@import 'styles/**/*.styl';
@import 'screens/**/*.styl';

html {
  box-sizing: border-box;
  touch-action: manipulation;
  scrollbar-width: none;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  font-family: 'QuattroNews', sans-serif;

  *::-webkit-scrollbar {
    display: none;
  }

  * {
    -webkit-tap-highlight-color: transparent;
  }
}

html, body {
  margin: 0;
  padding: 0;
}

body {
  color: white;
  background: colors.atomic;

  #ot-sdk-btn-floating {
    display: none !important;
  }
}

img {
  display: block;
}

h1 {
  font-size: 2rem;
  margin: 1.25rem 0;
}

h2 {
  font-size: 1.25rem;
}

h1, h2 {
  margin-top: 0rem;
}

a {
  color: white;
  text-decoration: none;

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: 2px solid currentColor;
  }
}

p {
  margin: 0.875rem 0;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

@font-face {
  font-family: 'QuattroNews';
  src: url('../../assets/fonts/QuattroNews-Regular.woff2') format('woff2'), url('../../assets/fonts/QuattroNews-Regular.woff') format('woff');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'QuattroNews';
  src: url('../../assets/fonts/QuattroNews-Medium.woff2') format('woff2'), url('../../assets/fonts/QuattroNews-Medium.woff') format('woff');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'QuattroNews';
  src: url('../../assets/fonts/QuattroNews-Bold.woff2') format('woff2'), url('../../assets/fonts/QuattroNews-Bold.woff') format('woff');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

.index {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.disable-link {
  pointer-events: none;
}
