@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes slide {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(.75);
  }

  100% {
    transform: scale(1);
  }
}

.about {
  background: linear-gradient(#3c4b52 0%, #2c383f 100%);
  flex: 1;
}

.about__container {
  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
  padding: 3rem 1.5rem 1.5rem;
}

@media screen and (min-width: 768px) {
  .about__container {
    padding: 3rem 0 1.5rem;
  }
}

.about__section h1 {
  margin: 0 0 .75rem;
}

.about__section p {
  color: #95a8b1;
}

.about__section:first-child p {
  color: #fff;
  margin: 0;
}

.about__heading h2 {
  margin: 2.5rem 0 0;
  padding: 0 0 0 1rem;
  font-size: 1.5rem;
  font-weight: 400;
}

.about__heading img {
  margin: 2.375rem 0 0;
}

.about__heading {
  align-items: center;
  display: flex;
}

.about__heading img {
  filter: invert(50%) sepia(60%) saturate(400%) hue-rotate(130deg) brightness(110%) contrast();
}

.about__footer {
  border-top: 1px solid #3c4b52;
  align-items: center;
  margin: 2rem 0 0;
  padding: 1.75rem 0 .5rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .about__footer {
    padding: 2rem 0 1rem;
  }
}

.about__disclaimer {
  padding: 0 1rem 0 0;
  font-size: 1rem;
  line-height: 1.625rem;
}

.about__disclaimer img {
  height: 2.5rem;
  width: 2.5rem;
}

@media screen and (min-width: 768px) {
  .about__disclaimer img {
    height: 3.5rem;
    width: 3.5rem;
  }
}

.about__disclaimer div {
  color: #95a8b1;
  display: inline-block;
}

.about__disclaimer a {
  text-decoration: underline;
  display: block;
}

@media screen and (min-width: 768px) {
  .about__disclaimer {
    padding: 0 1.5rem 0 0;
  }
}

.button {
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
}

.button__container {
  width: 100%;
  border: 1.5px solid rgba(255, 255, 255, .08);
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  padding: .75rem;
  display: flex;
}

.button__container--fill {
  background: rgba(255, 255, 255, .08);
  border: none;
}

.button__container--tv4 {
  background: #ff3334;
  border: none;
  margin: 2rem 0 0;
}

.button__container img {
  height: 1.75rem;
  padding: 0 0 0 .75rem;
}

.button__container span {
  font-weight: 500;
}

.buttonWithoutLabel {
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}

.crucial {
  width: fit-content;
  cursor: pointer;
  white-space: nowrap;
}

.crucial__container {
  background: rgba(255, 255, 255, .08);
  border-radius: 3rem;
  justify-content: center;
  align-items: center;
  margin: 0 0 1.25rem;
  padding: .5rem 1.5rem .5rem .5rem;
  display: flex;
}

.crucial__container img {
  height: 1.75rem;
  width: 1.75rem;
  box-sizing: content-box;
  padding: 0 .75rem;
}

.crucial__container span {
  font-weight: 500;
  line-height: 1.75rem;
}

.header {
  z-index: 11;
  background: #3c4b52;
  transition: transform .5s ease-out;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  transform: translateY(0);
  box-shadow: 0 8px 24px rgba(0, 0, 0, .12);
}

@media screen and (max-width: 767px) {
  .header--hide {
    transform: translateY(-100%);
  }
}

.header__cells {
  grid-template-columns: 1fr 2fr 1fr;
  padding: 0 1.5rem;
  display: grid;
}

.header__cell {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: .75rem 0;
  display: flex;
}

.header__cell span {
  padding: 0 .625rem;
  font-weight: 500;
  display: none;
}

.header__cell:first-child {
  justify-content: flex-start;
}

.header__cell:last-child {
  justify-content: flex-end;
}

.header__cell:focus-visible, .header__cell:focus {
  outline: none;
}

@media screen and (min-width: 768px) {
  .header__cell {
    padding: 1rem 0;
  }

  .header__cell span {
    display: inline-block;
  }
}

.header__claims {
  justify-content: space-between;
  padding: .5rem 0 1rem;
  display: flex;
  position: relative;
  overflow: scroll;
}

.header__claim {
  color: #95a8b1;
  min-width: 2rem;
  min-height: 2rem;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 .75rem;
  font-size: .875rem;
  font-weight: 700;
  display: flex;
}

.header__claim:focus-visible {
  opacity: .7;
}

.header__claim:focus {
  outline: none;
}

.header__claim:focus-visible {
  outline: 2px solid;
}

@media screen and (min-width: 768px) {
  .header__claim {
    margin: 0 0 0 1rem;
  }
}

.header__claim:last-child {
  margin: 0 .75rem;
}

@media screen and (min-width: 768px) {
  .header__claim:last-child {
    margin: 0 1rem;
  }
}

.header__claim--0 {
  color: #dc5259;
  cursor: pointer;
  background: rgba(220, 82, 89, .1);
}

.header__claim--1 {
  color: #e88c49;
  cursor: pointer;
  background: rgba(232, 140, 73, .1);
}

.header__claim--2 {
  cursor: pointer;
  background: #4c5e67;
}

.header__claim--3 {
  color: #b7c853;
  cursor: pointer;
  background: rgba(183, 200, 83, .1);
}

.header__claim--4 {
  color: #58c263;
  cursor: pointer;
  background: rgba(88, 194, 99, .1);
}

.header__claim--active {
  color: #2c383f;
  background: #fff;
}

.header__indicator {
  width: 0;
  height: 0;
  border-bottom: .375rem solid #fff;
  border-left: .375rem solid rgba(0, 0, 0, 0);
  border-right: .375rem solid rgba(0, 0, 0, 0);
  position: absolute;
  bottom: 0;
}

.loaders {
  z-index: 100;
  background: #2c383f;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.loaders img {
  height: 8rem;
  animation: spin 1s linear infinite;
}

.opinion {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  margin: 1rem .375rem;
}

.opinion:focus-visible {
  opacity: .7;
}

.opinion:focus {
  outline: none;
}

@media screen and (min-width: 768px) {
  .opinion {
    flex: 1;
  }
}

.opinion__button {
  border-radius: 2rem;
  justify-content: center;
  align-items: center;
  padding: .375rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .opinion__button {
    padding: .375rem 2rem;
  }
}

@media screen and (min-width: 480px) {
  .opinion__button:hover {
    outline: 2px solid #fff;
  }
}

.opinion__button img {
  width: 1.825rem;
  height: 1.825rem;
  border: 2px solid rgba(0, 0, 0, .04);
  border-radius: 3rem;
  display: block;
}

@media screen and (min-width: 768px) {
  .opinion__button img {
    width: 2.5rem;
    height: 2.5rem;
    border: 4px solid rgba(0, 0, 0, .04);
  }
}

.opinion__button .show-checkmark {
  border-color: rgba(0, 0, 0, 0);
}

.opinion__button.bounce {
  animation: bounce .5s;
}

.opinion__button.bounce img {
  border: 2px solid rgba(0, 0, 0, 0);
}

.opinion__button--0 {
  background: #dc5259;
}

.opinion__button--1 {
  background: #e88c49;
}

.opinion__button--2 {
  background: rgba(156, 165, 168, .36);
}

@media screen and (min-width: 768px) {
  .opinion__button--2 {
    background: none;
    border: 2px solid #3c4b52;
  }

  .opinion__button--2 img {
    border: 4px solid #3c4b52;
  }
}

.opinion__button--3 {
  background: #b7c853;
}

.opinion__button--4 {
  background: #58c263;
}

.overview {
  padding: 0 1.5rem 2.5rem;
}

@media screen and (min-width: 768px) {
  .overview {
    padding: 2.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .overview {
    padding: 5rem;
  }
}

.overview__links {
  padding: 0 0 .75rem;
}

.overview__link {
  cursor: pointer;
  margin: 0 1.5rem 0 0;
  padding: .25rem 0;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.375rem;
}

.overview__link--active {
  border-bottom: 2px solid #44baab;
}

.overview__question {
  max-width: 40rem;
  margin: 0 auto;
  overflow: visible;
}

.overview__slider {
  flex-flow: row;
  display: flex;
}

.overview__slide {
  width: 100%;
  flex-shrink: 0;
}

.overview__slide.animate {
  animation: fade 1.5s;
}

.overview__table {
  min-height: 280px;
  background-image: linear-gradient(rgba(0, 0, 0, 0) 0%, #2c383f 100%), linear-gradient(rgba(255, 255, 255, .08) 0%, rgba(255, 255, 255, 0) 100%), repeating-linear-gradient(90deg, #2c383f 0%, #2c383f 10%, rgba(255, 255, 255, .32) calc(10% + 1px), #2c383f 10%, #2c383f 20%);
  background-repeat: repeat;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: min-content;
  grid-auto-flow: row dense;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  padding-bottom: 20px;
  display: grid;
  position: relative;
}

.overview__table-token {
  text-align: center;
  width: 32px;
  height: 32px;
  margin: 12px auto 0;
}

.overview__table-token--0 {
  grid-column: 1;
}

.overview__table-token--1 {
  grid-column: 2;
}

.overview__table-token--2 {
  grid-column: 3;
}

.overview__table-token--3 {
  grid-column: 4;
}

.overview__table-token--4 {
  grid-column: 5;
}

.overview__table-heading {
  text-align: center;
  background-color: #2c383f;
  margin: 0;
  padding-bottom: 12px;
  font-size: 12px;
}

.overview__table-heading:nth-child(1) {
  color: #ef92aa;
}

.overview__table-heading:nth-child(2) {
  color: #f39f95;
}

.overview__table-heading:nth-child(3) {
  color: #b4bcbe;
}

.overview__table-heading:nth-child(4) {
  color: #d8e0a2;
}

.overview__table-heading:nth-child(5) {
  color: #9ede97;
}

@media screen and (max-width: 767px) {
  .overview__table-heading:nth-child(1) {
    text-align: left;
    grid-column: span 2;
    padding-left: 12px;
  }

  .overview__table-heading:nth-child(5) {
    text-align: right;
    grid-column: span 2;
    padding-right: 12px;
  }

  .overview__table-heading:nth-child(2), .overview__table-heading:nth-child(4) {
    display: none;
  }
}

.overview__line {
  height: 4px;
  width: 100%;
  background: linear-gradient(90deg, #da3a55 10.23%, #e8834a 29.87%, #fff 50.31%, #c2cf57 69.79%, #4ec869 90.26%);
  border: none;
  grid-column: 1 / -1;
  margin: 0;
}

.overview__heading {
  padding: .75rem 0;
}

.overview__heading span {
  color: #44baab;
}

.overview__buttons {
  display: flex;
}

.overview__button {
  width: 100%;
  cursor: pointer;
  background: rgba(255, 255, 255, .08);
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  margin: 0 1rem 0 0;
  padding: .75rem;
  font-weight: 500;
  display: flex;
}

.overview__button img {
  height: 1.75rem;
  padding: 0 0 0 .75rem;
}

.overview__button:first-child img {
  transform: rotate(180deg);
}

.overview__button:nth-child(2) {
  max-width: 12ch;
  color: #44baab;
  cursor: default;
  background: none;
  border: 2px solid rgba(255, 255, 255, .08);
  font-size: .75rem;
  font-weight: 700;
}

.overview__button:last-child {
  margin: 0;
}

.overview__button--disabled {
  color: rgba(255, 255, 255, .16);
  cursor: default;
}

.overview__button--disabled img {
  opacity: .16;
}

.overview__preamble {
  color: #95a8b1;
}

.overview__preamble span {
  font-weight: 700;
}

.overview__argument {
  border-radius: .5rem;
  margin: 1.25rem 0;
  padding: 1rem;
  position: relative;
}

.overview__argument--pro {
  background: rgba(68, 186, 171, .1);
}

.overview__argument--pro div {
  background: #44baab;
}

.overview__argument--con {
  background: rgba(220, 82, 89, .1);
}

.overview__argument--con div {
  background: #dc5259;
}

.overview__argument p {
  color: #fff;
  margin: 0;
}

.overview__argument div {
  width: .125rem;
  height: calc(100% - 2rem);
  border-radius: 1px;
  position: absolute;
  top: 1rem;
  left: 0;
}

.overview__legend {
  color: #95a8b1;
  grid-template-columns: 1fr 1fr 1fr;
  padding: .75rem 0;
  font-size: .75rem;
  display: grid;
}

@media screen and (min-width: 768px) {
  .overview__legend {
    font-size: 1rem;
    display: flex;
  }
}

@media screen and (min-width: 768px) {
  .overview__legend div {
    margin: 0 .5rem 0 1rem;
  }
}

.overview__legend span {
  align-items: center;
  padding: 0 .25rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .overview__legend span:first-child div {
    margin-left: 0;
  }
}

.overview__opinions {
  background: rgba(255, 255, 255, .08);
  border-radius: .5rem;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.overview__opinion {
  border-bottom: 1px solid rgba(255, 255, 255, .08);
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: .75rem 1rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .overview__opinion {
    flex-direction: row;
    align-items: center;
  }
}

.overview__opinion h2 {
  grid-template-columns: min-content 1fr;
  margin: 0;
  padding: .5rem 0;
  font-size: 1rem;
  display: grid;
}

.overview__opinion h2 span {
  color: #95a8b1;
  padding: 0 .25rem 0 0;
}

@media screen and (max-width: 767px) {
  .overview__opinion h2 {
    padding-left: 2rem;
    font-size: .875rem;
    display: block;
  }

  .overview__opinion h2 span {
    position: absolute;
    left: 1rem;
  }
}

.overview__opinion:first-child {
  z-index: 1;
  background: #3c4b52;
  border-bottom: none;
  transition: transform .5s ease-out;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.overview__opinion:first-child h2 {
  display: none;
}

@media screen and (min-width: 768px) {
  .overview__opinion:first-child {
    border-radius: .5rem .5rem 0 0;
    top: calc(4.5rem + 2px);
    box-shadow: 0 -.5rem #2c383f;
  }

  .overview__opinion:first-child h2 {
    display: block;
  }
}

.overview__opinion:last-child {
  border-bottom: none;
}

.overview__circles {
  justify-content: center;
  margin-left: 2rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .overview__circles {
    justify-content: flex-end;
  }
}

.overview__circle {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1rem;
  flex-shrink: 0;
  margin: .25rem .25rem 0;
}

@media screen and (min-width: 768px) {
  .overview__circle {
    width: 2rem;
    height: 2rem;
    margin: .5rem .5rem 0;
  }
}

.overview__circle img {
  height: 1.5rem;
  width: 1.5rem;
}

@media screen and (min-width: 768px) {
  .overview__circle img {
    height: 2rem;
    width: 2rem;
  }
}

.overview__circle--0 {
  background: #dc5259;
}

.overview__circle--1 {
  background: #e88c49;
}

.overview__circle--2 {
  background: #95a8b1;
}

.overview__circle--3 {
  background: #b7c853;
}

.overview__circle--4 {
  background: #58c263;
}

.overview__circle--0 img, .overview__circle--1 img, .overview__circle--2 img, .overview__circle--3 img, .overview__circle--4 img {
  filter: invert();
  padding: .175rem;
}

@media screen and (min-width: 768px) {
  .overview__circle--0 img, .overview__circle--1 img, .overview__circle--2 img, .overview__circle--3 img, .overview__circle--4 img {
    padding: .375rem;
  }
}

.overview__circle--small {
  width: 1rem;
  height: 1rem;
}

@media screen and (min-width: 768px) {
  .overview__circle--small {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.overview__circle--small img {
  height: 1rem;
  width: 1rem;
}

@media screen and (min-width: 768px) {
  .overview__circle--small img {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.progress {
  width: 100%;
  background: #4c5e67;
}

.progress__horizontal {
  width: 0;
  height: .25rem;
  background: #44baab;
  border-radius: .25rem;
  transition: width .25s;
}

.video {
  width: 100%;
  aspect-ratio: 16 / 9;
}

.video__overlay {
  position: relative;
}

.video__buttons {
  z-index: 10;
  transition: opacity .3s ease-in-out;
  position: absolute;
  top: .25rem;
  right: .25rem;
}

@media screen and (min-width: 480px) {
  .video__buttons {
    top: 1.75rem;
    right: 1.75rem;
  }
}

.video__button {
  min-width: 42px;
  min-height: 42px;
  cursor: pointer;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: opacity .3s ease-in-out;
  display: inline-flex;
}

.video__button:focus-visible {
  opacity: .7;
}

.video__button:focus {
  outline: none;
}

.video__button img {
  box-sizing: content-box;
  width: 14px;
  height: 14px;
  background: rgba(0, 0, 0, .5);
  border-radius: .5rem;
  padding: .5rem;
}

@media screen and (min-width: 768px) {
  .video__button:first-child {
    margin-right: 1rem;
  }

  .video__button img {
    width: unset;
    height: unset;
    padding: .5rem 1rem;
  }
}

.video--controls-hidden .video__button {
  opacity: 0;
}

@media (hover: hover) {
  .video--controls-hidden:hover .video__button {
    opacity: 1;
  }
}

.video__primary-button {
  cursor: auto;
  width: 100%;
  height: 100%;
  z-index: 1;
  color: #fff;
  background-color: rgba(0, 0, 0, .2);
  border: none;
  border-radius: .25rem;
  align-content: center;
  justify-content: center;
  padding: 0;
  display: grid;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.video__primary-button--play {
  opacity: 0;
}

.video__primary-button--pause {
  opacity: 1 !important;
}

.video__primary-button img {
  width: 80px;
  height: 80px;
  background-color: rgba(0, 0, 0, .5);
  border-radius: 100%;
  padding: .5rem;
}

.video__player {
  aspect-ratio: 16 / 9;
  width: 100%;
  background: #8a9ea7;
  border-radius: .25rem;
  display: block;
}

.video__progress {
  color: #fff;
  font-variant-numeric: tabular-nums;
  z-index: 1;
  padding: 4px;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  transition: opacity .3s ease-in-out;
  position: absolute;
  bottom: 12px;
  left: 12px;
}

.video--controls-hidden .video__progress {
  opacity: 0;
}

@media (hover: hover) {
  .video--controls-hidden:hover .video__progress {
    opacity: 1;
  }
}

.about {
  background: linear-gradient(#3c4b52 0%, #2c383f 100%);
  flex: 1;
}

.about__container {
  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
  padding: 3rem 1.5rem 1.5rem;
}

@media screen and (min-width: 768px) {
  .about__container {
    padding: 3rem 0 1.5rem;
  }
}

.about__section h1 {
  margin: 0 0 .75rem;
}

.about__section p {
  color: #95a8b1;
}

.about__section:first-child p {
  color: #fff;
  margin: 0;
}

.about__heading h2 {
  margin: 2.5rem 0 0;
  padding: 0 0 0 1rem;
  font-size: 1.5rem;
  font-weight: 400;
}

.about__heading img {
  margin: 2.375rem 0 0;
}

.about__heading {
  align-items: center;
  display: flex;
}

.about__heading img {
  filter: invert(50%) sepia(60%) saturate(400%) hue-rotate(130deg) brightness(110%) contrast();
}

.about__footer {
  border-top: 1px solid #3c4b52;
  align-items: center;
  margin: 2rem 0 0;
  padding: 1.75rem 0 .5rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .about__footer {
    padding: 2rem 0 1rem;
  }
}

.about__disclaimer {
  padding: 0 1rem 0 0;
  font-size: 1rem;
  line-height: 1.625rem;
}

.about__disclaimer img {
  height: 2.5rem;
  width: 2.5rem;
}

@media screen and (min-width: 768px) {
  .about__disclaimer img {
    height: 3.5rem;
    width: 3.5rem;
  }
}

.about__disclaimer div {
  color: #95a8b1;
  display: inline-block;
}

.about__disclaimer a {
  text-decoration: underline;
  display: block;
}

@media screen and (min-width: 768px) {
  .about__disclaimer {
    padding: 0 1.5rem 0 0;
  }
}

.button {
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
}

.button__container {
  width: 100%;
  border: 1.5px solid rgba(255, 255, 255, .08);
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  padding: .75rem;
  display: flex;
}

.button__container--fill {
  background: rgba(255, 255, 255, .08);
  border: none;
}

.button__container--tv4 {
  background: #ff3334;
  border: none;
  margin: 2rem 0 0;
}

.button__container img {
  height: 1.75rem;
  padding: 0 0 0 .75rem;
}

.button__container span {
  font-weight: 500;
}

.buttonWithoutLabel {
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}

.crucial {
  width: fit-content;
  cursor: pointer;
  white-space: nowrap;
}

.crucial__container {
  background: rgba(255, 255, 255, .08);
  border-radius: 3rem;
  justify-content: center;
  align-items: center;
  margin: 0 0 1.25rem;
  padding: .5rem 1.5rem .5rem .5rem;
  display: flex;
}

.crucial__container img {
  height: 1.75rem;
  width: 1.75rem;
  box-sizing: content-box;
  padding: 0 .75rem;
}

.crucial__container span {
  font-weight: 500;
  line-height: 1.75rem;
}

.header {
  z-index: 11;
  background: #3c4b52;
  transition: transform .5s ease-out;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  transform: translateY(0);
  box-shadow: 0 8px 24px rgba(0, 0, 0, .12);
}

@media screen and (max-width: 767px) {
  .header--hide {
    transform: translateY(-100%);
  }
}

.header__cells {
  grid-template-columns: 1fr 2fr 1fr;
  padding: 0 1.5rem;
  display: grid;
}

.header__cell {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: .75rem 0;
  display: flex;
}

.header__cell span {
  padding: 0 .625rem;
  font-weight: 500;
  display: none;
}

.header__cell:first-child {
  justify-content: flex-start;
}

.header__cell:last-child {
  justify-content: flex-end;
}

.header__cell:focus-visible, .header__cell:focus {
  outline: none;
}

@media screen and (min-width: 768px) {
  .header__cell {
    padding: 1rem 0;
  }

  .header__cell span {
    display: inline-block;
  }
}

.header__claims {
  justify-content: space-between;
  padding: .5rem 0 1rem;
  display: flex;
  position: relative;
  overflow: scroll;
}

.header__claim {
  color: #95a8b1;
  min-width: 2rem;
  min-height: 2rem;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 .75rem;
  font-size: .875rem;
  font-weight: 700;
  display: flex;
}

.header__claim:focus-visible {
  opacity: .7;
}

.header__claim:focus {
  outline: none;
}

.header__claim:focus-visible {
  outline: 2px solid;
}

@media screen and (min-width: 768px) {
  .header__claim {
    margin: 0 0 0 1rem;
  }
}

.header__claim:last-child {
  margin: 0 .75rem;
}

@media screen and (min-width: 768px) {
  .header__claim:last-child {
    margin: 0 1rem;
  }
}

.header__claim--0 {
  color: #dc5259;
  cursor: pointer;
  background: rgba(220, 82, 89, .1);
}

.header__claim--1 {
  color: #e88c49;
  cursor: pointer;
  background: rgba(232, 140, 73, .1);
}

.header__claim--2 {
  cursor: pointer;
  background: #4c5e67;
}

.header__claim--3 {
  color: #b7c853;
  cursor: pointer;
  background: rgba(183, 200, 83, .1);
}

.header__claim--4 {
  color: #58c263;
  cursor: pointer;
  background: rgba(88, 194, 99, .1);
}

.header__claim--active {
  color: #2c383f;
  background: #fff;
}

.header__indicator {
  width: 0;
  height: 0;
  border-bottom: .375rem solid #fff;
  border-left: .375rem solid rgba(0, 0, 0, 0);
  border-right: .375rem solid rgba(0, 0, 0, 0);
  position: absolute;
  bottom: 0;
}

.loaders {
  z-index: 100;
  background: #2c383f;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.loaders img {
  height: 8rem;
  animation: spin 1s linear infinite;
}

.opinion {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  margin: 1rem .375rem;
}

.opinion:focus-visible {
  opacity: .7;
}

.opinion:focus {
  outline: none;
}

@media screen and (min-width: 768px) {
  .opinion {
    flex: 1;
  }
}

.opinion__button {
  border-radius: 2rem;
  justify-content: center;
  align-items: center;
  padding: .375rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .opinion__button {
    padding: .375rem 2rem;
  }
}

@media screen and (min-width: 480px) {
  .opinion__button:hover {
    outline: 2px solid #fff;
  }
}

.opinion__button img {
  width: 1.825rem;
  height: 1.825rem;
  border: 2px solid rgba(0, 0, 0, .04);
  border-radius: 3rem;
  display: block;
}

@media screen and (min-width: 768px) {
  .opinion__button img {
    width: 2.5rem;
    height: 2.5rem;
    border: 4px solid rgba(0, 0, 0, .04);
  }
}

.opinion__button .show-checkmark {
  border-color: rgba(0, 0, 0, 0);
}

.opinion__button.bounce {
  animation: bounce .5s;
}

.opinion__button.bounce img {
  border: 2px solid rgba(0, 0, 0, 0);
}

.opinion__button--0 {
  background: #dc5259;
}

.opinion__button--1 {
  background: #e88c49;
}

.opinion__button--2 {
  background: rgba(156, 165, 168, .36);
}

@media screen and (min-width: 768px) {
  .opinion__button--2 {
    background: none;
    border: 2px solid #3c4b52;
  }

  .opinion__button--2 img {
    border: 4px solid #3c4b52;
  }
}

.opinion__button--3 {
  background: #b7c853;
}

.opinion__button--4 {
  background: #58c263;
}

.overview {
  padding: 0 1.5rem 2.5rem;
}

@media screen and (min-width: 768px) {
  .overview {
    padding: 2.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .overview {
    padding: 5rem;
  }
}

.overview__links {
  padding: 0 0 .75rem;
}

.overview__link {
  cursor: pointer;
  margin: 0 1.5rem 0 0;
  padding: .25rem 0;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.375rem;
}

.overview__link--active {
  border-bottom: 2px solid #44baab;
}

.overview__question {
  max-width: 40rem;
  margin: 0 auto;
  overflow: visible;
}

.overview__slider {
  flex-flow: row;
  display: flex;
}

.overview__slide {
  width: 100%;
  flex-shrink: 0;
}

.overview__slide.animate {
  animation: fade 1.5s;
}

.overview__table {
  min-height: 280px;
  background-image: linear-gradient(rgba(0, 0, 0, 0) 0%, #2c383f 100%), linear-gradient(rgba(255, 255, 255, .08) 0%, rgba(255, 255, 255, 0) 100%), repeating-linear-gradient(90deg, #2c383f 0%, #2c383f 10%, rgba(255, 255, 255, .32) calc(10% + 1px), #2c383f 10%, #2c383f 20%);
  background-repeat: repeat;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: min-content;
  grid-auto-flow: row dense;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  padding-bottom: 20px;
  display: grid;
  position: relative;
}

.overview__table-token {
  text-align: center;
  width: 32px;
  height: 32px;
  margin: 12px auto 0;
}

.overview__table-token--0 {
  grid-column: 1;
}

.overview__table-token--1 {
  grid-column: 2;
}

.overview__table-token--2 {
  grid-column: 3;
}

.overview__table-token--3 {
  grid-column: 4;
}

.overview__table-token--4 {
  grid-column: 5;
}

.overview__table-heading {
  text-align: center;
  background-color: #2c383f;
  margin: 0;
  padding-bottom: 12px;
  font-size: 12px;
}

.overview__table-heading:nth-child(1) {
  color: #ef92aa;
}

.overview__table-heading:nth-child(2) {
  color: #f39f95;
}

.overview__table-heading:nth-child(3) {
  color: #b4bcbe;
}

.overview__table-heading:nth-child(4) {
  color: #d8e0a2;
}

.overview__table-heading:nth-child(5) {
  color: #9ede97;
}

@media screen and (max-width: 767px) {
  .overview__table-heading:nth-child(1) {
    text-align: left;
    grid-column: span 2;
    padding-left: 12px;
  }

  .overview__table-heading:nth-child(5) {
    text-align: right;
    grid-column: span 2;
    padding-right: 12px;
  }

  .overview__table-heading:nth-child(2), .overview__table-heading:nth-child(4) {
    display: none;
  }
}

.overview__line {
  height: 4px;
  width: 100%;
  background: linear-gradient(90deg, #da3a55 10.23%, #e8834a 29.87%, #fff 50.31%, #c2cf57 69.79%, #4ec869 90.26%);
  border: none;
  grid-column: 1 / -1;
  margin: 0;
}

.overview__heading {
  padding: .75rem 0;
}

.overview__heading span {
  color: #44baab;
}

.overview__buttons {
  display: flex;
}

.overview__button {
  width: 100%;
  cursor: pointer;
  background: rgba(255, 255, 255, .08);
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  margin: 0 1rem 0 0;
  padding: .75rem;
  font-weight: 500;
  display: flex;
}

.overview__button img {
  height: 1.75rem;
  padding: 0 0 0 .75rem;
}

.overview__button:first-child img {
  transform: rotate(180deg);
}

.overview__button:nth-child(2) {
  max-width: 12ch;
  color: #44baab;
  cursor: default;
  background: none;
  border: 2px solid rgba(255, 255, 255, .08);
  font-size: .75rem;
  font-weight: 700;
}

.overview__button:last-child {
  margin: 0;
}

.overview__button--disabled {
  color: rgba(255, 255, 255, .16);
  cursor: default;
}

.overview__button--disabled img {
  opacity: .16;
}

.overview__preamble {
  color: #95a8b1;
}

.overview__preamble span {
  font-weight: 700;
}

.overview__argument {
  border-radius: .5rem;
  margin: 1.25rem 0;
  padding: 1rem;
  position: relative;
}

.overview__argument--pro {
  background: rgba(68, 186, 171, .1);
}

.overview__argument--pro div {
  background: #44baab;
}

.overview__argument--con {
  background: rgba(220, 82, 89, .1);
}

.overview__argument--con div {
  background: #dc5259;
}

.overview__argument p {
  color: #fff;
  margin: 0;
}

.overview__argument div {
  width: .125rem;
  height: calc(100% - 2rem);
  border-radius: 1px;
  position: absolute;
  top: 1rem;
  left: 0;
}

.overview__legend {
  color: #95a8b1;
  grid-template-columns: 1fr 1fr 1fr;
  padding: .75rem 0;
  font-size: .75rem;
  display: grid;
}

@media screen and (min-width: 768px) {
  .overview__legend {
    font-size: 1rem;
    display: flex;
  }
}

@media screen and (min-width: 768px) {
  .overview__legend div {
    margin: 0 .5rem 0 1rem;
  }
}

.overview__legend span {
  align-items: center;
  padding: 0 .25rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .overview__legend span:first-child div {
    margin-left: 0;
  }
}

.overview__opinions {
  background: rgba(255, 255, 255, .08);
  border-radius: .5rem;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.overview__opinion {
  border-bottom: 1px solid rgba(255, 255, 255, .08);
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: .75rem 1rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .overview__opinion {
    flex-direction: row;
    align-items: center;
  }
}

.overview__opinion h2 {
  grid-template-columns: min-content 1fr;
  margin: 0;
  padding: .5rem 0;
  font-size: 1rem;
  display: grid;
}

.overview__opinion h2 span {
  color: #95a8b1;
  padding: 0 .25rem 0 0;
}

@media screen and (max-width: 767px) {
  .overview__opinion h2 {
    padding-left: 2rem;
    font-size: .875rem;
    display: block;
  }

  .overview__opinion h2 span {
    position: absolute;
    left: 1rem;
  }
}

.overview__opinion:first-child {
  z-index: 1;
  background: #3c4b52;
  border-bottom: none;
  transition: transform .5s ease-out;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.overview__opinion:first-child h2 {
  display: none;
}

@media screen and (min-width: 768px) {
  .overview__opinion:first-child {
    border-radius: .5rem .5rem 0 0;
    top: calc(4.5rem + 2px);
    box-shadow: 0 -.5rem #2c383f;
  }

  .overview__opinion:first-child h2 {
    display: block;
  }
}

.overview__opinion:last-child {
  border-bottom: none;
}

.overview__circles {
  justify-content: center;
  margin-left: 2rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .overview__circles {
    justify-content: flex-end;
  }
}

.overview__circle {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1rem;
  flex-shrink: 0;
  margin: .25rem .25rem 0;
}

@media screen and (min-width: 768px) {
  .overview__circle {
    width: 2rem;
    height: 2rem;
    margin: .5rem .5rem 0;
  }
}

.overview__circle img {
  height: 1.5rem;
  width: 1.5rem;
}

@media screen and (min-width: 768px) {
  .overview__circle img {
    height: 2rem;
    width: 2rem;
  }
}

.overview__circle--0 {
  background: #dc5259;
}

.overview__circle--1 {
  background: #e88c49;
}

.overview__circle--2 {
  background: #95a8b1;
}

.overview__circle--3 {
  background: #b7c853;
}

.overview__circle--4 {
  background: #58c263;
}

.overview__circle--0 img, .overview__circle--1 img, .overview__circle--2 img, .overview__circle--3 img, .overview__circle--4 img {
  filter: invert();
  padding: .175rem;
}

@media screen and (min-width: 768px) {
  .overview__circle--0 img, .overview__circle--1 img, .overview__circle--2 img, .overview__circle--3 img, .overview__circle--4 img {
    padding: .375rem;
  }
}

.overview__circle--small {
  width: 1rem;
  height: 1rem;
}

@media screen and (min-width: 768px) {
  .overview__circle--small {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.overview__circle--small img {
  height: 1rem;
  width: 1rem;
}

@media screen and (min-width: 768px) {
  .overview__circle--small img {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.progress {
  width: 100%;
  background: #4c5e67;
}

.progress__horizontal {
  width: 0;
  height: .25rem;
  background: #44baab;
  border-radius: .25rem;
  transition: width .25s;
}

.video {
  width: 100%;
  aspect-ratio: 16 / 9;
}

.video__overlay {
  position: relative;
}

.video__buttons {
  z-index: 10;
  transition: opacity .3s ease-in-out;
  position: absolute;
  top: .25rem;
  right: .25rem;
}

@media screen and (min-width: 480px) {
  .video__buttons {
    top: 1.75rem;
    right: 1.75rem;
  }
}

.video__button {
  min-width: 42px;
  min-height: 42px;
  cursor: pointer;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: opacity .3s ease-in-out;
  display: inline-flex;
}

.video__button:focus-visible {
  opacity: .7;
}

.video__button:focus {
  outline: none;
}

.video__button img {
  box-sizing: content-box;
  width: 14px;
  height: 14px;
  background: rgba(0, 0, 0, .5);
  border-radius: .5rem;
  padding: .5rem;
}

@media screen and (min-width: 768px) {
  .video__button:first-child {
    margin-right: 1rem;
  }

  .video__button img {
    width: unset;
    height: unset;
    padding: .5rem 1rem;
  }
}

.video--controls-hidden .video__button {
  opacity: 0;
}

@media (hover: hover) {
  .video--controls-hidden:hover .video__button {
    opacity: 1;
  }
}

.video__primary-button {
  cursor: auto;
  width: 100%;
  height: 100%;
  z-index: 1;
  color: #fff;
  background-color: rgba(0, 0, 0, .2);
  border: none;
  border-radius: .25rem;
  align-content: center;
  justify-content: center;
  padding: 0;
  display: grid;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.video__primary-button--play {
  opacity: 0;
}

.video__primary-button--pause {
  opacity: 1 !important;
}

.video__primary-button img {
  width: 80px;
  height: 80px;
  background-color: rgba(0, 0, 0, .5);
  border-radius: 100%;
  padding: .5rem;
}

.video__player {
  aspect-ratio: 16 / 9;
  width: 100%;
  background: #8a9ea7;
  border-radius: .25rem;
  display: block;
}

.video__progress {
  color: #fff;
  font-variant-numeric: tabular-nums;
  z-index: 1;
  padding: 4px;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  transition: opacity .3s ease-in-out;
  position: absolute;
  bottom: 12px;
  left: 12px;
}

.video--controls-hidden .video__progress {
  opacity: 0;
}

@media (hover: hover) {
  .video--controls-hidden:hover .video__progress {
    opacity: 1;
  }
}

.about {
  background: linear-gradient(#3c4b52 0%, #2c383f 100%);
  flex: 1;
}

.about__container {
  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
  padding: 3rem 1.5rem 1.5rem;
}

@media screen and (min-width: 768px) {
  .about__container {
    padding: 3rem 0 1.5rem;
  }
}

.about__section h1 {
  margin: 0 0 .75rem;
}

.about__section p {
  color: #95a8b1;
}

.about__section:first-child p {
  color: #fff;
  margin: 0;
}

.about__heading h2 {
  margin: 2.5rem 0 0;
  padding: 0 0 0 1rem;
  font-size: 1.5rem;
  font-weight: 400;
}

.about__heading img {
  margin: 2.375rem 0 0;
}

.about__heading {
  align-items: center;
  display: flex;
}

.about__heading img {
  filter: invert(50%) sepia(60%) saturate(400%) hue-rotate(130deg) brightness(110%) contrast();
}

.about__footer {
  border-top: 1px solid #3c4b52;
  align-items: center;
  margin: 2rem 0 0;
  padding: 1.75rem 0 .5rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .about__footer {
    padding: 2rem 0 1rem;
  }
}

.about__disclaimer {
  padding: 0 1rem 0 0;
  font-size: 1rem;
  line-height: 1.625rem;
}

.about__disclaimer img {
  height: 2.5rem;
  width: 2.5rem;
}

@media screen and (min-width: 768px) {
  .about__disclaimer img {
    height: 3.5rem;
    width: 3.5rem;
  }
}

.about__disclaimer div {
  color: #95a8b1;
  display: inline-block;
}

.about__disclaimer a {
  text-decoration: underline;
  display: block;
}

@media screen and (min-width: 768px) {
  .about__disclaimer {
    padding: 0 1.5rem 0 0;
  }
}

.button {
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
}

.button__container {
  width: 100%;
  border: 1.5px solid rgba(255, 255, 255, .08);
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  padding: .75rem;
  display: flex;
}

.button__container--fill {
  background: rgba(255, 255, 255, .08);
  border: none;
}

.button__container--tv4 {
  background: #ff3334;
  border: none;
  margin: 2rem 0 0;
}

.button__container img {
  height: 1.75rem;
  padding: 0 0 0 .75rem;
}

.button__container span {
  font-weight: 500;
}

.buttonWithoutLabel {
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}

.crucial {
  width: fit-content;
  cursor: pointer;
  white-space: nowrap;
}

.crucial__container {
  background: rgba(255, 255, 255, .08);
  border-radius: 3rem;
  justify-content: center;
  align-items: center;
  margin: 0 0 1.25rem;
  padding: .5rem 1.5rem .5rem .5rem;
  display: flex;
}

.crucial__container img {
  height: 1.75rem;
  width: 1.75rem;
  box-sizing: content-box;
  padding: 0 .75rem;
}

.crucial__container span {
  font-weight: 500;
  line-height: 1.75rem;
}

.header {
  z-index: 11;
  background: #3c4b52;
  transition: transform .5s ease-out;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  transform: translateY(0);
  box-shadow: 0 8px 24px rgba(0, 0, 0, .12);
}

@media screen and (max-width: 767px) {
  .header--hide {
    transform: translateY(-100%);
  }
}

.header__cells {
  grid-template-columns: 1fr 2fr 1fr;
  padding: 0 1.5rem;
  display: grid;
}

.header__cell {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: .75rem 0;
  display: flex;
}

.header__cell span {
  padding: 0 .625rem;
  font-weight: 500;
  display: none;
}

.header__cell:first-child {
  justify-content: flex-start;
}

.header__cell:last-child {
  justify-content: flex-end;
}

.header__cell:focus-visible, .header__cell:focus {
  outline: none;
}

@media screen and (min-width: 768px) {
  .header__cell {
    padding: 1rem 0;
  }

  .header__cell span {
    display: inline-block;
  }
}

.header__claims {
  justify-content: space-between;
  padding: .5rem 0 1rem;
  display: flex;
  position: relative;
  overflow: scroll;
}

.header__claim {
  color: #95a8b1;
  min-width: 2rem;
  min-height: 2rem;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 .75rem;
  font-size: .875rem;
  font-weight: 700;
  display: flex;
}

.header__claim:focus-visible {
  opacity: .7;
}

.header__claim:focus {
  outline: none;
}

.header__claim:focus-visible {
  outline: 2px solid;
}

@media screen and (min-width: 768px) {
  .header__claim {
    margin: 0 0 0 1rem;
  }
}

.header__claim:last-child {
  margin: 0 .75rem;
}

@media screen and (min-width: 768px) {
  .header__claim:last-child {
    margin: 0 1rem;
  }
}

.header__claim--0 {
  color: #dc5259;
  cursor: pointer;
  background: rgba(220, 82, 89, .1);
}

.header__claim--1 {
  color: #e88c49;
  cursor: pointer;
  background: rgba(232, 140, 73, .1);
}

.header__claim--2 {
  cursor: pointer;
  background: #4c5e67;
}

.header__claim--3 {
  color: #b7c853;
  cursor: pointer;
  background: rgba(183, 200, 83, .1);
}

.header__claim--4 {
  color: #58c263;
  cursor: pointer;
  background: rgba(88, 194, 99, .1);
}

.header__claim--active {
  color: #2c383f;
  background: #fff;
}

.header__indicator {
  width: 0;
  height: 0;
  border-bottom: .375rem solid #fff;
  border-left: .375rem solid rgba(0, 0, 0, 0);
  border-right: .375rem solid rgba(0, 0, 0, 0);
  position: absolute;
  bottom: 0;
}

.loaders {
  z-index: 100;
  background: #2c383f;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.loaders img {
  height: 8rem;
  animation: spin 1s linear infinite;
}

.opinion {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  margin: 1rem .375rem;
}

.opinion:focus-visible {
  opacity: .7;
}

.opinion:focus {
  outline: none;
}

@media screen and (min-width: 768px) {
  .opinion {
    flex: 1;
  }
}

.opinion__button {
  border-radius: 2rem;
  justify-content: center;
  align-items: center;
  padding: .375rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .opinion__button {
    padding: .375rem 2rem;
  }
}

@media screen and (min-width: 480px) {
  .opinion__button:hover {
    outline: 2px solid #fff;
  }
}

.opinion__button img {
  width: 1.825rem;
  height: 1.825rem;
  border: 2px solid rgba(0, 0, 0, .04);
  border-radius: 3rem;
  display: block;
}

@media screen and (min-width: 768px) {
  .opinion__button img {
    width: 2.5rem;
    height: 2.5rem;
    border: 4px solid rgba(0, 0, 0, .04);
  }
}

.opinion__button .show-checkmark {
  border-color: rgba(0, 0, 0, 0);
}

.opinion__button.bounce {
  animation: bounce .5s;
}

.opinion__button.bounce img {
  border: 2px solid rgba(0, 0, 0, 0);
}

.opinion__button--0 {
  background: #dc5259;
}

.opinion__button--1 {
  background: #e88c49;
}

.opinion__button--2 {
  background: rgba(156, 165, 168, .36);
}

@media screen and (min-width: 768px) {
  .opinion__button--2 {
    background: none;
    border: 2px solid #3c4b52;
  }

  .opinion__button--2 img {
    border: 4px solid #3c4b52;
  }
}

.opinion__button--3 {
  background: #b7c853;
}

.opinion__button--4 {
  background: #58c263;
}

.overview {
  padding: 0 1.5rem 2.5rem;
}

@media screen and (min-width: 768px) {
  .overview {
    padding: 2.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .overview {
    padding: 5rem;
  }
}

.overview__links {
  padding: 0 0 .75rem;
}

.overview__link {
  cursor: pointer;
  margin: 0 1.5rem 0 0;
  padding: .25rem 0;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.375rem;
}

.overview__link--active {
  border-bottom: 2px solid #44baab;
}

.overview__question {
  max-width: 40rem;
  margin: 0 auto;
  overflow: visible;
}

.overview__slider {
  flex-flow: row;
  display: flex;
}

.overview__slide {
  width: 100%;
  flex-shrink: 0;
}

.overview__slide.animate {
  animation: fade 1.5s;
}

.overview__table {
  min-height: 280px;
  background-image: linear-gradient(rgba(0, 0, 0, 0) 0%, #2c383f 100%), linear-gradient(rgba(255, 255, 255, .08) 0%, rgba(255, 255, 255, 0) 100%), repeating-linear-gradient(90deg, #2c383f 0%, #2c383f 10%, rgba(255, 255, 255, .32) calc(10% + 1px), #2c383f 10%, #2c383f 20%);
  background-repeat: repeat;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: min-content;
  grid-auto-flow: row dense;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  padding-bottom: 20px;
  display: grid;
  position: relative;
}

.overview__table-token {
  text-align: center;
  width: 32px;
  height: 32px;
  margin: 12px auto 0;
}

.overview__table-token--0 {
  grid-column: 1;
}

.overview__table-token--1 {
  grid-column: 2;
}

.overview__table-token--2 {
  grid-column: 3;
}

.overview__table-token--3 {
  grid-column: 4;
}

.overview__table-token--4 {
  grid-column: 5;
}

.overview__table-heading {
  text-align: center;
  background-color: #2c383f;
  margin: 0;
  padding-bottom: 12px;
  font-size: 12px;
}

.overview__table-heading:nth-child(1) {
  color: #ef92aa;
}

.overview__table-heading:nth-child(2) {
  color: #f39f95;
}

.overview__table-heading:nth-child(3) {
  color: #b4bcbe;
}

.overview__table-heading:nth-child(4) {
  color: #d8e0a2;
}

.overview__table-heading:nth-child(5) {
  color: #9ede97;
}

@media screen and (max-width: 767px) {
  .overview__table-heading:nth-child(1) {
    text-align: left;
    grid-column: span 2;
    padding-left: 12px;
  }

  .overview__table-heading:nth-child(5) {
    text-align: right;
    grid-column: span 2;
    padding-right: 12px;
  }

  .overview__table-heading:nth-child(2), .overview__table-heading:nth-child(4) {
    display: none;
  }
}

.overview__line {
  height: 4px;
  width: 100%;
  background: linear-gradient(90deg, #da3a55 10.23%, #e8834a 29.87%, #fff 50.31%, #c2cf57 69.79%, #4ec869 90.26%);
  border: none;
  grid-column: 1 / -1;
  margin: 0;
}

.overview__heading {
  padding: .75rem 0;
}

.overview__heading span {
  color: #44baab;
}

.overview__buttons {
  display: flex;
}

.overview__button {
  width: 100%;
  cursor: pointer;
  background: rgba(255, 255, 255, .08);
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  margin: 0 1rem 0 0;
  padding: .75rem;
  font-weight: 500;
  display: flex;
}

.overview__button img {
  height: 1.75rem;
  padding: 0 0 0 .75rem;
}

.overview__button:first-child img {
  transform: rotate(180deg);
}

.overview__button:nth-child(2) {
  max-width: 12ch;
  color: #44baab;
  cursor: default;
  background: none;
  border: 2px solid rgba(255, 255, 255, .08);
  font-size: .75rem;
  font-weight: 700;
}

.overview__button:last-child {
  margin: 0;
}

.overview__button--disabled {
  color: rgba(255, 255, 255, .16);
  cursor: default;
}

.overview__button--disabled img {
  opacity: .16;
}

.overview__preamble {
  color: #95a8b1;
}

.overview__preamble span {
  font-weight: 700;
}

.overview__argument {
  border-radius: .5rem;
  margin: 1.25rem 0;
  padding: 1rem;
  position: relative;
}

.overview__argument--pro {
  background: rgba(68, 186, 171, .1);
}

.overview__argument--pro div {
  background: #44baab;
}

.overview__argument--con {
  background: rgba(220, 82, 89, .1);
}

.overview__argument--con div {
  background: #dc5259;
}

.overview__argument p {
  color: #fff;
  margin: 0;
}

.overview__argument div {
  width: .125rem;
  height: calc(100% - 2rem);
  border-radius: 1px;
  position: absolute;
  top: 1rem;
  left: 0;
}

.overview__legend {
  color: #95a8b1;
  grid-template-columns: 1fr 1fr 1fr;
  padding: .75rem 0;
  font-size: .75rem;
  display: grid;
}

@media screen and (min-width: 768px) {
  .overview__legend {
    font-size: 1rem;
    display: flex;
  }
}

@media screen and (min-width: 768px) {
  .overview__legend div {
    margin: 0 .5rem 0 1rem;
  }
}

.overview__legend span {
  align-items: center;
  padding: 0 .25rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .overview__legend span:first-child div {
    margin-left: 0;
  }
}

.overview__opinions {
  background: rgba(255, 255, 255, .08);
  border-radius: .5rem;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.overview__opinion {
  border-bottom: 1px solid rgba(255, 255, 255, .08);
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: .75rem 1rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .overview__opinion {
    flex-direction: row;
    align-items: center;
  }
}

.overview__opinion h2 {
  grid-template-columns: min-content 1fr;
  margin: 0;
  padding: .5rem 0;
  font-size: 1rem;
  display: grid;
}

.overview__opinion h2 span {
  color: #95a8b1;
  padding: 0 .25rem 0 0;
}

@media screen and (max-width: 767px) {
  .overview__opinion h2 {
    padding-left: 2rem;
    font-size: .875rem;
    display: block;
  }

  .overview__opinion h2 span {
    position: absolute;
    left: 1rem;
  }
}

.overview__opinion:first-child {
  z-index: 1;
  background: #3c4b52;
  border-bottom: none;
  transition: transform .5s ease-out;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.overview__opinion:first-child h2 {
  display: none;
}

@media screen and (min-width: 768px) {
  .overview__opinion:first-child {
    border-radius: .5rem .5rem 0 0;
    top: calc(4.5rem + 2px);
    box-shadow: 0 -.5rem #2c383f;
  }

  .overview__opinion:first-child h2 {
    display: block;
  }
}

.overview__opinion:last-child {
  border-bottom: none;
}

.overview__circles {
  justify-content: center;
  margin-left: 2rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .overview__circles {
    justify-content: flex-end;
  }
}

.overview__circle {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1rem;
  flex-shrink: 0;
  margin: .25rem .25rem 0;
}

@media screen and (min-width: 768px) {
  .overview__circle {
    width: 2rem;
    height: 2rem;
    margin: .5rem .5rem 0;
  }
}

.overview__circle img {
  height: 1.5rem;
  width: 1.5rem;
}

@media screen and (min-width: 768px) {
  .overview__circle img {
    height: 2rem;
    width: 2rem;
  }
}

.overview__circle--0 {
  background: #dc5259;
}

.overview__circle--1 {
  background: #e88c49;
}

.overview__circle--2 {
  background: #95a8b1;
}

.overview__circle--3 {
  background: #b7c853;
}

.overview__circle--4 {
  background: #58c263;
}

.overview__circle--0 img, .overview__circle--1 img, .overview__circle--2 img, .overview__circle--3 img, .overview__circle--4 img {
  filter: invert();
  padding: .175rem;
}

@media screen and (min-width: 768px) {
  .overview__circle--0 img, .overview__circle--1 img, .overview__circle--2 img, .overview__circle--3 img, .overview__circle--4 img {
    padding: .375rem;
  }
}

.overview__circle--small {
  width: 1rem;
  height: 1rem;
}

@media screen and (min-width: 768px) {
  .overview__circle--small {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.overview__circle--small img {
  height: 1rem;
  width: 1rem;
}

@media screen and (min-width: 768px) {
  .overview__circle--small img {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.progress {
  width: 100%;
  background: #4c5e67;
}

.progress__horizontal {
  width: 0;
  height: .25rem;
  background: #44baab;
  border-radius: .25rem;
  transition: width .25s;
}

.video {
  width: 100%;
  aspect-ratio: 16 / 9;
}

.video__overlay {
  position: relative;
}

.video__buttons {
  z-index: 10;
  transition: opacity .3s ease-in-out;
  position: absolute;
  top: .25rem;
  right: .25rem;
}

@media screen and (min-width: 480px) {
  .video__buttons {
    top: 1.75rem;
    right: 1.75rem;
  }
}

.video__button {
  min-width: 42px;
  min-height: 42px;
  cursor: pointer;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: opacity .3s ease-in-out;
  display: inline-flex;
}

.video__button:focus-visible {
  opacity: .7;
}

.video__button:focus {
  outline: none;
}

.video__button img {
  box-sizing: content-box;
  width: 14px;
  height: 14px;
  background: rgba(0, 0, 0, .5);
  border-radius: .5rem;
  padding: .5rem;
}

@media screen and (min-width: 768px) {
  .video__button:first-child {
    margin-right: 1rem;
  }

  .video__button img {
    width: unset;
    height: unset;
    padding: .5rem 1rem;
  }
}

.video--controls-hidden .video__button {
  opacity: 0;
}

@media (hover: hover) {
  .video--controls-hidden:hover .video__button {
    opacity: 1;
  }
}

.video__primary-button {
  cursor: auto;
  width: 100%;
  height: 100%;
  z-index: 1;
  color: #fff;
  background-color: rgba(0, 0, 0, .2);
  border: none;
  border-radius: .25rem;
  align-content: center;
  justify-content: center;
  padding: 0;
  display: grid;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.video__primary-button--play {
  opacity: 0;
}

.video__primary-button--pause {
  opacity: 1 !important;
}

.video__primary-button img {
  width: 80px;
  height: 80px;
  background-color: rgba(0, 0, 0, .5);
  border-radius: 100%;
  padding: .5rem;
}

.video__player {
  aspect-ratio: 16 / 9;
  width: 100%;
  background: #8a9ea7;
  border-radius: .25rem;
  display: block;
}

.video__progress {
  color: #fff;
  font-variant-numeric: tabular-nums;
  z-index: 1;
  padding: 4px;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  transition: opacity .3s ease-in-out;
  position: absolute;
  bottom: 12px;
  left: 12px;
}

.video--controls-hidden .video__progress {
  opacity: 0;
}

@media (hover: hover) {
  .video--controls-hidden:hover .video__progress {
    opacity: 1;
  }
}

.about {
  background: linear-gradient(#3c4b52 0%, #2c383f 100%);
  flex: 1;
}

.about__container {
  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
  padding: 3rem 1.5rem 1.5rem;
}

@media screen and (min-width: 768px) {
  .about__container {
    padding: 3rem 0 1.5rem;
  }
}

.about__section h1 {
  margin: 0 0 .75rem;
}

.about__section p {
  color: #95a8b1;
}

.about__section:first-child p {
  color: #fff;
  margin: 0;
}

.about__heading h2 {
  margin: 2.5rem 0 0;
  padding: 0 0 0 1rem;
  font-size: 1.5rem;
  font-weight: 400;
}

.about__heading img {
  margin: 2.375rem 0 0;
}

.about__heading {
  align-items: center;
  display: flex;
}

.about__heading img {
  filter: invert(50%) sepia(60%) saturate(400%) hue-rotate(130deg) brightness(110%) contrast();
}

.about__footer {
  border-top: 1px solid #3c4b52;
  align-items: center;
  margin: 2rem 0 0;
  padding: 1.75rem 0 .5rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .about__footer {
    padding: 2rem 0 1rem;
  }
}

.about__disclaimer {
  padding: 0 1rem 0 0;
  font-size: 1rem;
  line-height: 1.625rem;
}

.about__disclaimer img {
  height: 2.5rem;
  width: 2.5rem;
}

@media screen and (min-width: 768px) {
  .about__disclaimer img {
    height: 3.5rem;
    width: 3.5rem;
  }
}

.about__disclaimer div {
  color: #95a8b1;
  display: inline-block;
}

.about__disclaimer a {
  text-decoration: underline;
  display: block;
}

@media screen and (min-width: 768px) {
  .about__disclaimer {
    padding: 0 1.5rem 0 0;
  }
}

.button {
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
}

.button__container {
  width: 100%;
  border: 1.5px solid rgba(255, 255, 255, .08);
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  padding: .75rem;
  display: flex;
}

.button__container--fill {
  background: rgba(255, 255, 255, .08);
  border: none;
}

.button__container--tv4 {
  background: #ff3334;
  border: none;
  margin: 2rem 0 0;
}

.button__container img {
  height: 1.75rem;
  padding: 0 0 0 .75rem;
}

.button__container span {
  font-weight: 500;
}

.buttonWithoutLabel {
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}

.crucial {
  width: fit-content;
  cursor: pointer;
  white-space: nowrap;
}

.crucial__container {
  background: rgba(255, 255, 255, .08);
  border-radius: 3rem;
  justify-content: center;
  align-items: center;
  margin: 0 0 1.25rem;
  padding: .5rem 1.5rem .5rem .5rem;
  display: flex;
}

.crucial__container img {
  height: 1.75rem;
  width: 1.75rem;
  box-sizing: content-box;
  padding: 0 .75rem;
}

.crucial__container span {
  font-weight: 500;
  line-height: 1.75rem;
}

.header {
  z-index: 11;
  background: #3c4b52;
  transition: transform .5s ease-out;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  transform: translateY(0);
  box-shadow: 0 8px 24px rgba(0, 0, 0, .12);
}

@media screen and (max-width: 767px) {
  .header--hide {
    transform: translateY(-100%);
  }
}

.header__cells {
  grid-template-columns: 1fr 2fr 1fr;
  padding: 0 1.5rem;
  display: grid;
}

.header__cell {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: .75rem 0;
  display: flex;
}

.header__cell span {
  padding: 0 .625rem;
  font-weight: 500;
  display: none;
}

.header__cell:first-child {
  justify-content: flex-start;
}

.header__cell:last-child {
  justify-content: flex-end;
}

.header__cell:focus-visible, .header__cell:focus {
  outline: none;
}

@media screen and (min-width: 768px) {
  .header__cell {
    padding: 1rem 0;
  }

  .header__cell span {
    display: inline-block;
  }
}

.header__claims {
  justify-content: space-between;
  padding: .5rem 0 1rem;
  display: flex;
  position: relative;
  overflow: scroll;
}

.header__claim {
  color: #95a8b1;
  min-width: 2rem;
  min-height: 2rem;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 .75rem;
  font-size: .875rem;
  font-weight: 700;
  display: flex;
}

.header__claim:focus-visible {
  opacity: .7;
}

.header__claim:focus {
  outline: none;
}

.header__claim:focus-visible {
  outline: 2px solid;
}

@media screen and (min-width: 768px) {
  .header__claim {
    margin: 0 0 0 1rem;
  }
}

.header__claim:last-child {
  margin: 0 .75rem;
}

@media screen and (min-width: 768px) {
  .header__claim:last-child {
    margin: 0 1rem;
  }
}

.header__claim--0 {
  color: #dc5259;
  cursor: pointer;
  background: rgba(220, 82, 89, .1);
}

.header__claim--1 {
  color: #e88c49;
  cursor: pointer;
  background: rgba(232, 140, 73, .1);
}

.header__claim--2 {
  cursor: pointer;
  background: #4c5e67;
}

.header__claim--3 {
  color: #b7c853;
  cursor: pointer;
  background: rgba(183, 200, 83, .1);
}

.header__claim--4 {
  color: #58c263;
  cursor: pointer;
  background: rgba(88, 194, 99, .1);
}

.header__claim--active {
  color: #2c383f;
  background: #fff;
}

.header__indicator {
  width: 0;
  height: 0;
  border-bottom: .375rem solid #fff;
  border-left: .375rem solid rgba(0, 0, 0, 0);
  border-right: .375rem solid rgba(0, 0, 0, 0);
  position: absolute;
  bottom: 0;
}

.loaders {
  z-index: 100;
  background: #2c383f;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.loaders img {
  height: 8rem;
  animation: spin 1s linear infinite;
}

.opinion {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  margin: 1rem .375rem;
}

.opinion:focus-visible {
  opacity: .7;
}

.opinion:focus {
  outline: none;
}

@media screen and (min-width: 768px) {
  .opinion {
    flex: 1;
  }
}

.opinion__button {
  border-radius: 2rem;
  justify-content: center;
  align-items: center;
  padding: .375rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .opinion__button {
    padding: .375rem 2rem;
  }
}

@media screen and (min-width: 480px) {
  .opinion__button:hover {
    outline: 2px solid #fff;
  }
}

.opinion__button img {
  width: 1.825rem;
  height: 1.825rem;
  border: 2px solid rgba(0, 0, 0, .04);
  border-radius: 3rem;
  display: block;
}

@media screen and (min-width: 768px) {
  .opinion__button img {
    width: 2.5rem;
    height: 2.5rem;
    border: 4px solid rgba(0, 0, 0, .04);
  }
}

.opinion__button .show-checkmark {
  border-color: rgba(0, 0, 0, 0);
}

.opinion__button.bounce {
  animation: bounce .5s;
}

.opinion__button.bounce img {
  border: 2px solid rgba(0, 0, 0, 0);
}

.opinion__button--0 {
  background: #dc5259;
}

.opinion__button--1 {
  background: #e88c49;
}

.opinion__button--2 {
  background: rgba(156, 165, 168, .36);
}

@media screen and (min-width: 768px) {
  .opinion__button--2 {
    background: none;
    border: 2px solid #3c4b52;
  }

  .opinion__button--2 img {
    border: 4px solid #3c4b52;
  }
}

.opinion__button--3 {
  background: #b7c853;
}

.opinion__button--4 {
  background: #58c263;
}

.overview {
  padding: 0 1.5rem 2.5rem;
}

@media screen and (min-width: 768px) {
  .overview {
    padding: 2.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .overview {
    padding: 5rem;
  }
}

.overview__links {
  padding: 0 0 .75rem;
}

.overview__link {
  cursor: pointer;
  margin: 0 1.5rem 0 0;
  padding: .25rem 0;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.375rem;
}

.overview__link--active {
  border-bottom: 2px solid #44baab;
}

.overview__question {
  max-width: 40rem;
  margin: 0 auto;
  overflow: visible;
}

.overview__slider {
  flex-flow: row;
  display: flex;
}

.overview__slide {
  width: 100%;
  flex-shrink: 0;
}

.overview__slide.animate {
  animation: fade 1.5s;
}

.overview__table {
  min-height: 280px;
  background-image: linear-gradient(rgba(0, 0, 0, 0) 0%, #2c383f 100%), linear-gradient(rgba(255, 255, 255, .08) 0%, rgba(255, 255, 255, 0) 100%), repeating-linear-gradient(90deg, #2c383f 0%, #2c383f 10%, rgba(255, 255, 255, .32) calc(10% + 1px), #2c383f 10%, #2c383f 20%);
  background-repeat: repeat;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: min-content;
  grid-auto-flow: row dense;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  padding-bottom: 20px;
  display: grid;
  position: relative;
}

.overview__table-token {
  text-align: center;
  width: 32px;
  height: 32px;
  margin: 12px auto 0;
}

.overview__table-token--0 {
  grid-column: 1;
}

.overview__table-token--1 {
  grid-column: 2;
}

.overview__table-token--2 {
  grid-column: 3;
}

.overview__table-token--3 {
  grid-column: 4;
}

.overview__table-token--4 {
  grid-column: 5;
}

.overview__table-heading {
  text-align: center;
  background-color: #2c383f;
  margin: 0;
  padding-bottom: 12px;
  font-size: 12px;
}

.overview__table-heading:nth-child(1) {
  color: #ef92aa;
}

.overview__table-heading:nth-child(2) {
  color: #f39f95;
}

.overview__table-heading:nth-child(3) {
  color: #b4bcbe;
}

.overview__table-heading:nth-child(4) {
  color: #d8e0a2;
}

.overview__table-heading:nth-child(5) {
  color: #9ede97;
}

@media screen and (max-width: 767px) {
  .overview__table-heading:nth-child(1) {
    text-align: left;
    grid-column: span 2;
    padding-left: 12px;
  }

  .overview__table-heading:nth-child(5) {
    text-align: right;
    grid-column: span 2;
    padding-right: 12px;
  }

  .overview__table-heading:nth-child(2), .overview__table-heading:nth-child(4) {
    display: none;
  }
}

.overview__line {
  height: 4px;
  width: 100%;
  background: linear-gradient(90deg, #da3a55 10.23%, #e8834a 29.87%, #fff 50.31%, #c2cf57 69.79%, #4ec869 90.26%);
  border: none;
  grid-column: 1 / -1;
  margin: 0;
}

.overview__heading {
  padding: .75rem 0;
}

.overview__heading span {
  color: #44baab;
}

.overview__buttons {
  display: flex;
}

.overview__button {
  width: 100%;
  cursor: pointer;
  background: rgba(255, 255, 255, .08);
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  margin: 0 1rem 0 0;
  padding: .75rem;
  font-weight: 500;
  display: flex;
}

.overview__button img {
  height: 1.75rem;
  padding: 0 0 0 .75rem;
}

.overview__button:first-child img {
  transform: rotate(180deg);
}

.overview__button:nth-child(2) {
  max-width: 12ch;
  color: #44baab;
  cursor: default;
  background: none;
  border: 2px solid rgba(255, 255, 255, .08);
  font-size: .75rem;
  font-weight: 700;
}

.overview__button:last-child {
  margin: 0;
}

.overview__button--disabled {
  color: rgba(255, 255, 255, .16);
  cursor: default;
}

.overview__button--disabled img {
  opacity: .16;
}

.overview__preamble {
  color: #95a8b1;
}

.overview__preamble span {
  font-weight: 700;
}

.overview__argument {
  border-radius: .5rem;
  margin: 1.25rem 0;
  padding: 1rem;
  position: relative;
}

.overview__argument--pro {
  background: rgba(68, 186, 171, .1);
}

.overview__argument--pro div {
  background: #44baab;
}

.overview__argument--con {
  background: rgba(220, 82, 89, .1);
}

.overview__argument--con div {
  background: #dc5259;
}

.overview__argument p {
  color: #fff;
  margin: 0;
}

.overview__argument div {
  width: .125rem;
  height: calc(100% - 2rem);
  border-radius: 1px;
  position: absolute;
  top: 1rem;
  left: 0;
}

.overview__legend {
  color: #95a8b1;
  grid-template-columns: 1fr 1fr 1fr;
  padding: .75rem 0;
  font-size: .75rem;
  display: grid;
}

@media screen and (min-width: 768px) {
  .overview__legend {
    font-size: 1rem;
    display: flex;
  }
}

@media screen and (min-width: 768px) {
  .overview__legend div {
    margin: 0 .5rem 0 1rem;
  }
}

.overview__legend span {
  align-items: center;
  padding: 0 .25rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .overview__legend span:first-child div {
    margin-left: 0;
  }
}

.overview__opinions {
  background: rgba(255, 255, 255, .08);
  border-radius: .5rem;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.overview__opinion {
  border-bottom: 1px solid rgba(255, 255, 255, .08);
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: .75rem 1rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .overview__opinion {
    flex-direction: row;
    align-items: center;
  }
}

.overview__opinion h2 {
  grid-template-columns: min-content 1fr;
  margin: 0;
  padding: .5rem 0;
  font-size: 1rem;
  display: grid;
}

.overview__opinion h2 span {
  color: #95a8b1;
  padding: 0 .25rem 0 0;
}

@media screen and (max-width: 767px) {
  .overview__opinion h2 {
    padding-left: 2rem;
    font-size: .875rem;
    display: block;
  }

  .overview__opinion h2 span {
    position: absolute;
    left: 1rem;
  }
}

.overview__opinion:first-child {
  z-index: 1;
  background: #3c4b52;
  border-bottom: none;
  transition: transform .5s ease-out;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.overview__opinion:first-child h2 {
  display: none;
}

@media screen and (min-width: 768px) {
  .overview__opinion:first-child {
    border-radius: .5rem .5rem 0 0;
    top: calc(4.5rem + 2px);
    box-shadow: 0 -.5rem #2c383f;
  }

  .overview__opinion:first-child h2 {
    display: block;
  }
}

.overview__opinion:last-child {
  border-bottom: none;
}

.overview__circles {
  justify-content: center;
  margin-left: 2rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .overview__circles {
    justify-content: flex-end;
  }
}

.overview__circle {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1rem;
  flex-shrink: 0;
  margin: .25rem .25rem 0;
}

@media screen and (min-width: 768px) {
  .overview__circle {
    width: 2rem;
    height: 2rem;
    margin: .5rem .5rem 0;
  }
}

.overview__circle img {
  height: 1.5rem;
  width: 1.5rem;
}

@media screen and (min-width: 768px) {
  .overview__circle img {
    height: 2rem;
    width: 2rem;
  }
}

.overview__circle--0 {
  background: #dc5259;
}

.overview__circle--1 {
  background: #e88c49;
}

.overview__circle--2 {
  background: #95a8b1;
}

.overview__circle--3 {
  background: #b7c853;
}

.overview__circle--4 {
  background: #58c263;
}

.overview__circle--0 img, .overview__circle--1 img, .overview__circle--2 img, .overview__circle--3 img, .overview__circle--4 img {
  filter: invert();
  padding: .175rem;
}

@media screen and (min-width: 768px) {
  .overview__circle--0 img, .overview__circle--1 img, .overview__circle--2 img, .overview__circle--3 img, .overview__circle--4 img {
    padding: .375rem;
  }
}

.overview__circle--small {
  width: 1rem;
  height: 1rem;
}

@media screen and (min-width: 768px) {
  .overview__circle--small {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.overview__circle--small img {
  height: 1rem;
  width: 1rem;
}

@media screen and (min-width: 768px) {
  .overview__circle--small img {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.progress {
  width: 100%;
  background: #4c5e67;
}

.progress__horizontal {
  width: 0;
  height: .25rem;
  background: #44baab;
  border-radius: .25rem;
  transition: width .25s;
}

.video {
  width: 100%;
  aspect-ratio: 16 / 9;
}

.video__overlay {
  position: relative;
}

.video__buttons {
  z-index: 10;
  transition: opacity .3s ease-in-out;
  position: absolute;
  top: .25rem;
  right: .25rem;
}

@media screen and (min-width: 480px) {
  .video__buttons {
    top: 1.75rem;
    right: 1.75rem;
  }
}

.video__button {
  min-width: 42px;
  min-height: 42px;
  cursor: pointer;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: opacity .3s ease-in-out;
  display: inline-flex;
}

.video__button:focus-visible {
  opacity: .7;
}

.video__button:focus {
  outline: none;
}

.video__button img {
  box-sizing: content-box;
  width: 14px;
  height: 14px;
  background: rgba(0, 0, 0, .5);
  border-radius: .5rem;
  padding: .5rem;
}

@media screen and (min-width: 768px) {
  .video__button:first-child {
    margin-right: 1rem;
  }

  .video__button img {
    width: unset;
    height: unset;
    padding: .5rem 1rem;
  }
}

.video--controls-hidden .video__button {
  opacity: 0;
}

@media (hover: hover) {
  .video--controls-hidden:hover .video__button {
    opacity: 1;
  }
}

.video__primary-button {
  cursor: auto;
  width: 100%;
  height: 100%;
  z-index: 1;
  color: #fff;
  background-color: rgba(0, 0, 0, .2);
  border: none;
  border-radius: .25rem;
  align-content: center;
  justify-content: center;
  padding: 0;
  display: grid;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.video__primary-button--play {
  opacity: 0;
}

.video__primary-button--pause {
  opacity: 1 !important;
}

.video__primary-button img {
  width: 80px;
  height: 80px;
  background-color: rgba(0, 0, 0, .5);
  border-radius: 100%;
  padding: .5rem;
}

.video__player {
  aspect-ratio: 16 / 9;
  width: 100%;
  background: #8a9ea7;
  border-radius: .25rem;
  display: block;
}

.video__progress {
  color: #fff;
  font-variant-numeric: tabular-nums;
  z-index: 1;
  padding: 4px;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  transition: opacity .3s ease-in-out;
  position: absolute;
  bottom: 12px;
  left: 12px;
}

.video--controls-hidden .video__progress {
  opacity: 0;
}

@media (hover: hover) {
  .video--controls-hidden:hover .video__progress {
    opacity: 1;
  }
}

.about {
  background: linear-gradient(#3c4b52 0%, #2c383f 100%);
  flex: 1;
}

.about__container {
  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
  padding: 3rem 1.5rem 1.5rem;
}

@media screen and (min-width: 768px) {
  .about__container {
    padding: 3rem 0 1.5rem;
  }
}

.about__section h1 {
  margin: 0 0 .75rem;
}

.about__section p {
  color: #95a8b1;
}

.about__section:first-child p {
  color: #fff;
  margin: 0;
}

.about__heading h2 {
  margin: 2.5rem 0 0;
  padding: 0 0 0 1rem;
  font-size: 1.5rem;
  font-weight: 400;
}

.about__heading img {
  margin: 2.375rem 0 0;
}

.about__heading {
  align-items: center;
  display: flex;
}

.about__heading img {
  filter: invert(50%) sepia(60%) saturate(400%) hue-rotate(130deg) brightness(110%) contrast();
}

.about__footer {
  border-top: 1px solid #3c4b52;
  align-items: center;
  margin: 2rem 0 0;
  padding: 1.75rem 0 .5rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .about__footer {
    padding: 2rem 0 1rem;
  }
}

.about__disclaimer {
  padding: 0 1rem 0 0;
  font-size: 1rem;
  line-height: 1.625rem;
}

.about__disclaimer img {
  height: 2.5rem;
  width: 2.5rem;
}

@media screen and (min-width: 768px) {
  .about__disclaimer img {
    height: 3.5rem;
    width: 3.5rem;
  }
}

.about__disclaimer div {
  color: #95a8b1;
  display: inline-block;
}

.about__disclaimer a {
  text-decoration: underline;
  display: block;
}

@media screen and (min-width: 768px) {
  .about__disclaimer {
    padding: 0 1.5rem 0 0;
  }
}

.button {
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
}

.button__container {
  width: 100%;
  border: 1.5px solid rgba(255, 255, 255, .08);
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  padding: .75rem;
  display: flex;
}

.button__container--fill {
  background: rgba(255, 255, 255, .08);
  border: none;
}

.button__container--tv4 {
  background: #ff3334;
  border: none;
  margin: 2rem 0 0;
}

.button__container img {
  height: 1.75rem;
  padding: 0 0 0 .75rem;
}

.button__container span {
  font-weight: 500;
}

.buttonWithoutLabel {
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}

.crucial {
  width: fit-content;
  cursor: pointer;
  white-space: nowrap;
}

.crucial__container {
  background: rgba(255, 255, 255, .08);
  border-radius: 3rem;
  justify-content: center;
  align-items: center;
  margin: 0 0 1.25rem;
  padding: .5rem 1.5rem .5rem .5rem;
  display: flex;
}

.crucial__container img {
  height: 1.75rem;
  width: 1.75rem;
  box-sizing: content-box;
  padding: 0 .75rem;
}

.crucial__container span {
  font-weight: 500;
  line-height: 1.75rem;
}

.header {
  z-index: 11;
  background: #3c4b52;
  transition: transform .5s ease-out;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  transform: translateY(0);
  box-shadow: 0 8px 24px rgba(0, 0, 0, .12);
}

@media screen and (max-width: 767px) {
  .header--hide {
    transform: translateY(-100%);
  }
}

.header__cells {
  grid-template-columns: 1fr 2fr 1fr;
  padding: 0 1.5rem;
  display: grid;
}

.header__cell {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: .75rem 0;
  display: flex;
}

.header__cell span {
  padding: 0 .625rem;
  font-weight: 500;
  display: none;
}

.header__cell:first-child {
  justify-content: flex-start;
}

.header__cell:last-child {
  justify-content: flex-end;
}

.header__cell:focus-visible, .header__cell:focus {
  outline: none;
}

@media screen and (min-width: 768px) {
  .header__cell {
    padding: 1rem 0;
  }

  .header__cell span {
    display: inline-block;
  }
}

.header__claims {
  justify-content: space-between;
  padding: .5rem 0 1rem;
  display: flex;
  position: relative;
  overflow: scroll;
}

.header__claim {
  color: #95a8b1;
  min-width: 2rem;
  min-height: 2rem;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 .75rem;
  font-size: .875rem;
  font-weight: 700;
  display: flex;
}

.header__claim:focus-visible {
  opacity: .7;
}

.header__claim:focus {
  outline: none;
}

.header__claim:focus-visible {
  outline: 2px solid;
}

@media screen and (min-width: 768px) {
  .header__claim {
    margin: 0 0 0 1rem;
  }
}

.header__claim:last-child {
  margin: 0 .75rem;
}

@media screen and (min-width: 768px) {
  .header__claim:last-child {
    margin: 0 1rem;
  }
}

.header__claim--0 {
  color: #dc5259;
  cursor: pointer;
  background: rgba(220, 82, 89, .1);
}

.header__claim--1 {
  color: #e88c49;
  cursor: pointer;
  background: rgba(232, 140, 73, .1);
}

.header__claim--2 {
  cursor: pointer;
  background: #4c5e67;
}

.header__claim--3 {
  color: #b7c853;
  cursor: pointer;
  background: rgba(183, 200, 83, .1);
}

.header__claim--4 {
  color: #58c263;
  cursor: pointer;
  background: rgba(88, 194, 99, .1);
}

.header__claim--active {
  color: #2c383f;
  background: #fff;
}

.header__indicator {
  width: 0;
  height: 0;
  border-bottom: .375rem solid #fff;
  border-left: .375rem solid rgba(0, 0, 0, 0);
  border-right: .375rem solid rgba(0, 0, 0, 0);
  position: absolute;
  bottom: 0;
}

.loaders {
  z-index: 100;
  background: #2c383f;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.loaders img {
  height: 8rem;
  animation: spin 1s linear infinite;
}

.opinion {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  margin: 1rem .375rem;
}

.opinion:focus-visible {
  opacity: .7;
}

.opinion:focus {
  outline: none;
}

@media screen and (min-width: 768px) {
  .opinion {
    flex: 1;
  }
}

.opinion__button {
  border-radius: 2rem;
  justify-content: center;
  align-items: center;
  padding: .375rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .opinion__button {
    padding: .375rem 2rem;
  }
}

@media screen and (min-width: 480px) {
  .opinion__button:hover {
    outline: 2px solid #fff;
  }
}

.opinion__button img {
  width: 1.825rem;
  height: 1.825rem;
  border: 2px solid rgba(0, 0, 0, .04);
  border-radius: 3rem;
  display: block;
}

@media screen and (min-width: 768px) {
  .opinion__button img {
    width: 2.5rem;
    height: 2.5rem;
    border: 4px solid rgba(0, 0, 0, .04);
  }
}

.opinion__button .show-checkmark {
  border-color: rgba(0, 0, 0, 0);
}

.opinion__button.bounce {
  animation: bounce .5s;
}

.opinion__button.bounce img {
  border: 2px solid rgba(0, 0, 0, 0);
}

.opinion__button--0 {
  background: #dc5259;
}

.opinion__button--1 {
  background: #e88c49;
}

.opinion__button--2 {
  background: rgba(156, 165, 168, .36);
}

@media screen and (min-width: 768px) {
  .opinion__button--2 {
    background: none;
    border: 2px solid #3c4b52;
  }

  .opinion__button--2 img {
    border: 4px solid #3c4b52;
  }
}

.opinion__button--3 {
  background: #b7c853;
}

.opinion__button--4 {
  background: #58c263;
}

.overview {
  padding: 0 1.5rem 2.5rem;
}

@media screen and (min-width: 768px) {
  .overview {
    padding: 2.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .overview {
    padding: 5rem;
  }
}

.overview__links {
  padding: 0 0 .75rem;
}

.overview__link {
  cursor: pointer;
  margin: 0 1.5rem 0 0;
  padding: .25rem 0;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.375rem;
}

.overview__link--active {
  border-bottom: 2px solid #44baab;
}

.overview__question {
  max-width: 40rem;
  margin: 0 auto;
  overflow: visible;
}

.overview__slider {
  flex-flow: row;
  display: flex;
}

.overview__slide {
  width: 100%;
  flex-shrink: 0;
}

.overview__slide.animate {
  animation: fade 1.5s;
}

.overview__table {
  min-height: 280px;
  background-image: linear-gradient(rgba(0, 0, 0, 0) 0%, #2c383f 100%), linear-gradient(rgba(255, 255, 255, .08) 0%, rgba(255, 255, 255, 0) 100%), repeating-linear-gradient(90deg, #2c383f 0%, #2c383f 10%, rgba(255, 255, 255, .32) calc(10% + 1px), #2c383f 10%, #2c383f 20%);
  background-repeat: repeat;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: min-content;
  grid-auto-flow: row dense;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  padding-bottom: 20px;
  display: grid;
  position: relative;
}

.overview__table-token {
  text-align: center;
  width: 32px;
  height: 32px;
  margin: 12px auto 0;
}

.overview__table-token--0 {
  grid-column: 1;
}

.overview__table-token--1 {
  grid-column: 2;
}

.overview__table-token--2 {
  grid-column: 3;
}

.overview__table-token--3 {
  grid-column: 4;
}

.overview__table-token--4 {
  grid-column: 5;
}

.overview__table-heading {
  text-align: center;
  background-color: #2c383f;
  margin: 0;
  padding-bottom: 12px;
  font-size: 12px;
}

.overview__table-heading:nth-child(1) {
  color: #ef92aa;
}

.overview__table-heading:nth-child(2) {
  color: #f39f95;
}

.overview__table-heading:nth-child(3) {
  color: #b4bcbe;
}

.overview__table-heading:nth-child(4) {
  color: #d8e0a2;
}

.overview__table-heading:nth-child(5) {
  color: #9ede97;
}

@media screen and (max-width: 767px) {
  .overview__table-heading:nth-child(1) {
    text-align: left;
    grid-column: span 2;
    padding-left: 12px;
  }

  .overview__table-heading:nth-child(5) {
    text-align: right;
    grid-column: span 2;
    padding-right: 12px;
  }

  .overview__table-heading:nth-child(2), .overview__table-heading:nth-child(4) {
    display: none;
  }
}

.overview__line {
  height: 4px;
  width: 100%;
  background: linear-gradient(90deg, #da3a55 10.23%, #e8834a 29.87%, #fff 50.31%, #c2cf57 69.79%, #4ec869 90.26%);
  border: none;
  grid-column: 1 / -1;
  margin: 0;
}

.overview__heading {
  padding: .75rem 0;
}

.overview__heading span {
  color: #44baab;
}

.overview__buttons {
  display: flex;
}

.overview__button {
  width: 100%;
  cursor: pointer;
  background: rgba(255, 255, 255, .08);
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  margin: 0 1rem 0 0;
  padding: .75rem;
  font-weight: 500;
  display: flex;
}

.overview__button img {
  height: 1.75rem;
  padding: 0 0 0 .75rem;
}

.overview__button:first-child img {
  transform: rotate(180deg);
}

.overview__button:nth-child(2) {
  max-width: 12ch;
  color: #44baab;
  cursor: default;
  background: none;
  border: 2px solid rgba(255, 255, 255, .08);
  font-size: .75rem;
  font-weight: 700;
}

.overview__button:last-child {
  margin: 0;
}

.overview__button--disabled {
  color: rgba(255, 255, 255, .16);
  cursor: default;
}

.overview__button--disabled img {
  opacity: .16;
}

.overview__preamble {
  color: #95a8b1;
}

.overview__preamble span {
  font-weight: 700;
}

.overview__argument {
  border-radius: .5rem;
  margin: 1.25rem 0;
  padding: 1rem;
  position: relative;
}

.overview__argument--pro {
  background: rgba(68, 186, 171, .1);
}

.overview__argument--pro div {
  background: #44baab;
}

.overview__argument--con {
  background: rgba(220, 82, 89, .1);
}

.overview__argument--con div {
  background: #dc5259;
}

.overview__argument p {
  color: #fff;
  margin: 0;
}

.overview__argument div {
  width: .125rem;
  height: calc(100% - 2rem);
  border-radius: 1px;
  position: absolute;
  top: 1rem;
  left: 0;
}

.overview__legend {
  color: #95a8b1;
  grid-template-columns: 1fr 1fr 1fr;
  padding: .75rem 0;
  font-size: .75rem;
  display: grid;
}

@media screen and (min-width: 768px) {
  .overview__legend {
    font-size: 1rem;
    display: flex;
  }
}

@media screen and (min-width: 768px) {
  .overview__legend div {
    margin: 0 .5rem 0 1rem;
  }
}

.overview__legend span {
  align-items: center;
  padding: 0 .25rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .overview__legend span:first-child div {
    margin-left: 0;
  }
}

.overview__opinions {
  background: rgba(255, 255, 255, .08);
  border-radius: .5rem;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.overview__opinion {
  border-bottom: 1px solid rgba(255, 255, 255, .08);
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: .75rem 1rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .overview__opinion {
    flex-direction: row;
    align-items: center;
  }
}

.overview__opinion h2 {
  grid-template-columns: min-content 1fr;
  margin: 0;
  padding: .5rem 0;
  font-size: 1rem;
  display: grid;
}

.overview__opinion h2 span {
  color: #95a8b1;
  padding: 0 .25rem 0 0;
}

@media screen and (max-width: 767px) {
  .overview__opinion h2 {
    padding-left: 2rem;
    font-size: .875rem;
    display: block;
  }

  .overview__opinion h2 span {
    position: absolute;
    left: 1rem;
  }
}

.overview__opinion:first-child {
  z-index: 1;
  background: #3c4b52;
  border-bottom: none;
  transition: transform .5s ease-out;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.overview__opinion:first-child h2 {
  display: none;
}

@media screen and (min-width: 768px) {
  .overview__opinion:first-child {
    border-radius: .5rem .5rem 0 0;
    top: calc(4.5rem + 2px);
    box-shadow: 0 -.5rem #2c383f;
  }

  .overview__opinion:first-child h2 {
    display: block;
  }
}

.overview__opinion:last-child {
  border-bottom: none;
}

.overview__circles {
  justify-content: center;
  margin-left: 2rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .overview__circles {
    justify-content: flex-end;
  }
}

.overview__circle {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1rem;
  flex-shrink: 0;
  margin: .25rem .25rem 0;
}

@media screen and (min-width: 768px) {
  .overview__circle {
    width: 2rem;
    height: 2rem;
    margin: .5rem .5rem 0;
  }
}

.overview__circle img {
  height: 1.5rem;
  width: 1.5rem;
}

@media screen and (min-width: 768px) {
  .overview__circle img {
    height: 2rem;
    width: 2rem;
  }
}

.overview__circle--0 {
  background: #dc5259;
}

.overview__circle--1 {
  background: #e88c49;
}

.overview__circle--2 {
  background: #95a8b1;
}

.overview__circle--3 {
  background: #b7c853;
}

.overview__circle--4 {
  background: #58c263;
}

.overview__circle--0 img, .overview__circle--1 img, .overview__circle--2 img, .overview__circle--3 img, .overview__circle--4 img {
  filter: invert();
  padding: .175rem;
}

@media screen and (min-width: 768px) {
  .overview__circle--0 img, .overview__circle--1 img, .overview__circle--2 img, .overview__circle--3 img, .overview__circle--4 img {
    padding: .375rem;
  }
}

.overview__circle--small {
  width: 1rem;
  height: 1rem;
}

@media screen and (min-width: 768px) {
  .overview__circle--small {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.overview__circle--small img {
  height: 1rem;
  width: 1rem;
}

@media screen and (min-width: 768px) {
  .overview__circle--small img {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.progress {
  width: 100%;
  background: #4c5e67;
}

.progress__horizontal {
  width: 0;
  height: .25rem;
  background: #44baab;
  border-radius: .25rem;
  transition: width .25s;
}

.video {
  width: 100%;
  aspect-ratio: 16 / 9;
}

.video__overlay {
  position: relative;
}

.video__buttons {
  z-index: 10;
  transition: opacity .3s ease-in-out;
  position: absolute;
  top: .25rem;
  right: .25rem;
}

@media screen and (min-width: 480px) {
  .video__buttons {
    top: 1.75rem;
    right: 1.75rem;
  }
}

.video__button {
  min-width: 42px;
  min-height: 42px;
  cursor: pointer;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: opacity .3s ease-in-out;
  display: inline-flex;
}

.video__button:focus-visible {
  opacity: .7;
}

.video__button:focus {
  outline: none;
}

.video__button img {
  box-sizing: content-box;
  width: 14px;
  height: 14px;
  background: rgba(0, 0, 0, .5);
  border-radius: .5rem;
  padding: .5rem;
}

@media screen and (min-width: 768px) {
  .video__button:first-child {
    margin-right: 1rem;
  }

  .video__button img {
    width: unset;
    height: unset;
    padding: .5rem 1rem;
  }
}

.video--controls-hidden .video__button {
  opacity: 0;
}

@media (hover: hover) {
  .video--controls-hidden:hover .video__button {
    opacity: 1;
  }
}

.video__primary-button {
  cursor: auto;
  width: 100%;
  height: 100%;
  z-index: 1;
  color: #fff;
  background-color: rgba(0, 0, 0, .2);
  border: none;
  border-radius: .25rem;
  align-content: center;
  justify-content: center;
  padding: 0;
  display: grid;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.video__primary-button--play {
  opacity: 0;
}

.video__primary-button--pause {
  opacity: 1 !important;
}

.video__primary-button img {
  width: 80px;
  height: 80px;
  background-color: rgba(0, 0, 0, .5);
  border-radius: 100%;
  padding: .5rem;
}

.video__player {
  aspect-ratio: 16 / 9;
  width: 100%;
  background: #8a9ea7;
  border-radius: .25rem;
  display: block;
}

.video__progress {
  color: #fff;
  font-variant-numeric: tabular-nums;
  z-index: 1;
  padding: 4px;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  transition: opacity .3s ease-in-out;
  position: absolute;
  bottom: 12px;
  left: 12px;
}

.video--controls-hidden .video__progress {
  opacity: 0;
}

@media (hover: hover) {
  .video--controls-hidden:hover .video__progress {
    opacity: 1;
  }
}

.about {
  background: linear-gradient(#3c4b52 0%, #2c383f 100%);
  flex: 1;
}

.about__container {
  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
  padding: 3rem 1.5rem 1.5rem;
}

@media screen and (min-width: 768px) {
  .about__container {
    padding: 3rem 0 1.5rem;
  }
}

.about__section h1 {
  margin: 0 0 .75rem;
}

.about__section p {
  color: #95a8b1;
}

.about__section:first-child p {
  color: #fff;
  margin: 0;
}

.about__heading h2 {
  margin: 2.5rem 0 0;
  padding: 0 0 0 1rem;
  font-size: 1.5rem;
  font-weight: 400;
}

.about__heading img {
  margin: 2.375rem 0 0;
}

.about__heading {
  align-items: center;
  display: flex;
}

.about__heading img {
  filter: invert(50%) sepia(60%) saturate(400%) hue-rotate(130deg) brightness(110%) contrast();
}

.about__footer {
  border-top: 1px solid #3c4b52;
  align-items: center;
  margin: 2rem 0 0;
  padding: 1.75rem 0 .5rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .about__footer {
    padding: 2rem 0 1rem;
  }
}

.about__disclaimer {
  padding: 0 1rem 0 0;
  font-size: 1rem;
  line-height: 1.625rem;
}

.about__disclaimer img {
  height: 2.5rem;
  width: 2.5rem;
}

@media screen and (min-width: 768px) {
  .about__disclaimer img {
    height: 3.5rem;
    width: 3.5rem;
  }
}

.about__disclaimer div {
  color: #95a8b1;
  display: inline-block;
}

.about__disclaimer a {
  text-decoration: underline;
  display: block;
}

@media screen and (min-width: 768px) {
  .about__disclaimer {
    padding: 0 1.5rem 0 0;
  }
}

.button {
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
}

.button__container {
  width: 100%;
  border: 1.5px solid rgba(255, 255, 255, .08);
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  padding: .75rem;
  display: flex;
}

.button__container--fill {
  background: rgba(255, 255, 255, .08);
  border: none;
}

.button__container--tv4 {
  background: #ff3334;
  border: none;
  margin: 2rem 0 0;
}

.button__container img {
  height: 1.75rem;
  padding: 0 0 0 .75rem;
}

.button__container span {
  font-weight: 500;
}

.buttonWithoutLabel {
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}

.crucial {
  width: fit-content;
  cursor: pointer;
  white-space: nowrap;
}

.crucial__container {
  background: rgba(255, 255, 255, .08);
  border-radius: 3rem;
  justify-content: center;
  align-items: center;
  margin: 0 0 1.25rem;
  padding: .5rem 1.5rem .5rem .5rem;
  display: flex;
}

.crucial__container img {
  height: 1.75rem;
  width: 1.75rem;
  box-sizing: content-box;
  padding: 0 .75rem;
}

.crucial__container span {
  font-weight: 500;
  line-height: 1.75rem;
}

.header {
  z-index: 11;
  background: #3c4b52;
  transition: transform .5s ease-out;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  transform: translateY(0);
  box-shadow: 0 8px 24px rgba(0, 0, 0, .12);
}

@media screen and (max-width: 767px) {
  .header--hide {
    transform: translateY(-100%);
  }
}

.header__cells {
  grid-template-columns: 1fr 2fr 1fr;
  padding: 0 1.5rem;
  display: grid;
}

.header__cell {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: .75rem 0;
  display: flex;
}

.header__cell span {
  padding: 0 .625rem;
  font-weight: 500;
  display: none;
}

.header__cell:first-child {
  justify-content: flex-start;
}

.header__cell:last-child {
  justify-content: flex-end;
}

.header__cell:focus-visible, .header__cell:focus {
  outline: none;
}

@media screen and (min-width: 768px) {
  .header__cell {
    padding: 1rem 0;
  }

  .header__cell span {
    display: inline-block;
  }
}

.header__claims {
  justify-content: space-between;
  padding: .5rem 0 1rem;
  display: flex;
  position: relative;
  overflow: scroll;
}

.header__claim {
  color: #95a8b1;
  min-width: 2rem;
  min-height: 2rem;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 .75rem;
  font-size: .875rem;
  font-weight: 700;
  display: flex;
}

.header__claim:focus-visible {
  opacity: .7;
}

.header__claim:focus {
  outline: none;
}

.header__claim:focus-visible {
  outline: 2px solid;
}

@media screen and (min-width: 768px) {
  .header__claim {
    margin: 0 0 0 1rem;
  }
}

.header__claim:last-child {
  margin: 0 .75rem;
}

@media screen and (min-width: 768px) {
  .header__claim:last-child {
    margin: 0 1rem;
  }
}

.header__claim--0 {
  color: #dc5259;
  cursor: pointer;
  background: rgba(220, 82, 89, .1);
}

.header__claim--1 {
  color: #e88c49;
  cursor: pointer;
  background: rgba(232, 140, 73, .1);
}

.header__claim--2 {
  cursor: pointer;
  background: #4c5e67;
}

.header__claim--3 {
  color: #b7c853;
  cursor: pointer;
  background: rgba(183, 200, 83, .1);
}

.header__claim--4 {
  color: #58c263;
  cursor: pointer;
  background: rgba(88, 194, 99, .1);
}

.header__claim--active {
  color: #2c383f;
  background: #fff;
}

.header__indicator {
  width: 0;
  height: 0;
  border-bottom: .375rem solid #fff;
  border-left: .375rem solid rgba(0, 0, 0, 0);
  border-right: .375rem solid rgba(0, 0, 0, 0);
  position: absolute;
  bottom: 0;
}

.loaders {
  z-index: 100;
  background: #2c383f;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.loaders img {
  height: 8rem;
  animation: spin 1s linear infinite;
}

.opinion {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  margin: 1rem .375rem;
}

.opinion:focus-visible {
  opacity: .7;
}

.opinion:focus {
  outline: none;
}

@media screen and (min-width: 768px) {
  .opinion {
    flex: 1;
  }
}

.opinion__button {
  border-radius: 2rem;
  justify-content: center;
  align-items: center;
  padding: .375rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .opinion__button {
    padding: .375rem 2rem;
  }
}

@media screen and (min-width: 480px) {
  .opinion__button:hover {
    outline: 2px solid #fff;
  }
}

.opinion__button img {
  width: 1.825rem;
  height: 1.825rem;
  border: 2px solid rgba(0, 0, 0, .04);
  border-radius: 3rem;
  display: block;
}

@media screen and (min-width: 768px) {
  .opinion__button img {
    width: 2.5rem;
    height: 2.5rem;
    border: 4px solid rgba(0, 0, 0, .04);
  }
}

.opinion__button .show-checkmark {
  border-color: rgba(0, 0, 0, 0);
}

.opinion__button.bounce {
  animation: bounce .5s;
}

.opinion__button.bounce img {
  border: 2px solid rgba(0, 0, 0, 0);
}

.opinion__button--0 {
  background: #dc5259;
}

.opinion__button--1 {
  background: #e88c49;
}

.opinion__button--2 {
  background: rgba(156, 165, 168, .36);
}

@media screen and (min-width: 768px) {
  .opinion__button--2 {
    background: none;
    border: 2px solid #3c4b52;
  }

  .opinion__button--2 img {
    border: 4px solid #3c4b52;
  }
}

.opinion__button--3 {
  background: #b7c853;
}

.opinion__button--4 {
  background: #58c263;
}

.overview {
  padding: 0 1.5rem 2.5rem;
}

@media screen and (min-width: 768px) {
  .overview {
    padding: 2.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .overview {
    padding: 5rem;
  }
}

.overview__links {
  padding: 0 0 .75rem;
}

.overview__link {
  cursor: pointer;
  margin: 0 1.5rem 0 0;
  padding: .25rem 0;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.375rem;
}

.overview__link--active {
  border-bottom: 2px solid #44baab;
}

.overview__question {
  max-width: 40rem;
  margin: 0 auto;
  overflow: visible;
}

.overview__slider {
  flex-flow: row;
  display: flex;
}

.overview__slide {
  width: 100%;
  flex-shrink: 0;
}

.overview__slide.animate {
  animation: fade 1.5s;
}

.overview__table {
  min-height: 280px;
  background-image: linear-gradient(rgba(0, 0, 0, 0) 0%, #2c383f 100%), linear-gradient(rgba(255, 255, 255, .08) 0%, rgba(255, 255, 255, 0) 100%), repeating-linear-gradient(90deg, #2c383f 0%, #2c383f 10%, rgba(255, 255, 255, .32) calc(10% + 1px), #2c383f 10%, #2c383f 20%);
  background-repeat: repeat;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: min-content;
  grid-auto-flow: row dense;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  padding-bottom: 20px;
  display: grid;
  position: relative;
}

.overview__table-token {
  text-align: center;
  width: 32px;
  height: 32px;
  margin: 12px auto 0;
}

.overview__table-token--0 {
  grid-column: 1;
}

.overview__table-token--1 {
  grid-column: 2;
}

.overview__table-token--2 {
  grid-column: 3;
}

.overview__table-token--3 {
  grid-column: 4;
}

.overview__table-token--4 {
  grid-column: 5;
}

.overview__table-heading {
  text-align: center;
  background-color: #2c383f;
  margin: 0;
  padding-bottom: 12px;
  font-size: 12px;
}

.overview__table-heading:nth-child(1) {
  color: #ef92aa;
}

.overview__table-heading:nth-child(2) {
  color: #f39f95;
}

.overview__table-heading:nth-child(3) {
  color: #b4bcbe;
}

.overview__table-heading:nth-child(4) {
  color: #d8e0a2;
}

.overview__table-heading:nth-child(5) {
  color: #9ede97;
}

@media screen and (max-width: 767px) {
  .overview__table-heading:nth-child(1) {
    text-align: left;
    grid-column: span 2;
    padding-left: 12px;
  }

  .overview__table-heading:nth-child(5) {
    text-align: right;
    grid-column: span 2;
    padding-right: 12px;
  }

  .overview__table-heading:nth-child(2), .overview__table-heading:nth-child(4) {
    display: none;
  }
}

.overview__line {
  height: 4px;
  width: 100%;
  background: linear-gradient(90deg, #da3a55 10.23%, #e8834a 29.87%, #fff 50.31%, #c2cf57 69.79%, #4ec869 90.26%);
  border: none;
  grid-column: 1 / -1;
  margin: 0;
}

.overview__heading {
  padding: .75rem 0;
}

.overview__heading span {
  color: #44baab;
}

.overview__buttons {
  display: flex;
}

.overview__button {
  width: 100%;
  cursor: pointer;
  background: rgba(255, 255, 255, .08);
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  margin: 0 1rem 0 0;
  padding: .75rem;
  font-weight: 500;
  display: flex;
}

.overview__button img {
  height: 1.75rem;
  padding: 0 0 0 .75rem;
}

.overview__button:first-child img {
  transform: rotate(180deg);
}

.overview__button:nth-child(2) {
  max-width: 12ch;
  color: #44baab;
  cursor: default;
  background: none;
  border: 2px solid rgba(255, 255, 255, .08);
  font-size: .75rem;
  font-weight: 700;
}

.overview__button:last-child {
  margin: 0;
}

.overview__button--disabled {
  color: rgba(255, 255, 255, .16);
  cursor: default;
}

.overview__button--disabled img {
  opacity: .16;
}

.overview__preamble {
  color: #95a8b1;
}

.overview__preamble span {
  font-weight: 700;
}

.overview__argument {
  border-radius: .5rem;
  margin: 1.25rem 0;
  padding: 1rem;
  position: relative;
}

.overview__argument--pro {
  background: rgba(68, 186, 171, .1);
}

.overview__argument--pro div {
  background: #44baab;
}

.overview__argument--con {
  background: rgba(220, 82, 89, .1);
}

.overview__argument--con div {
  background: #dc5259;
}

.overview__argument p {
  color: #fff;
  margin: 0;
}

.overview__argument div {
  width: .125rem;
  height: calc(100% - 2rem);
  border-radius: 1px;
  position: absolute;
  top: 1rem;
  left: 0;
}

.overview__legend {
  color: #95a8b1;
  grid-template-columns: 1fr 1fr 1fr;
  padding: .75rem 0;
  font-size: .75rem;
  display: grid;
}

@media screen and (min-width: 768px) {
  .overview__legend {
    font-size: 1rem;
    display: flex;
  }
}

@media screen and (min-width: 768px) {
  .overview__legend div {
    margin: 0 .5rem 0 1rem;
  }
}

.overview__legend span {
  align-items: center;
  padding: 0 .25rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .overview__legend span:first-child div {
    margin-left: 0;
  }
}

.overview__opinions {
  background: rgba(255, 255, 255, .08);
  border-radius: .5rem;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.overview__opinion {
  border-bottom: 1px solid rgba(255, 255, 255, .08);
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: .75rem 1rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .overview__opinion {
    flex-direction: row;
    align-items: center;
  }
}

.overview__opinion h2 {
  grid-template-columns: min-content 1fr;
  margin: 0;
  padding: .5rem 0;
  font-size: 1rem;
  display: grid;
}

.overview__opinion h2 span {
  color: #95a8b1;
  padding: 0 .25rem 0 0;
}

@media screen and (max-width: 767px) {
  .overview__opinion h2 {
    padding-left: 2rem;
    font-size: .875rem;
    display: block;
  }

  .overview__opinion h2 span {
    position: absolute;
    left: 1rem;
  }
}

.overview__opinion:first-child {
  z-index: 1;
  background: #3c4b52;
  border-bottom: none;
  transition: transform .5s ease-out;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.overview__opinion:first-child h2 {
  display: none;
}

@media screen and (min-width: 768px) {
  .overview__opinion:first-child {
    border-radius: .5rem .5rem 0 0;
    top: calc(4.5rem + 2px);
    box-shadow: 0 -.5rem #2c383f;
  }

  .overview__opinion:first-child h2 {
    display: block;
  }
}

.overview__opinion:last-child {
  border-bottom: none;
}

.overview__circles {
  justify-content: center;
  margin-left: 2rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .overview__circles {
    justify-content: flex-end;
  }
}

.overview__circle {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1rem;
  flex-shrink: 0;
  margin: .25rem .25rem 0;
}

@media screen and (min-width: 768px) {
  .overview__circle {
    width: 2rem;
    height: 2rem;
    margin: .5rem .5rem 0;
  }
}

.overview__circle img {
  height: 1.5rem;
  width: 1.5rem;
}

@media screen and (min-width: 768px) {
  .overview__circle img {
    height: 2rem;
    width: 2rem;
  }
}

.overview__circle--0 {
  background: #dc5259;
}

.overview__circle--1 {
  background: #e88c49;
}

.overview__circle--2 {
  background: #95a8b1;
}

.overview__circle--3 {
  background: #b7c853;
}

.overview__circle--4 {
  background: #58c263;
}

.overview__circle--0 img, .overview__circle--1 img, .overview__circle--2 img, .overview__circle--3 img, .overview__circle--4 img {
  filter: invert();
  padding: .175rem;
}

@media screen and (min-width: 768px) {
  .overview__circle--0 img, .overview__circle--1 img, .overview__circle--2 img, .overview__circle--3 img, .overview__circle--4 img {
    padding: .375rem;
  }
}

.overview__circle--small {
  width: 1rem;
  height: 1rem;
}

@media screen and (min-width: 768px) {
  .overview__circle--small {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.overview__circle--small img {
  height: 1rem;
  width: 1rem;
}

@media screen and (min-width: 768px) {
  .overview__circle--small img {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.progress {
  width: 100%;
  background: #4c5e67;
}

.progress__horizontal {
  width: 0;
  height: .25rem;
  background: #44baab;
  border-radius: .25rem;
  transition: width .25s;
}

.video {
  width: 100%;
  aspect-ratio: 16 / 9;
}

.video__overlay {
  position: relative;
}

.video__buttons {
  z-index: 10;
  transition: opacity .3s ease-in-out;
  position: absolute;
  top: .25rem;
  right: .25rem;
}

@media screen and (min-width: 480px) {
  .video__buttons {
    top: 1.75rem;
    right: 1.75rem;
  }
}

.video__button {
  min-width: 42px;
  min-height: 42px;
  cursor: pointer;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: opacity .3s ease-in-out;
  display: inline-flex;
}

.video__button:focus-visible {
  opacity: .7;
}

.video__button:focus {
  outline: none;
}

.video__button img {
  box-sizing: content-box;
  width: 14px;
  height: 14px;
  background: rgba(0, 0, 0, .5);
  border-radius: .5rem;
  padding: .5rem;
}

@media screen and (min-width: 768px) {
  .video__button:first-child {
    margin-right: 1rem;
  }

  .video__button img {
    width: unset;
    height: unset;
    padding: .5rem 1rem;
  }
}

.video--controls-hidden .video__button {
  opacity: 0;
}

@media (hover: hover) {
  .video--controls-hidden:hover .video__button {
    opacity: 1;
  }
}

.video__primary-button {
  cursor: auto;
  width: 100%;
  height: 100%;
  z-index: 1;
  color: #fff;
  background-color: rgba(0, 0, 0, .2);
  border: none;
  border-radius: .25rem;
  align-content: center;
  justify-content: center;
  padding: 0;
  display: grid;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.video__primary-button--play {
  opacity: 0;
}

.video__primary-button--pause {
  opacity: 1 !important;
}

.video__primary-button img {
  width: 80px;
  height: 80px;
  background-color: rgba(0, 0, 0, .5);
  border-radius: 100%;
  padding: .5rem;
}

.video__player {
  aspect-ratio: 16 / 9;
  width: 100%;
  background: #8a9ea7;
  border-radius: .25rem;
  display: block;
}

.video__progress {
  color: #fff;
  font-variant-numeric: tabular-nums;
  z-index: 1;
  padding: 4px;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  transition: opacity .3s ease-in-out;
  position: absolute;
  bottom: 12px;
  left: 12px;
}

.video--controls-hidden .video__progress {
  opacity: 0;
}

@media (hover: hover) {
  .video--controls-hidden:hover .video__progress {
    opacity: 1;
  }
}

.about {
  background: linear-gradient(#3c4b52 0%, #2c383f 100%);
  flex: 1;
}

.about__container {
  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
  padding: 3rem 1.5rem 1.5rem;
}

@media screen and (min-width: 768px) {
  .about__container {
    padding: 3rem 0 1.5rem;
  }
}

.about__section h1 {
  margin: 0 0 .75rem;
}

.about__section p {
  color: #95a8b1;
}

.about__section:first-child p {
  color: #fff;
  margin: 0;
}

.about__heading h2 {
  margin: 2.5rem 0 0;
  padding: 0 0 0 1rem;
  font-size: 1.5rem;
  font-weight: 400;
}

.about__heading img {
  margin: 2.375rem 0 0;
}

.about__heading {
  align-items: center;
  display: flex;
}

.about__heading img {
  filter: invert(50%) sepia(60%) saturate(400%) hue-rotate(130deg) brightness(110%) contrast();
}

.about__footer {
  border-top: 1px solid #3c4b52;
  align-items: center;
  margin: 2rem 0 0;
  padding: 1.75rem 0 .5rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .about__footer {
    padding: 2rem 0 1rem;
  }
}

.about__disclaimer {
  padding: 0 1rem 0 0;
  font-size: 1rem;
  line-height: 1.625rem;
}

.about__disclaimer img {
  height: 2.5rem;
  width: 2.5rem;
}

@media screen and (min-width: 768px) {
  .about__disclaimer img {
    height: 3.5rem;
    width: 3.5rem;
  }
}

.about__disclaimer div {
  color: #95a8b1;
  display: inline-block;
}

.about__disclaimer a {
  text-decoration: underline;
  display: block;
}

@media screen and (min-width: 768px) {
  .about__disclaimer {
    padding: 0 1.5rem 0 0;
  }
}

.button {
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
}

.button__container {
  width: 100%;
  border: 1.5px solid rgba(255, 255, 255, .08);
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  padding: .75rem;
  display: flex;
}

.button__container--fill {
  background: rgba(255, 255, 255, .08);
  border: none;
}

.button__container--tv4 {
  background: #ff3334;
  border: none;
  margin: 2rem 0 0;
}

.button__container img {
  height: 1.75rem;
  padding: 0 0 0 .75rem;
}

.button__container span {
  font-weight: 500;
}

.buttonWithoutLabel {
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}

.crucial {
  width: fit-content;
  cursor: pointer;
  white-space: nowrap;
}

.crucial__container {
  background: rgba(255, 255, 255, .08);
  border-radius: 3rem;
  justify-content: center;
  align-items: center;
  margin: 0 0 1.25rem;
  padding: .5rem 1.5rem .5rem .5rem;
  display: flex;
}

.crucial__container img {
  height: 1.75rem;
  width: 1.75rem;
  box-sizing: content-box;
  padding: 0 .75rem;
}

.crucial__container span {
  font-weight: 500;
  line-height: 1.75rem;
}

.header {
  z-index: 11;
  background: #3c4b52;
  transition: transform .5s ease-out;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  transform: translateY(0);
  box-shadow: 0 8px 24px rgba(0, 0, 0, .12);
}

@media screen and (max-width: 767px) {
  .header--hide {
    transform: translateY(-100%);
  }
}

.header__cells {
  grid-template-columns: 1fr 2fr 1fr;
  padding: 0 1.5rem;
  display: grid;
}

.header__cell {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: .75rem 0;
  display: flex;
}

.header__cell span {
  padding: 0 .625rem;
  font-weight: 500;
  display: none;
}

.header__cell:first-child {
  justify-content: flex-start;
}

.header__cell:last-child {
  justify-content: flex-end;
}

.header__cell:focus-visible, .header__cell:focus {
  outline: none;
}

@media screen and (min-width: 768px) {
  .header__cell {
    padding: 1rem 0;
  }

  .header__cell span {
    display: inline-block;
  }
}

.header__claims {
  justify-content: space-between;
  padding: .5rem 0 1rem;
  display: flex;
  position: relative;
  overflow: scroll;
}

.header__claim {
  color: #95a8b1;
  min-width: 2rem;
  min-height: 2rem;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 .75rem;
  font-size: .875rem;
  font-weight: 700;
  display: flex;
}

.header__claim:focus-visible {
  opacity: .7;
}

.header__claim:focus {
  outline: none;
}

.header__claim:focus-visible {
  outline: 2px solid;
}

@media screen and (min-width: 768px) {
  .header__claim {
    margin: 0 0 0 1rem;
  }
}

.header__claim:last-child {
  margin: 0 .75rem;
}

@media screen and (min-width: 768px) {
  .header__claim:last-child {
    margin: 0 1rem;
  }
}

.header__claim--0 {
  color: #dc5259;
  cursor: pointer;
  background: rgba(220, 82, 89, .1);
}

.header__claim--1 {
  color: #e88c49;
  cursor: pointer;
  background: rgba(232, 140, 73, .1);
}

.header__claim--2 {
  cursor: pointer;
  background: #4c5e67;
}

.header__claim--3 {
  color: #b7c853;
  cursor: pointer;
  background: rgba(183, 200, 83, .1);
}

.header__claim--4 {
  color: #58c263;
  cursor: pointer;
  background: rgba(88, 194, 99, .1);
}

.header__claim--active {
  color: #2c383f;
  background: #fff;
}

.header__indicator {
  width: 0;
  height: 0;
  border-bottom: .375rem solid #fff;
  border-left: .375rem solid rgba(0, 0, 0, 0);
  border-right: .375rem solid rgba(0, 0, 0, 0);
  position: absolute;
  bottom: 0;
}

.loaders {
  z-index: 100;
  background: #2c383f;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.loaders img {
  height: 8rem;
  animation: spin 1s linear infinite;
}

.opinion {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  margin: 1rem .375rem;
}

.opinion:focus-visible {
  opacity: .7;
}

.opinion:focus {
  outline: none;
}

@media screen and (min-width: 768px) {
  .opinion {
    flex: 1;
  }
}

.opinion__button {
  border-radius: 2rem;
  justify-content: center;
  align-items: center;
  padding: .375rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .opinion__button {
    padding: .375rem 2rem;
  }
}

@media screen and (min-width: 480px) {
  .opinion__button:hover {
    outline: 2px solid #fff;
  }
}

.opinion__button img {
  width: 1.825rem;
  height: 1.825rem;
  border: 2px solid rgba(0, 0, 0, .04);
  border-radius: 3rem;
  display: block;
}

@media screen and (min-width: 768px) {
  .opinion__button img {
    width: 2.5rem;
    height: 2.5rem;
    border: 4px solid rgba(0, 0, 0, .04);
  }
}

.opinion__button .show-checkmark {
  border-color: rgba(0, 0, 0, 0);
}

.opinion__button.bounce {
  animation: bounce .5s;
}

.opinion__button.bounce img {
  border: 2px solid rgba(0, 0, 0, 0);
}

.opinion__button--0 {
  background: #dc5259;
}

.opinion__button--1 {
  background: #e88c49;
}

.opinion__button--2 {
  background: rgba(156, 165, 168, .36);
}

@media screen and (min-width: 768px) {
  .opinion__button--2 {
    background: none;
    border: 2px solid #3c4b52;
  }

  .opinion__button--2 img {
    border: 4px solid #3c4b52;
  }
}

.opinion__button--3 {
  background: #b7c853;
}

.opinion__button--4 {
  background: #58c263;
}

.overview {
  padding: 0 1.5rem 2.5rem;
}

@media screen and (min-width: 768px) {
  .overview {
    padding: 2.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .overview {
    padding: 5rem;
  }
}

.overview__links {
  padding: 0 0 .75rem;
}

.overview__link {
  cursor: pointer;
  margin: 0 1.5rem 0 0;
  padding: .25rem 0;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.375rem;
}

.overview__link--active {
  border-bottom: 2px solid #44baab;
}

.overview__question {
  max-width: 40rem;
  margin: 0 auto;
  overflow: visible;
}

.overview__slider {
  flex-flow: row;
  display: flex;
}

.overview__slide {
  width: 100%;
  flex-shrink: 0;
}

.overview__slide.animate {
  animation: fade 1.5s;
}

.overview__table {
  min-height: 280px;
  background-image: linear-gradient(rgba(0, 0, 0, 0) 0%, #2c383f 100%), linear-gradient(rgba(255, 255, 255, .08) 0%, rgba(255, 255, 255, 0) 100%), repeating-linear-gradient(90deg, #2c383f 0%, #2c383f 10%, rgba(255, 255, 255, .32) calc(10% + 1px), #2c383f 10%, #2c383f 20%);
  background-repeat: repeat;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: min-content;
  grid-auto-flow: row dense;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  padding-bottom: 20px;
  display: grid;
  position: relative;
}

.overview__table-token {
  text-align: center;
  width: 32px;
  height: 32px;
  margin: 12px auto 0;
}

.overview__table-token--0 {
  grid-column: 1;
}

.overview__table-token--1 {
  grid-column: 2;
}

.overview__table-token--2 {
  grid-column: 3;
}

.overview__table-token--3 {
  grid-column: 4;
}

.overview__table-token--4 {
  grid-column: 5;
}

.overview__table-heading {
  text-align: center;
  background-color: #2c383f;
  margin: 0;
  padding-bottom: 12px;
  font-size: 12px;
}

.overview__table-heading:nth-child(1) {
  color: #ef92aa;
}

.overview__table-heading:nth-child(2) {
  color: #f39f95;
}

.overview__table-heading:nth-child(3) {
  color: #b4bcbe;
}

.overview__table-heading:nth-child(4) {
  color: #d8e0a2;
}

.overview__table-heading:nth-child(5) {
  color: #9ede97;
}

@media screen and (max-width: 767px) {
  .overview__table-heading:nth-child(1) {
    text-align: left;
    grid-column: span 2;
    padding-left: 12px;
  }

  .overview__table-heading:nth-child(5) {
    text-align: right;
    grid-column: span 2;
    padding-right: 12px;
  }

  .overview__table-heading:nth-child(2), .overview__table-heading:nth-child(4) {
    display: none;
  }
}

.overview__line {
  height: 4px;
  width: 100%;
  background: linear-gradient(90deg, #da3a55 10.23%, #e8834a 29.87%, #fff 50.31%, #c2cf57 69.79%, #4ec869 90.26%);
  border: none;
  grid-column: 1 / -1;
  margin: 0;
}

.overview__heading {
  padding: .75rem 0;
}

.overview__heading span {
  color: #44baab;
}

.overview__buttons {
  display: flex;
}

.overview__button {
  width: 100%;
  cursor: pointer;
  background: rgba(255, 255, 255, .08);
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  margin: 0 1rem 0 0;
  padding: .75rem;
  font-weight: 500;
  display: flex;
}

.overview__button img {
  height: 1.75rem;
  padding: 0 0 0 .75rem;
}

.overview__button:first-child img {
  transform: rotate(180deg);
}

.overview__button:nth-child(2) {
  max-width: 12ch;
  color: #44baab;
  cursor: default;
  background: none;
  border: 2px solid rgba(255, 255, 255, .08);
  font-size: .75rem;
  font-weight: 700;
}

.overview__button:last-child {
  margin: 0;
}

.overview__button--disabled {
  color: rgba(255, 255, 255, .16);
  cursor: default;
}

.overview__button--disabled img {
  opacity: .16;
}

.overview__preamble {
  color: #95a8b1;
}

.overview__preamble span {
  font-weight: 700;
}

.overview__argument {
  border-radius: .5rem;
  margin: 1.25rem 0;
  padding: 1rem;
  position: relative;
}

.overview__argument--pro {
  background: rgba(68, 186, 171, .1);
}

.overview__argument--pro div {
  background: #44baab;
}

.overview__argument--con {
  background: rgba(220, 82, 89, .1);
}

.overview__argument--con div {
  background: #dc5259;
}

.overview__argument p {
  color: #fff;
  margin: 0;
}

.overview__argument div {
  width: .125rem;
  height: calc(100% - 2rem);
  border-radius: 1px;
  position: absolute;
  top: 1rem;
  left: 0;
}

.overview__legend {
  color: #95a8b1;
  grid-template-columns: 1fr 1fr 1fr;
  padding: .75rem 0;
  font-size: .75rem;
  display: grid;
}

@media screen and (min-width: 768px) {
  .overview__legend {
    font-size: 1rem;
    display: flex;
  }
}

@media screen and (min-width: 768px) {
  .overview__legend div {
    margin: 0 .5rem 0 1rem;
  }
}

.overview__legend span {
  align-items: center;
  padding: 0 .25rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .overview__legend span:first-child div {
    margin-left: 0;
  }
}

.overview__opinions {
  background: rgba(255, 255, 255, .08);
  border-radius: .5rem;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.overview__opinion {
  border-bottom: 1px solid rgba(255, 255, 255, .08);
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: .75rem 1rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .overview__opinion {
    flex-direction: row;
    align-items: center;
  }
}

.overview__opinion h2 {
  grid-template-columns: min-content 1fr;
  margin: 0;
  padding: .5rem 0;
  font-size: 1rem;
  display: grid;
}

.overview__opinion h2 span {
  color: #95a8b1;
  padding: 0 .25rem 0 0;
}

@media screen and (max-width: 767px) {
  .overview__opinion h2 {
    padding-left: 2rem;
    font-size: .875rem;
    display: block;
  }

  .overview__opinion h2 span {
    position: absolute;
    left: 1rem;
  }
}

.overview__opinion:first-child {
  z-index: 1;
  background: #3c4b52;
  border-bottom: none;
  transition: transform .5s ease-out;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.overview__opinion:first-child h2 {
  display: none;
}

@media screen and (min-width: 768px) {
  .overview__opinion:first-child {
    border-radius: .5rem .5rem 0 0;
    top: calc(4.5rem + 2px);
    box-shadow: 0 -.5rem #2c383f;
  }

  .overview__opinion:first-child h2 {
    display: block;
  }
}

.overview__opinion:last-child {
  border-bottom: none;
}

.overview__circles {
  justify-content: center;
  margin-left: 2rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .overview__circles {
    justify-content: flex-end;
  }
}

.overview__circle {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1rem;
  flex-shrink: 0;
  margin: .25rem .25rem 0;
}

@media screen and (min-width: 768px) {
  .overview__circle {
    width: 2rem;
    height: 2rem;
    margin: .5rem .5rem 0;
  }
}

.overview__circle img {
  height: 1.5rem;
  width: 1.5rem;
}

@media screen and (min-width: 768px) {
  .overview__circle img {
    height: 2rem;
    width: 2rem;
  }
}

.overview__circle--0 {
  background: #dc5259;
}

.overview__circle--1 {
  background: #e88c49;
}

.overview__circle--2 {
  background: #95a8b1;
}

.overview__circle--3 {
  background: #b7c853;
}

.overview__circle--4 {
  background: #58c263;
}

.overview__circle--0 img, .overview__circle--1 img, .overview__circle--2 img, .overview__circle--3 img, .overview__circle--4 img {
  filter: invert();
  padding: .175rem;
}

@media screen and (min-width: 768px) {
  .overview__circle--0 img, .overview__circle--1 img, .overview__circle--2 img, .overview__circle--3 img, .overview__circle--4 img {
    padding: .375rem;
  }
}

.overview__circle--small {
  width: 1rem;
  height: 1rem;
}

@media screen and (min-width: 768px) {
  .overview__circle--small {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.overview__circle--small img {
  height: 1rem;
  width: 1rem;
}

@media screen and (min-width: 768px) {
  .overview__circle--small img {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.progress {
  width: 100%;
  background: #4c5e67;
}

.progress__horizontal {
  width: 0;
  height: .25rem;
  background: #44baab;
  border-radius: .25rem;
  transition: width .25s;
}

.video {
  width: 100%;
  aspect-ratio: 16 / 9;
}

.video__overlay {
  position: relative;
}

.video__buttons {
  z-index: 10;
  transition: opacity .3s ease-in-out;
  position: absolute;
  top: .25rem;
  right: .25rem;
}

@media screen and (min-width: 480px) {
  .video__buttons {
    top: 1.75rem;
    right: 1.75rem;
  }
}

.video__button {
  min-width: 42px;
  min-height: 42px;
  cursor: pointer;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: opacity .3s ease-in-out;
  display: inline-flex;
}

.video__button:focus-visible {
  opacity: .7;
}

.video__button:focus {
  outline: none;
}

.video__button img {
  box-sizing: content-box;
  width: 14px;
  height: 14px;
  background: rgba(0, 0, 0, .5);
  border-radius: .5rem;
  padding: .5rem;
}

@media screen and (min-width: 768px) {
  .video__button:first-child {
    margin-right: 1rem;
  }

  .video__button img {
    width: unset;
    height: unset;
    padding: .5rem 1rem;
  }
}

.video--controls-hidden .video__button {
  opacity: 0;
}

@media (hover: hover) {
  .video--controls-hidden:hover .video__button {
    opacity: 1;
  }
}

.video__primary-button {
  cursor: auto;
  width: 100%;
  height: 100%;
  z-index: 1;
  color: #fff;
  background-color: rgba(0, 0, 0, .2);
  border: none;
  border-radius: .25rem;
  align-content: center;
  justify-content: center;
  padding: 0;
  display: grid;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.video__primary-button--play {
  opacity: 0;
}

.video__primary-button--pause {
  opacity: 1 !important;
}

.video__primary-button img {
  width: 80px;
  height: 80px;
  background-color: rgba(0, 0, 0, .5);
  border-radius: 100%;
  padding: .5rem;
}

.video__player {
  aspect-ratio: 16 / 9;
  width: 100%;
  background: #8a9ea7;
  border-radius: .25rem;
  display: block;
}

.video__progress {
  color: #fff;
  font-variant-numeric: tabular-nums;
  z-index: 1;
  padding: 4px;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  transition: opacity .3s ease-in-out;
  position: absolute;
  bottom: 12px;
  left: 12px;
}

.video--controls-hidden .video__progress {
  opacity: 0;
}

@media (hover: hover) {
  .video--controls-hidden:hover .video__progress {
    opacity: 1;
  }
}

.about {
  background: linear-gradient(#3c4b52 0%, #2c383f 100%);
  flex: 1;
}

.about__container {
  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
  padding: 3rem 1.5rem 1.5rem;
}

@media screen and (min-width: 768px) {
  .about__container {
    padding: 3rem 0 1.5rem;
  }
}

.about__section h1 {
  margin: 0 0 .75rem;
}

.about__section p {
  color: #95a8b1;
}

.about__section:first-child p {
  color: #fff;
  margin: 0;
}

.about__heading h2 {
  margin: 2.5rem 0 0;
  padding: 0 0 0 1rem;
  font-size: 1.5rem;
  font-weight: 400;
}

.about__heading img {
  margin: 2.375rem 0 0;
}

.about__heading {
  align-items: center;
  display: flex;
}

.about__heading img {
  filter: invert(50%) sepia(60%) saturate(400%) hue-rotate(130deg) brightness(110%) contrast();
}

.about__footer {
  border-top: 1px solid #3c4b52;
  align-items: center;
  margin: 2rem 0 0;
  padding: 1.75rem 0 .5rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .about__footer {
    padding: 2rem 0 1rem;
  }
}

.about__disclaimer {
  padding: 0 1rem 0 0;
  font-size: 1rem;
  line-height: 1.625rem;
}

.about__disclaimer img {
  height: 2.5rem;
  width: 2.5rem;
}

@media screen and (min-width: 768px) {
  .about__disclaimer img {
    height: 3.5rem;
    width: 3.5rem;
  }
}

.about__disclaimer div {
  color: #95a8b1;
  display: inline-block;
}

.about__disclaimer a {
  text-decoration: underline;
  display: block;
}

@media screen and (min-width: 768px) {
  .about__disclaimer {
    padding: 0 1.5rem 0 0;
  }
}

.button {
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
}

.button__container {
  width: 100%;
  border: 1.5px solid rgba(255, 255, 255, .08);
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  padding: .75rem;
  display: flex;
}

.button__container--fill {
  background: rgba(255, 255, 255, .08);
  border: none;
}

.button__container--tv4 {
  background: #ff3334;
  border: none;
  margin: 2rem 0 0;
}

.button__container img {
  height: 1.75rem;
  padding: 0 0 0 .75rem;
}

.button__container span {
  font-weight: 500;
}

.buttonWithoutLabel {
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}

.crucial {
  width: fit-content;
  cursor: pointer;
  white-space: nowrap;
}

.crucial__container {
  background: rgba(255, 255, 255, .08);
  border-radius: 3rem;
  justify-content: center;
  align-items: center;
  margin: 0 0 1.25rem;
  padding: .5rem 1.5rem .5rem .5rem;
  display: flex;
}

.crucial__container img {
  height: 1.75rem;
  width: 1.75rem;
  box-sizing: content-box;
  padding: 0 .75rem;
}

.crucial__container span {
  font-weight: 500;
  line-height: 1.75rem;
}

.header {
  z-index: 11;
  background: #3c4b52;
  transition: transform .5s ease-out;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  transform: translateY(0);
  box-shadow: 0 8px 24px rgba(0, 0, 0, .12);
}

@media screen and (max-width: 767px) {
  .header--hide {
    transform: translateY(-100%);
  }
}

.header__cells {
  grid-template-columns: 1fr 2fr 1fr;
  padding: 0 1.5rem;
  display: grid;
}

.header__cell {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: .75rem 0;
  display: flex;
}

.header__cell span {
  padding: 0 .625rem;
  font-weight: 500;
  display: none;
}

.header__cell:first-child {
  justify-content: flex-start;
}

.header__cell:last-child {
  justify-content: flex-end;
}

.header__cell:focus-visible, .header__cell:focus {
  outline: none;
}

@media screen and (min-width: 768px) {
  .header__cell {
    padding: 1rem 0;
  }

  .header__cell span {
    display: inline-block;
  }
}

.header__claims {
  justify-content: space-between;
  padding: .5rem 0 1rem;
  display: flex;
  position: relative;
  overflow: scroll;
}

.header__claim {
  color: #95a8b1;
  min-width: 2rem;
  min-height: 2rem;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 .75rem;
  font-size: .875rem;
  font-weight: 700;
  display: flex;
}

.header__claim:focus-visible {
  opacity: .7;
}

.header__claim:focus {
  outline: none;
}

.header__claim:focus-visible {
  outline: 2px solid;
}

@media screen and (min-width: 768px) {
  .header__claim {
    margin: 0 0 0 1rem;
  }
}

.header__claim:last-child {
  margin: 0 .75rem;
}

@media screen and (min-width: 768px) {
  .header__claim:last-child {
    margin: 0 1rem;
  }
}

.header__claim--0 {
  color: #dc5259;
  cursor: pointer;
  background: rgba(220, 82, 89, .1);
}

.header__claim--1 {
  color: #e88c49;
  cursor: pointer;
  background: rgba(232, 140, 73, .1);
}

.header__claim--2 {
  cursor: pointer;
  background: #4c5e67;
}

.header__claim--3 {
  color: #b7c853;
  cursor: pointer;
  background: rgba(183, 200, 83, .1);
}

.header__claim--4 {
  color: #58c263;
  cursor: pointer;
  background: rgba(88, 194, 99, .1);
}

.header__claim--active {
  color: #2c383f;
  background: #fff;
}

.header__indicator {
  width: 0;
  height: 0;
  border-bottom: .375rem solid #fff;
  border-left: .375rem solid rgba(0, 0, 0, 0);
  border-right: .375rem solid rgba(0, 0, 0, 0);
  position: absolute;
  bottom: 0;
}

.loaders {
  z-index: 100;
  background: #2c383f;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.loaders img {
  height: 8rem;
  animation: spin 1s linear infinite;
}

.opinion {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  margin: 1rem .375rem;
}

.opinion:focus-visible {
  opacity: .7;
}

.opinion:focus {
  outline: none;
}

@media screen and (min-width: 768px) {
  .opinion {
    flex: 1;
  }
}

.opinion__button {
  border-radius: 2rem;
  justify-content: center;
  align-items: center;
  padding: .375rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .opinion__button {
    padding: .375rem 2rem;
  }
}

@media screen and (min-width: 480px) {
  .opinion__button:hover {
    outline: 2px solid #fff;
  }
}

.opinion__button img {
  width: 1.825rem;
  height: 1.825rem;
  border: 2px solid rgba(0, 0, 0, .04);
  border-radius: 3rem;
  display: block;
}

@media screen and (min-width: 768px) {
  .opinion__button img {
    width: 2.5rem;
    height: 2.5rem;
    border: 4px solid rgba(0, 0, 0, .04);
  }
}

.opinion__button .show-checkmark {
  border-color: rgba(0, 0, 0, 0);
}

.opinion__button.bounce {
  animation: bounce .5s;
}

.opinion__button.bounce img {
  border: 2px solid rgba(0, 0, 0, 0);
}

.opinion__button--0 {
  background: #dc5259;
}

.opinion__button--1 {
  background: #e88c49;
}

.opinion__button--2 {
  background: rgba(156, 165, 168, .36);
}

@media screen and (min-width: 768px) {
  .opinion__button--2 {
    background: none;
    border: 2px solid #3c4b52;
  }

  .opinion__button--2 img {
    border: 4px solid #3c4b52;
  }
}

.opinion__button--3 {
  background: #b7c853;
}

.opinion__button--4 {
  background: #58c263;
}

.overview {
  padding: 0 1.5rem 2.5rem;
}

@media screen and (min-width: 768px) {
  .overview {
    padding: 2.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .overview {
    padding: 5rem;
  }
}

.overview__links {
  padding: 0 0 .75rem;
}

.overview__link {
  cursor: pointer;
  margin: 0 1.5rem 0 0;
  padding: .25rem 0;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.375rem;
}

.overview__link--active {
  border-bottom: 2px solid #44baab;
}

.overview__question {
  max-width: 40rem;
  margin: 0 auto;
  overflow: visible;
}

.overview__slider {
  flex-flow: row;
  display: flex;
}

.overview__slide {
  width: 100%;
  flex-shrink: 0;
}

.overview__slide.animate {
  animation: fade 1.5s;
}

.overview__table {
  min-height: 280px;
  background-image: linear-gradient(rgba(0, 0, 0, 0) 0%, #2c383f 100%), linear-gradient(rgba(255, 255, 255, .08) 0%, rgba(255, 255, 255, 0) 100%), repeating-linear-gradient(90deg, #2c383f 0%, #2c383f 10%, rgba(255, 255, 255, .32) calc(10% + 1px), #2c383f 10%, #2c383f 20%);
  background-repeat: repeat;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: min-content;
  grid-auto-flow: row dense;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  padding-bottom: 20px;
  display: grid;
  position: relative;
}

.overview__table-token {
  text-align: center;
  width: 32px;
  height: 32px;
  margin: 12px auto 0;
}

.overview__table-token--0 {
  grid-column: 1;
}

.overview__table-token--1 {
  grid-column: 2;
}

.overview__table-token--2 {
  grid-column: 3;
}

.overview__table-token--3 {
  grid-column: 4;
}

.overview__table-token--4 {
  grid-column: 5;
}

.overview__table-heading {
  text-align: center;
  background-color: #2c383f;
  margin: 0;
  padding-bottom: 12px;
  font-size: 12px;
}

.overview__table-heading:nth-child(1) {
  color: #ef92aa;
}

.overview__table-heading:nth-child(2) {
  color: #f39f95;
}

.overview__table-heading:nth-child(3) {
  color: #b4bcbe;
}

.overview__table-heading:nth-child(4) {
  color: #d8e0a2;
}

.overview__table-heading:nth-child(5) {
  color: #9ede97;
}

@media screen and (max-width: 767px) {
  .overview__table-heading:nth-child(1) {
    text-align: left;
    grid-column: span 2;
    padding-left: 12px;
  }

  .overview__table-heading:nth-child(5) {
    text-align: right;
    grid-column: span 2;
    padding-right: 12px;
  }

  .overview__table-heading:nth-child(2), .overview__table-heading:nth-child(4) {
    display: none;
  }
}

.overview__line {
  height: 4px;
  width: 100%;
  background: linear-gradient(90deg, #da3a55 10.23%, #e8834a 29.87%, #fff 50.31%, #c2cf57 69.79%, #4ec869 90.26%);
  border: none;
  grid-column: 1 / -1;
  margin: 0;
}

.overview__heading {
  padding: .75rem 0;
}

.overview__heading span {
  color: #44baab;
}

.overview__buttons {
  display: flex;
}

.overview__button {
  width: 100%;
  cursor: pointer;
  background: rgba(255, 255, 255, .08);
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  margin: 0 1rem 0 0;
  padding: .75rem;
  font-weight: 500;
  display: flex;
}

.overview__button img {
  height: 1.75rem;
  padding: 0 0 0 .75rem;
}

.overview__button:first-child img {
  transform: rotate(180deg);
}

.overview__button:nth-child(2) {
  max-width: 12ch;
  color: #44baab;
  cursor: default;
  background: none;
  border: 2px solid rgba(255, 255, 255, .08);
  font-size: .75rem;
  font-weight: 700;
}

.overview__button:last-child {
  margin: 0;
}

.overview__button--disabled {
  color: rgba(255, 255, 255, .16);
  cursor: default;
}

.overview__button--disabled img {
  opacity: .16;
}

.overview__preamble {
  color: #95a8b1;
}

.overview__preamble span {
  font-weight: 700;
}

.overview__argument {
  border-radius: .5rem;
  margin: 1.25rem 0;
  padding: 1rem;
  position: relative;
}

.overview__argument--pro {
  background: rgba(68, 186, 171, .1);
}

.overview__argument--pro div {
  background: #44baab;
}

.overview__argument--con {
  background: rgba(220, 82, 89, .1);
}

.overview__argument--con div {
  background: #dc5259;
}

.overview__argument p {
  color: #fff;
  margin: 0;
}

.overview__argument div {
  width: .125rem;
  height: calc(100% - 2rem);
  border-radius: 1px;
  position: absolute;
  top: 1rem;
  left: 0;
}

.overview__legend {
  color: #95a8b1;
  grid-template-columns: 1fr 1fr 1fr;
  padding: .75rem 0;
  font-size: .75rem;
  display: grid;
}

@media screen and (min-width: 768px) {
  .overview__legend {
    font-size: 1rem;
    display: flex;
  }
}

@media screen and (min-width: 768px) {
  .overview__legend div {
    margin: 0 .5rem 0 1rem;
  }
}

.overview__legend span {
  align-items: center;
  padding: 0 .25rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .overview__legend span:first-child div {
    margin-left: 0;
  }
}

.overview__opinions {
  background: rgba(255, 255, 255, .08);
  border-radius: .5rem;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.overview__opinion {
  border-bottom: 1px solid rgba(255, 255, 255, .08);
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: .75rem 1rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .overview__opinion {
    flex-direction: row;
    align-items: center;
  }
}

.overview__opinion h2 {
  grid-template-columns: min-content 1fr;
  margin: 0;
  padding: .5rem 0;
  font-size: 1rem;
  display: grid;
}

.overview__opinion h2 span {
  color: #95a8b1;
  padding: 0 .25rem 0 0;
}

@media screen and (max-width: 767px) {
  .overview__opinion h2 {
    padding-left: 2rem;
    font-size: .875rem;
    display: block;
  }

  .overview__opinion h2 span {
    position: absolute;
    left: 1rem;
  }
}

.overview__opinion:first-child {
  z-index: 1;
  background: #3c4b52;
  border-bottom: none;
  transition: transform .5s ease-out;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.overview__opinion:first-child h2 {
  display: none;
}

@media screen and (min-width: 768px) {
  .overview__opinion:first-child {
    border-radius: .5rem .5rem 0 0;
    top: calc(4.5rem + 2px);
    box-shadow: 0 -.5rem #2c383f;
  }

  .overview__opinion:first-child h2 {
    display: block;
  }
}

.overview__opinion:last-child {
  border-bottom: none;
}

.overview__circles {
  justify-content: center;
  margin-left: 2rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .overview__circles {
    justify-content: flex-end;
  }
}

.overview__circle {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1rem;
  flex-shrink: 0;
  margin: .25rem .25rem 0;
}

@media screen and (min-width: 768px) {
  .overview__circle {
    width: 2rem;
    height: 2rem;
    margin: .5rem .5rem 0;
  }
}

.overview__circle img {
  height: 1.5rem;
  width: 1.5rem;
}

@media screen and (min-width: 768px) {
  .overview__circle img {
    height: 2rem;
    width: 2rem;
  }
}

.overview__circle--0 {
  background: #dc5259;
}

.overview__circle--1 {
  background: #e88c49;
}

.overview__circle--2 {
  background: #95a8b1;
}

.overview__circle--3 {
  background: #b7c853;
}

.overview__circle--4 {
  background: #58c263;
}

.overview__circle--0 img, .overview__circle--1 img, .overview__circle--2 img, .overview__circle--3 img, .overview__circle--4 img {
  filter: invert();
  padding: .175rem;
}

@media screen and (min-width: 768px) {
  .overview__circle--0 img, .overview__circle--1 img, .overview__circle--2 img, .overview__circle--3 img, .overview__circle--4 img {
    padding: .375rem;
  }
}

.overview__circle--small {
  width: 1rem;
  height: 1rem;
}

@media screen and (min-width: 768px) {
  .overview__circle--small {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.overview__circle--small img {
  height: 1rem;
  width: 1rem;
}

@media screen and (min-width: 768px) {
  .overview__circle--small img {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.progress {
  width: 100%;
  background: #4c5e67;
}

.progress__horizontal {
  width: 0;
  height: .25rem;
  background: #44baab;
  border-radius: .25rem;
  transition: width .25s;
}

.video {
  width: 100%;
  aspect-ratio: 16 / 9;
}

.video__overlay {
  position: relative;
}

.video__buttons {
  z-index: 10;
  transition: opacity .3s ease-in-out;
  position: absolute;
  top: .25rem;
  right: .25rem;
}

@media screen and (min-width: 480px) {
  .video__buttons {
    top: 1.75rem;
    right: 1.75rem;
  }
}

.video__button {
  min-width: 42px;
  min-height: 42px;
  cursor: pointer;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: opacity .3s ease-in-out;
  display: inline-flex;
}

.video__button:focus-visible {
  opacity: .7;
}

.video__button:focus {
  outline: none;
}

.video__button img {
  box-sizing: content-box;
  width: 14px;
  height: 14px;
  background: rgba(0, 0, 0, .5);
  border-radius: .5rem;
  padding: .5rem;
}

@media screen and (min-width: 768px) {
  .video__button:first-child {
    margin-right: 1rem;
  }

  .video__button img {
    width: unset;
    height: unset;
    padding: .5rem 1rem;
  }
}

.video--controls-hidden .video__button {
  opacity: 0;
}

@media (hover: hover) {
  .video--controls-hidden:hover .video__button {
    opacity: 1;
  }
}

.video__primary-button {
  cursor: auto;
  width: 100%;
  height: 100%;
  z-index: 1;
  color: #fff;
  background-color: rgba(0, 0, 0, .2);
  border: none;
  border-radius: .25rem;
  align-content: center;
  justify-content: center;
  padding: 0;
  display: grid;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.video__primary-button--play {
  opacity: 0;
}

.video__primary-button--pause {
  opacity: 1 !important;
}

.video__primary-button img {
  width: 80px;
  height: 80px;
  background-color: rgba(0, 0, 0, .5);
  border-radius: 100%;
  padding: .5rem;
}

.video__player {
  aspect-ratio: 16 / 9;
  width: 100%;
  background: #8a9ea7;
  border-radius: .25rem;
  display: block;
}

.video__progress {
  color: #fff;
  font-variant-numeric: tabular-nums;
  z-index: 1;
  padding: 4px;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  transition: opacity .3s ease-in-out;
  position: absolute;
  bottom: 12px;
  left: 12px;
}

.video--controls-hidden .video__progress {
  opacity: 0;
}

@media (hover: hover) {
  .video--controls-hidden:hover .video__progress {
    opacity: 1;
  }
}

.about {
  background: linear-gradient(#3c4b52 0%, #2c383f 100%);
  flex: 1;
}

.about__container {
  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
  padding: 3rem 1.5rem 1.5rem;
}

@media screen and (min-width: 768px) {
  .about__container {
    padding: 3rem 0 1.5rem;
  }
}

.about__section h1 {
  margin: 0 0 .75rem;
}

.about__section p {
  color: #95a8b1;
}

.about__section:first-child p {
  color: #fff;
  margin: 0;
}

.about__heading h2 {
  margin: 2.5rem 0 0;
  padding: 0 0 0 1rem;
  font-size: 1.5rem;
  font-weight: 400;
}

.about__heading img {
  margin: 2.375rem 0 0;
}

.about__heading {
  align-items: center;
  display: flex;
}

.about__heading img {
  filter: invert(50%) sepia(60%) saturate(400%) hue-rotate(130deg) brightness(110%) contrast();
}

.about__footer {
  border-top: 1px solid #3c4b52;
  align-items: center;
  margin: 2rem 0 0;
  padding: 1.75rem 0 .5rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .about__footer {
    padding: 2rem 0 1rem;
  }
}

.about__disclaimer {
  padding: 0 1rem 0 0;
  font-size: 1rem;
  line-height: 1.625rem;
}

.about__disclaimer img {
  height: 2.5rem;
  width: 2.5rem;
}

@media screen and (min-width: 768px) {
  .about__disclaimer img {
    height: 3.5rem;
    width: 3.5rem;
  }
}

.about__disclaimer div {
  color: #95a8b1;
  display: inline-block;
}

.about__disclaimer a {
  text-decoration: underline;
  display: block;
}

@media screen and (min-width: 768px) {
  .about__disclaimer {
    padding: 0 1.5rem 0 0;
  }
}

.button {
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
}

.button__container {
  width: 100%;
  border: 1.5px solid rgba(255, 255, 255, .08);
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  padding: .75rem;
  display: flex;
}

.button__container--fill {
  background: rgba(255, 255, 255, .08);
  border: none;
}

.button__container--tv4 {
  background: #ff3334;
  border: none;
  margin: 2rem 0 0;
}

.button__container img {
  height: 1.75rem;
  padding: 0 0 0 .75rem;
}

.button__container span {
  font-weight: 500;
}

.buttonWithoutLabel {
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}

.crucial {
  width: fit-content;
  cursor: pointer;
  white-space: nowrap;
}

.crucial__container {
  background: rgba(255, 255, 255, .08);
  border-radius: 3rem;
  justify-content: center;
  align-items: center;
  margin: 0 0 1.25rem;
  padding: .5rem 1.5rem .5rem .5rem;
  display: flex;
}

.crucial__container img {
  height: 1.75rem;
  width: 1.75rem;
  box-sizing: content-box;
  padding: 0 .75rem;
}

.crucial__container span {
  font-weight: 500;
  line-height: 1.75rem;
}

.header {
  z-index: 11;
  background: #3c4b52;
  transition: transform .5s ease-out;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  transform: translateY(0);
  box-shadow: 0 8px 24px rgba(0, 0, 0, .12);
}

@media screen and (max-width: 767px) {
  .header--hide {
    transform: translateY(-100%);
  }
}

.header__cells {
  grid-template-columns: 1fr 2fr 1fr;
  padding: 0 1.5rem;
  display: grid;
}

.header__cell {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: .75rem 0;
  display: flex;
}

.header__cell span {
  padding: 0 .625rem;
  font-weight: 500;
  display: none;
}

.header__cell:first-child {
  justify-content: flex-start;
}

.header__cell:last-child {
  justify-content: flex-end;
}

.header__cell:focus-visible, .header__cell:focus {
  outline: none;
}

@media screen and (min-width: 768px) {
  .header__cell {
    padding: 1rem 0;
  }

  .header__cell span {
    display: inline-block;
  }
}

.header__claims {
  justify-content: space-between;
  padding: .5rem 0 1rem;
  display: flex;
  position: relative;
  overflow: scroll;
}

.header__claim {
  color: #95a8b1;
  min-width: 2rem;
  min-height: 2rem;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 .75rem;
  font-size: .875rem;
  font-weight: 700;
  display: flex;
}

.header__claim:focus-visible {
  opacity: .7;
}

.header__claim:focus {
  outline: none;
}

.header__claim:focus-visible {
  outline: 2px solid;
}

@media screen and (min-width: 768px) {
  .header__claim {
    margin: 0 0 0 1rem;
  }
}

.header__claim:last-child {
  margin: 0 .75rem;
}

@media screen and (min-width: 768px) {
  .header__claim:last-child {
    margin: 0 1rem;
  }
}

.header__claim--0 {
  color: #dc5259;
  cursor: pointer;
  background: rgba(220, 82, 89, .1);
}

.header__claim--1 {
  color: #e88c49;
  cursor: pointer;
  background: rgba(232, 140, 73, .1);
}

.header__claim--2 {
  cursor: pointer;
  background: #4c5e67;
}

.header__claim--3 {
  color: #b7c853;
  cursor: pointer;
  background: rgba(183, 200, 83, .1);
}

.header__claim--4 {
  color: #58c263;
  cursor: pointer;
  background: rgba(88, 194, 99, .1);
}

.header__claim--active {
  color: #2c383f;
  background: #fff;
}

.header__indicator {
  width: 0;
  height: 0;
  border-bottom: .375rem solid #fff;
  border-left: .375rem solid rgba(0, 0, 0, 0);
  border-right: .375rem solid rgba(0, 0, 0, 0);
  position: absolute;
  bottom: 0;
}

.loaders {
  z-index: 100;
  background: #2c383f;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.loaders img {
  height: 8rem;
  animation: spin 1s linear infinite;
}

.opinion {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  margin: 1rem .375rem;
}

.opinion:focus-visible {
  opacity: .7;
}

.opinion:focus {
  outline: none;
}

@media screen and (min-width: 768px) {
  .opinion {
    flex: 1;
  }
}

.opinion__button {
  border-radius: 2rem;
  justify-content: center;
  align-items: center;
  padding: .375rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .opinion__button {
    padding: .375rem 2rem;
  }
}

@media screen and (min-width: 480px) {
  .opinion__button:hover {
    outline: 2px solid #fff;
  }
}

.opinion__button img {
  width: 1.825rem;
  height: 1.825rem;
  border: 2px solid rgba(0, 0, 0, .04);
  border-radius: 3rem;
  display: block;
}

@media screen and (min-width: 768px) {
  .opinion__button img {
    width: 2.5rem;
    height: 2.5rem;
    border: 4px solid rgba(0, 0, 0, .04);
  }
}

.opinion__button .show-checkmark {
  border-color: rgba(0, 0, 0, 0);
}

.opinion__button.bounce {
  animation: bounce .5s;
}

.opinion__button.bounce img {
  border: 2px solid rgba(0, 0, 0, 0);
}

.opinion__button--0 {
  background: #dc5259;
}

.opinion__button--1 {
  background: #e88c49;
}

.opinion__button--2 {
  background: rgba(156, 165, 168, .36);
}

@media screen and (min-width: 768px) {
  .opinion__button--2 {
    background: none;
    border: 2px solid #3c4b52;
  }

  .opinion__button--2 img {
    border: 4px solid #3c4b52;
  }
}

.opinion__button--3 {
  background: #b7c853;
}

.opinion__button--4 {
  background: #58c263;
}

.overview {
  padding: 0 1.5rem 2.5rem;
}

@media screen and (min-width: 768px) {
  .overview {
    padding: 2.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .overview {
    padding: 5rem;
  }
}

.overview__links {
  padding: 0 0 .75rem;
}

.overview__link {
  cursor: pointer;
  margin: 0 1.5rem 0 0;
  padding: .25rem 0;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.375rem;
}

.overview__link--active {
  border-bottom: 2px solid #44baab;
}

.overview__question {
  max-width: 40rem;
  margin: 0 auto;
  overflow: visible;
}

.overview__slider {
  flex-flow: row;
  display: flex;
}

.overview__slide {
  width: 100%;
  flex-shrink: 0;
}

.overview__slide.animate {
  animation: fade 1.5s;
}

.overview__table {
  min-height: 280px;
  background-image: linear-gradient(rgba(0, 0, 0, 0) 0%, #2c383f 100%), linear-gradient(rgba(255, 255, 255, .08) 0%, rgba(255, 255, 255, 0) 100%), repeating-linear-gradient(90deg, #2c383f 0%, #2c383f 10%, rgba(255, 255, 255, .32) calc(10% + 1px), #2c383f 10%, #2c383f 20%);
  background-repeat: repeat;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: min-content;
  grid-auto-flow: row dense;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  padding-bottom: 20px;
  display: grid;
  position: relative;
}

.overview__table-token {
  text-align: center;
  width: 32px;
  height: 32px;
  margin: 12px auto 0;
}

.overview__table-token--0 {
  grid-column: 1;
}

.overview__table-token--1 {
  grid-column: 2;
}

.overview__table-token--2 {
  grid-column: 3;
}

.overview__table-token--3 {
  grid-column: 4;
}

.overview__table-token--4 {
  grid-column: 5;
}

.overview__table-heading {
  text-align: center;
  background-color: #2c383f;
  margin: 0;
  padding-bottom: 12px;
  font-size: 12px;
}

.overview__table-heading:nth-child(1) {
  color: #ef92aa;
}

.overview__table-heading:nth-child(2) {
  color: #f39f95;
}

.overview__table-heading:nth-child(3) {
  color: #b4bcbe;
}

.overview__table-heading:nth-child(4) {
  color: #d8e0a2;
}

.overview__table-heading:nth-child(5) {
  color: #9ede97;
}

@media screen and (max-width: 767px) {
  .overview__table-heading:nth-child(1) {
    text-align: left;
    grid-column: span 2;
    padding-left: 12px;
  }

  .overview__table-heading:nth-child(5) {
    text-align: right;
    grid-column: span 2;
    padding-right: 12px;
  }

  .overview__table-heading:nth-child(2), .overview__table-heading:nth-child(4) {
    display: none;
  }
}

.overview__line {
  height: 4px;
  width: 100%;
  background: linear-gradient(90deg, #da3a55 10.23%, #e8834a 29.87%, #fff 50.31%, #c2cf57 69.79%, #4ec869 90.26%);
  border: none;
  grid-column: 1 / -1;
  margin: 0;
}

.overview__heading {
  padding: .75rem 0;
}

.overview__heading span {
  color: #44baab;
}

.overview__buttons {
  display: flex;
}

.overview__button {
  width: 100%;
  cursor: pointer;
  background: rgba(255, 255, 255, .08);
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  margin: 0 1rem 0 0;
  padding: .75rem;
  font-weight: 500;
  display: flex;
}

.overview__button img {
  height: 1.75rem;
  padding: 0 0 0 .75rem;
}

.overview__button:first-child img {
  transform: rotate(180deg);
}

.overview__button:nth-child(2) {
  max-width: 12ch;
  color: #44baab;
  cursor: default;
  background: none;
  border: 2px solid rgba(255, 255, 255, .08);
  font-size: .75rem;
  font-weight: 700;
}

.overview__button:last-child {
  margin: 0;
}

.overview__button--disabled {
  color: rgba(255, 255, 255, .16);
  cursor: default;
}

.overview__button--disabled img {
  opacity: .16;
}

.overview__preamble {
  color: #95a8b1;
}

.overview__preamble span {
  font-weight: 700;
}

.overview__argument {
  border-radius: .5rem;
  margin: 1.25rem 0;
  padding: 1rem;
  position: relative;
}

.overview__argument--pro {
  background: rgba(68, 186, 171, .1);
}

.overview__argument--pro div {
  background: #44baab;
}

.overview__argument--con {
  background: rgba(220, 82, 89, .1);
}

.overview__argument--con div {
  background: #dc5259;
}

.overview__argument p {
  color: #fff;
  margin: 0;
}

.overview__argument div {
  width: .125rem;
  height: calc(100% - 2rem);
  border-radius: 1px;
  position: absolute;
  top: 1rem;
  left: 0;
}

.overview__legend {
  color: #95a8b1;
  grid-template-columns: 1fr 1fr 1fr;
  padding: .75rem 0;
  font-size: .75rem;
  display: grid;
}

@media screen and (min-width: 768px) {
  .overview__legend {
    font-size: 1rem;
    display: flex;
  }
}

@media screen and (min-width: 768px) {
  .overview__legend div {
    margin: 0 .5rem 0 1rem;
  }
}

.overview__legend span {
  align-items: center;
  padding: 0 .25rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .overview__legend span:first-child div {
    margin-left: 0;
  }
}

.overview__opinions {
  background: rgba(255, 255, 255, .08);
  border-radius: .5rem;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.overview__opinion {
  border-bottom: 1px solid rgba(255, 255, 255, .08);
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: .75rem 1rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .overview__opinion {
    flex-direction: row;
    align-items: center;
  }
}

.overview__opinion h2 {
  grid-template-columns: min-content 1fr;
  margin: 0;
  padding: .5rem 0;
  font-size: 1rem;
  display: grid;
}

.overview__opinion h2 span {
  color: #95a8b1;
  padding: 0 .25rem 0 0;
}

@media screen and (max-width: 767px) {
  .overview__opinion h2 {
    padding-left: 2rem;
    font-size: .875rem;
    display: block;
  }

  .overview__opinion h2 span {
    position: absolute;
    left: 1rem;
  }
}

.overview__opinion:first-child {
  z-index: 1;
  background: #3c4b52;
  border-bottom: none;
  transition: transform .5s ease-out;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.overview__opinion:first-child h2 {
  display: none;
}

@media screen and (min-width: 768px) {
  .overview__opinion:first-child {
    border-radius: .5rem .5rem 0 0;
    top: calc(4.5rem + 2px);
    box-shadow: 0 -.5rem #2c383f;
  }

  .overview__opinion:first-child h2 {
    display: block;
  }
}

.overview__opinion:last-child {
  border-bottom: none;
}

.overview__circles {
  justify-content: center;
  margin-left: 2rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .overview__circles {
    justify-content: flex-end;
  }
}

.overview__circle {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1rem;
  flex-shrink: 0;
  margin: .25rem .25rem 0;
}

@media screen and (min-width: 768px) {
  .overview__circle {
    width: 2rem;
    height: 2rem;
    margin: .5rem .5rem 0;
  }
}

.overview__circle img {
  height: 1.5rem;
  width: 1.5rem;
}

@media screen and (min-width: 768px) {
  .overview__circle img {
    height: 2rem;
    width: 2rem;
  }
}

.overview__circle--0 {
  background: #dc5259;
}

.overview__circle--1 {
  background: #e88c49;
}

.overview__circle--2 {
  background: #95a8b1;
}

.overview__circle--3 {
  background: #b7c853;
}

.overview__circle--4 {
  background: #58c263;
}

.overview__circle--0 img, .overview__circle--1 img, .overview__circle--2 img, .overview__circle--3 img, .overview__circle--4 img {
  filter: invert();
  padding: .175rem;
}

@media screen and (min-width: 768px) {
  .overview__circle--0 img, .overview__circle--1 img, .overview__circle--2 img, .overview__circle--3 img, .overview__circle--4 img {
    padding: .375rem;
  }
}

.overview__circle--small {
  width: 1rem;
  height: 1rem;
}

@media screen and (min-width: 768px) {
  .overview__circle--small {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.overview__circle--small img {
  height: 1rem;
  width: 1rem;
}

@media screen and (min-width: 768px) {
  .overview__circle--small img {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.progress {
  width: 100%;
  background: #4c5e67;
}

.progress__horizontal {
  width: 0;
  height: .25rem;
  background: #44baab;
  border-radius: .25rem;
  transition: width .25s;
}

.video {
  width: 100%;
  aspect-ratio: 16 / 9;
}

.video__overlay {
  position: relative;
}

.video__buttons {
  z-index: 10;
  transition: opacity .3s ease-in-out;
  position: absolute;
  top: .25rem;
  right: .25rem;
}

@media screen and (min-width: 480px) {
  .video__buttons {
    top: 1.75rem;
    right: 1.75rem;
  }
}

.video__button {
  min-width: 42px;
  min-height: 42px;
  cursor: pointer;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: opacity .3s ease-in-out;
  display: inline-flex;
}

.video__button:focus-visible {
  opacity: .7;
}

.video__button:focus {
  outline: none;
}

.video__button img {
  box-sizing: content-box;
  width: 14px;
  height: 14px;
  background: rgba(0, 0, 0, .5);
  border-radius: .5rem;
  padding: .5rem;
}

@media screen and (min-width: 768px) {
  .video__button:first-child {
    margin-right: 1rem;
  }

  .video__button img {
    width: unset;
    height: unset;
    padding: .5rem 1rem;
  }
}

.video--controls-hidden .video__button {
  opacity: 0;
}

@media (hover: hover) {
  .video--controls-hidden:hover .video__button {
    opacity: 1;
  }
}

.video__primary-button {
  cursor: auto;
  width: 100%;
  height: 100%;
  z-index: 1;
  color: #fff;
  background-color: rgba(0, 0, 0, .2);
  border: none;
  border-radius: .25rem;
  align-content: center;
  justify-content: center;
  padding: 0;
  display: grid;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.video__primary-button--play {
  opacity: 0;
}

.video__primary-button--pause {
  opacity: 1 !important;
}

.video__primary-button img {
  width: 80px;
  height: 80px;
  background-color: rgba(0, 0, 0, .5);
  border-radius: 100%;
  padding: .5rem;
}

.video__player {
  aspect-ratio: 16 / 9;
  width: 100%;
  background: #8a9ea7;
  border-radius: .25rem;
  display: block;
}

.video__progress {
  color: #fff;
  font-variant-numeric: tabular-nums;
  z-index: 1;
  padding: 4px;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  transition: opacity .3s ease-in-out;
  position: absolute;
  bottom: 12px;
  left: 12px;
}

.video--controls-hidden .video__progress {
  opacity: 0;
}

@media (hover: hover) {
  .video--controls-hidden:hover .video__progress {
    opacity: 1;
  }
}

.about {
  background: linear-gradient(#3c4b52 0%, #2c383f 100%);
  flex: 1;
}

.about__container {
  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
  padding: 3rem 1.5rem 1.5rem;
}

@media screen and (min-width: 768px) {
  .about__container {
    padding: 3rem 0 1.5rem;
  }
}

.about__section h1 {
  margin: 0 0 .75rem;
}

.about__section p {
  color: #95a8b1;
}

.about__section:first-child p {
  color: #fff;
  margin: 0;
}

.about__heading h2 {
  margin: 2.5rem 0 0;
  padding: 0 0 0 1rem;
  font-size: 1.5rem;
  font-weight: 400;
}

.about__heading img {
  margin: 2.375rem 0 0;
}

.about__heading {
  align-items: center;
  display: flex;
}

.about__heading img {
  filter: invert(50%) sepia(60%) saturate(400%) hue-rotate(130deg) brightness(110%) contrast();
}

.about__footer {
  border-top: 1px solid #3c4b52;
  align-items: center;
  margin: 2rem 0 0;
  padding: 1.75rem 0 .5rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .about__footer {
    padding: 2rem 0 1rem;
  }
}

.about__disclaimer {
  padding: 0 1rem 0 0;
  font-size: 1rem;
  line-height: 1.625rem;
}

.about__disclaimer img {
  height: 2.5rem;
  width: 2.5rem;
}

@media screen and (min-width: 768px) {
  .about__disclaimer img {
    height: 3.5rem;
    width: 3.5rem;
  }
}

.about__disclaimer div {
  color: #95a8b1;
  display: inline-block;
}

.about__disclaimer a {
  text-decoration: underline;
  display: block;
}

@media screen and (min-width: 768px) {
  .about__disclaimer {
    padding: 0 1.5rem 0 0;
  }
}

.button {
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
}

.button__container {
  width: 100%;
  border: 1.5px solid rgba(255, 255, 255, .08);
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  padding: .75rem;
  display: flex;
}

.button__container--fill {
  background: rgba(255, 255, 255, .08);
  border: none;
}

.button__container--tv4 {
  background: #ff3334;
  border: none;
  margin: 2rem 0 0;
}

.button__container img {
  height: 1.75rem;
  padding: 0 0 0 .75rem;
}

.button__container span {
  font-weight: 500;
}

.buttonWithoutLabel {
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}

.crucial {
  width: fit-content;
  cursor: pointer;
  white-space: nowrap;
}

.crucial__container {
  background: rgba(255, 255, 255, .08);
  border-radius: 3rem;
  justify-content: center;
  align-items: center;
  margin: 0 0 1.25rem;
  padding: .5rem 1.5rem .5rem .5rem;
  display: flex;
}

.crucial__container img {
  height: 1.75rem;
  width: 1.75rem;
  box-sizing: content-box;
  padding: 0 .75rem;
}

.crucial__container span {
  font-weight: 500;
  line-height: 1.75rem;
}

.header {
  z-index: 11;
  background: #3c4b52;
  transition: transform .5s ease-out;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  transform: translateY(0);
  box-shadow: 0 8px 24px rgba(0, 0, 0, .12);
}

@media screen and (max-width: 767px) {
  .header--hide {
    transform: translateY(-100%);
  }
}

.header__cells {
  grid-template-columns: 1fr 2fr 1fr;
  padding: 0 1.5rem;
  display: grid;
}

.header__cell {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: .75rem 0;
  display: flex;
}

.header__cell span {
  padding: 0 .625rem;
  font-weight: 500;
  display: none;
}

.header__cell:first-child {
  justify-content: flex-start;
}

.header__cell:last-child {
  justify-content: flex-end;
}

.header__cell:focus-visible, .header__cell:focus {
  outline: none;
}

@media screen and (min-width: 768px) {
  .header__cell {
    padding: 1rem 0;
  }

  .header__cell span {
    display: inline-block;
  }
}

.header__claims {
  justify-content: space-between;
  padding: .5rem 0 1rem;
  display: flex;
  position: relative;
  overflow: scroll;
}

.header__claim {
  color: #95a8b1;
  min-width: 2rem;
  min-height: 2rem;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 .75rem;
  font-size: .875rem;
  font-weight: 700;
  display: flex;
}

.header__claim:focus-visible {
  opacity: .7;
}

.header__claim:focus {
  outline: none;
}

.header__claim:focus-visible {
  outline: 2px solid;
}

@media screen and (min-width: 768px) {
  .header__claim {
    margin: 0 0 0 1rem;
  }
}

.header__claim:last-child {
  margin: 0 .75rem;
}

@media screen and (min-width: 768px) {
  .header__claim:last-child {
    margin: 0 1rem;
  }
}

.header__claim--0 {
  color: #dc5259;
  cursor: pointer;
  background: rgba(220, 82, 89, .1);
}

.header__claim--1 {
  color: #e88c49;
  cursor: pointer;
  background: rgba(232, 140, 73, .1);
}

.header__claim--2 {
  cursor: pointer;
  background: #4c5e67;
}

.header__claim--3 {
  color: #b7c853;
  cursor: pointer;
  background: rgba(183, 200, 83, .1);
}

.header__claim--4 {
  color: #58c263;
  cursor: pointer;
  background: rgba(88, 194, 99, .1);
}

.header__claim--active {
  color: #2c383f;
  background: #fff;
}

.header__indicator {
  width: 0;
  height: 0;
  border-bottom: .375rem solid #fff;
  border-left: .375rem solid rgba(0, 0, 0, 0);
  border-right: .375rem solid rgba(0, 0, 0, 0);
  position: absolute;
  bottom: 0;
}

.loaders {
  z-index: 100;
  background: #2c383f;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.loaders img {
  height: 8rem;
  animation: spin 1s linear infinite;
}

.opinion {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  margin: 1rem .375rem;
}

.opinion:focus-visible {
  opacity: .7;
}

.opinion:focus {
  outline: none;
}

@media screen and (min-width: 768px) {
  .opinion {
    flex: 1;
  }
}

.opinion__button {
  border-radius: 2rem;
  justify-content: center;
  align-items: center;
  padding: .375rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .opinion__button {
    padding: .375rem 2rem;
  }
}

@media screen and (min-width: 480px) {
  .opinion__button:hover {
    outline: 2px solid #fff;
  }
}

.opinion__button img {
  width: 1.825rem;
  height: 1.825rem;
  border: 2px solid rgba(0, 0, 0, .04);
  border-radius: 3rem;
  display: block;
}

@media screen and (min-width: 768px) {
  .opinion__button img {
    width: 2.5rem;
    height: 2.5rem;
    border: 4px solid rgba(0, 0, 0, .04);
  }
}

.opinion__button .show-checkmark {
  border-color: rgba(0, 0, 0, 0);
}

.opinion__button.bounce {
  animation: bounce .5s;
}

.opinion__button.bounce img {
  border: 2px solid rgba(0, 0, 0, 0);
}

.opinion__button--0 {
  background: #dc5259;
}

.opinion__button--1 {
  background: #e88c49;
}

.opinion__button--2 {
  background: rgba(156, 165, 168, .36);
}

@media screen and (min-width: 768px) {
  .opinion__button--2 {
    background: none;
    border: 2px solid #3c4b52;
  }

  .opinion__button--2 img {
    border: 4px solid #3c4b52;
  }
}

.opinion__button--3 {
  background: #b7c853;
}

.opinion__button--4 {
  background: #58c263;
}

.overview {
  padding: 0 1.5rem 2.5rem;
}

@media screen and (min-width: 768px) {
  .overview {
    padding: 2.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .overview {
    padding: 5rem;
  }
}

.overview__links {
  padding: 0 0 .75rem;
}

.overview__link {
  cursor: pointer;
  margin: 0 1.5rem 0 0;
  padding: .25rem 0;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.375rem;
}

.overview__link--active {
  border-bottom: 2px solid #44baab;
}

.overview__question {
  max-width: 40rem;
  margin: 0 auto;
  overflow: visible;
}

.overview__slider {
  flex-flow: row;
  display: flex;
}

.overview__slide {
  width: 100%;
  flex-shrink: 0;
}

.overview__slide.animate {
  animation: fade 1.5s;
}

.overview__table {
  min-height: 280px;
  background-image: linear-gradient(rgba(0, 0, 0, 0) 0%, #2c383f 100%), linear-gradient(rgba(255, 255, 255, .08) 0%, rgba(255, 255, 255, 0) 100%), repeating-linear-gradient(90deg, #2c383f 0%, #2c383f 10%, rgba(255, 255, 255, .32) calc(10% + 1px), #2c383f 10%, #2c383f 20%);
  background-repeat: repeat;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: min-content;
  grid-auto-flow: row dense;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  padding-bottom: 20px;
  display: grid;
  position: relative;
}

.overview__table-token {
  text-align: center;
  width: 32px;
  height: 32px;
  margin: 12px auto 0;
}

.overview__table-token--0 {
  grid-column: 1;
}

.overview__table-token--1 {
  grid-column: 2;
}

.overview__table-token--2 {
  grid-column: 3;
}

.overview__table-token--3 {
  grid-column: 4;
}

.overview__table-token--4 {
  grid-column: 5;
}

.overview__table-heading {
  text-align: center;
  background-color: #2c383f;
  margin: 0;
  padding-bottom: 12px;
  font-size: 12px;
}

.overview__table-heading:nth-child(1) {
  color: #ef92aa;
}

.overview__table-heading:nth-child(2) {
  color: #f39f95;
}

.overview__table-heading:nth-child(3) {
  color: #b4bcbe;
}

.overview__table-heading:nth-child(4) {
  color: #d8e0a2;
}

.overview__table-heading:nth-child(5) {
  color: #9ede97;
}

@media screen and (max-width: 767px) {
  .overview__table-heading:nth-child(1) {
    text-align: left;
    grid-column: span 2;
    padding-left: 12px;
  }

  .overview__table-heading:nth-child(5) {
    text-align: right;
    grid-column: span 2;
    padding-right: 12px;
  }

  .overview__table-heading:nth-child(2), .overview__table-heading:nth-child(4) {
    display: none;
  }
}

.overview__line {
  height: 4px;
  width: 100%;
  background: linear-gradient(90deg, #da3a55 10.23%, #e8834a 29.87%, #fff 50.31%, #c2cf57 69.79%, #4ec869 90.26%);
  border: none;
  grid-column: 1 / -1;
  margin: 0;
}

.overview__heading {
  padding: .75rem 0;
}

.overview__heading span {
  color: #44baab;
}

.overview__buttons {
  display: flex;
}

.overview__button {
  width: 100%;
  cursor: pointer;
  background: rgba(255, 255, 255, .08);
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  margin: 0 1rem 0 0;
  padding: .75rem;
  font-weight: 500;
  display: flex;
}

.overview__button img {
  height: 1.75rem;
  padding: 0 0 0 .75rem;
}

.overview__button:first-child img {
  transform: rotate(180deg);
}

.overview__button:nth-child(2) {
  max-width: 12ch;
  color: #44baab;
  cursor: default;
  background: none;
  border: 2px solid rgba(255, 255, 255, .08);
  font-size: .75rem;
  font-weight: 700;
}

.overview__button:last-child {
  margin: 0;
}

.overview__button--disabled {
  color: rgba(255, 255, 255, .16);
  cursor: default;
}

.overview__button--disabled img {
  opacity: .16;
}

.overview__preamble {
  color: #95a8b1;
}

.overview__preamble span {
  font-weight: 700;
}

.overview__argument {
  border-radius: .5rem;
  margin: 1.25rem 0;
  padding: 1rem;
  position: relative;
}

.overview__argument--pro {
  background: rgba(68, 186, 171, .1);
}

.overview__argument--pro div {
  background: #44baab;
}

.overview__argument--con {
  background: rgba(220, 82, 89, .1);
}

.overview__argument--con div {
  background: #dc5259;
}

.overview__argument p {
  color: #fff;
  margin: 0;
}

.overview__argument div {
  width: .125rem;
  height: calc(100% - 2rem);
  border-radius: 1px;
  position: absolute;
  top: 1rem;
  left: 0;
}

.overview__legend {
  color: #95a8b1;
  grid-template-columns: 1fr 1fr 1fr;
  padding: .75rem 0;
  font-size: .75rem;
  display: grid;
}

@media screen and (min-width: 768px) {
  .overview__legend {
    font-size: 1rem;
    display: flex;
  }
}

@media screen and (min-width: 768px) {
  .overview__legend div {
    margin: 0 .5rem 0 1rem;
  }
}

.overview__legend span {
  align-items: center;
  padding: 0 .25rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .overview__legend span:first-child div {
    margin-left: 0;
  }
}

.overview__opinions {
  background: rgba(255, 255, 255, .08);
  border-radius: .5rem;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.overview__opinion {
  border-bottom: 1px solid rgba(255, 255, 255, .08);
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: .75rem 1rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .overview__opinion {
    flex-direction: row;
    align-items: center;
  }
}

.overview__opinion h2 {
  grid-template-columns: min-content 1fr;
  margin: 0;
  padding: .5rem 0;
  font-size: 1rem;
  display: grid;
}

.overview__opinion h2 span {
  color: #95a8b1;
  padding: 0 .25rem 0 0;
}

@media screen and (max-width: 767px) {
  .overview__opinion h2 {
    padding-left: 2rem;
    font-size: .875rem;
    display: block;
  }

  .overview__opinion h2 span {
    position: absolute;
    left: 1rem;
  }
}

.overview__opinion:first-child {
  z-index: 1;
  background: #3c4b52;
  border-bottom: none;
  transition: transform .5s ease-out;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.overview__opinion:first-child h2 {
  display: none;
}

@media screen and (min-width: 768px) {
  .overview__opinion:first-child {
    border-radius: .5rem .5rem 0 0;
    top: calc(4.5rem + 2px);
    box-shadow: 0 -.5rem #2c383f;
  }

  .overview__opinion:first-child h2 {
    display: block;
  }
}

.overview__opinion:last-child {
  border-bottom: none;
}

.overview__circles {
  justify-content: center;
  margin-left: 2rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .overview__circles {
    justify-content: flex-end;
  }
}

.overview__circle {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1rem;
  flex-shrink: 0;
  margin: .25rem .25rem 0;
}

@media screen and (min-width: 768px) {
  .overview__circle {
    width: 2rem;
    height: 2rem;
    margin: .5rem .5rem 0;
  }
}

.overview__circle img {
  height: 1.5rem;
  width: 1.5rem;
}

@media screen and (min-width: 768px) {
  .overview__circle img {
    height: 2rem;
    width: 2rem;
  }
}

.overview__circle--0 {
  background: #dc5259;
}

.overview__circle--1 {
  background: #e88c49;
}

.overview__circle--2 {
  background: #95a8b1;
}

.overview__circle--3 {
  background: #b7c853;
}

.overview__circle--4 {
  background: #58c263;
}

.overview__circle--0 img, .overview__circle--1 img, .overview__circle--2 img, .overview__circle--3 img, .overview__circle--4 img {
  filter: invert();
  padding: .175rem;
}

@media screen and (min-width: 768px) {
  .overview__circle--0 img, .overview__circle--1 img, .overview__circle--2 img, .overview__circle--3 img, .overview__circle--4 img {
    padding: .375rem;
  }
}

.overview__circle--small {
  width: 1rem;
  height: 1rem;
}

@media screen and (min-width: 768px) {
  .overview__circle--small {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.overview__circle--small img {
  height: 1rem;
  width: 1rem;
}

@media screen and (min-width: 768px) {
  .overview__circle--small img {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.progress {
  width: 100%;
  background: #4c5e67;
}

.progress__horizontal {
  width: 0;
  height: .25rem;
  background: #44baab;
  border-radius: .25rem;
  transition: width .25s;
}

.video {
  width: 100%;
  aspect-ratio: 16 / 9;
}

.video__overlay {
  position: relative;
}

.video__buttons {
  z-index: 10;
  transition: opacity .3s ease-in-out;
  position: absolute;
  top: .25rem;
  right: .25rem;
}

@media screen and (min-width: 480px) {
  .video__buttons {
    top: 1.75rem;
    right: 1.75rem;
  }
}

.video__button {
  min-width: 42px;
  min-height: 42px;
  cursor: pointer;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: opacity .3s ease-in-out;
  display: inline-flex;
}

.video__button:focus-visible {
  opacity: .7;
}

.video__button:focus {
  outline: none;
}

.video__button img {
  box-sizing: content-box;
  width: 14px;
  height: 14px;
  background: rgba(0, 0, 0, .5);
  border-radius: .5rem;
  padding: .5rem;
}

@media screen and (min-width: 768px) {
  .video__button:first-child {
    margin-right: 1rem;
  }

  .video__button img {
    width: unset;
    height: unset;
    padding: .5rem 1rem;
  }
}

.video--controls-hidden .video__button {
  opacity: 0;
}

@media (hover: hover) {
  .video--controls-hidden:hover .video__button {
    opacity: 1;
  }
}

.video__primary-button {
  cursor: auto;
  width: 100%;
  height: 100%;
  z-index: 1;
  color: #fff;
  background-color: rgba(0, 0, 0, .2);
  border: none;
  border-radius: .25rem;
  align-content: center;
  justify-content: center;
  padding: 0;
  display: grid;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.video__primary-button--play {
  opacity: 0;
}

.video__primary-button--pause {
  opacity: 1 !important;
}

.video__primary-button img {
  width: 80px;
  height: 80px;
  background-color: rgba(0, 0, 0, .5);
  border-radius: 100%;
  padding: .5rem;
}

.video__player {
  aspect-ratio: 16 / 9;
  width: 100%;
  background: #8a9ea7;
  border-radius: .25rem;
  display: block;
}

.video__progress {
  color: #fff;
  font-variant-numeric: tabular-nums;
  z-index: 1;
  padding: 4px;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  transition: opacity .3s ease-in-out;
  position: absolute;
  bottom: 12px;
  left: 12px;
}

.video--controls-hidden .video__progress {
  opacity: 0;
}

@media (hover: hover) {
  .video--controls-hidden:hover .video__progress {
    opacity: 1;
  }
}

.about {
  background: linear-gradient(#3c4b52 0%, #2c383f 100%);
  flex: 1;
}

.about__container {
  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
  padding: 3rem 1.5rem 1.5rem;
}

@media screen and (min-width: 768px) {
  .about__container {
    padding: 3rem 0 1.5rem;
  }
}

.about__section h1 {
  margin: 0 0 .75rem;
}

.about__section p {
  color: #95a8b1;
}

.about__section:first-child p {
  color: #fff;
  margin: 0;
}

.about__heading h2 {
  margin: 2.5rem 0 0;
  padding: 0 0 0 1rem;
  font-size: 1.5rem;
  font-weight: 400;
}

.about__heading img {
  margin: 2.375rem 0 0;
}

.about__heading {
  align-items: center;
  display: flex;
}

.about__heading img {
  filter: invert(50%) sepia(60%) saturate(400%) hue-rotate(130deg) brightness(110%) contrast();
}

.about__footer {
  border-top: 1px solid #3c4b52;
  align-items: center;
  margin: 2rem 0 0;
  padding: 1.75rem 0 .5rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .about__footer {
    padding: 2rem 0 1rem;
  }
}

.about__disclaimer {
  padding: 0 1rem 0 0;
  font-size: 1rem;
  line-height: 1.625rem;
}

.about__disclaimer img {
  height: 2.5rem;
  width: 2.5rem;
}

@media screen and (min-width: 768px) {
  .about__disclaimer img {
    height: 3.5rem;
    width: 3.5rem;
  }
}

.about__disclaimer div {
  color: #95a8b1;
  display: inline-block;
}

.about__disclaimer a {
  text-decoration: underline;
  display: block;
}

@media screen and (min-width: 768px) {
  .about__disclaimer {
    padding: 0 1.5rem 0 0;
  }
}

.button {
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
}

.button__container {
  width: 100%;
  border: 1.5px solid rgba(255, 255, 255, .08);
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  padding: .75rem;
  display: flex;
}

.button__container--fill {
  background: rgba(255, 255, 255, .08);
  border: none;
}

.button__container--tv4 {
  background: #ff3334;
  border: none;
  margin: 2rem 0 0;
}

.button__container img {
  height: 1.75rem;
  padding: 0 0 0 .75rem;
}

.button__container span {
  font-weight: 500;
}

.buttonWithoutLabel {
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}

.crucial {
  width: fit-content;
  cursor: pointer;
  white-space: nowrap;
}

.crucial__container {
  background: rgba(255, 255, 255, .08);
  border-radius: 3rem;
  justify-content: center;
  align-items: center;
  margin: 0 0 1.25rem;
  padding: .5rem 1.5rem .5rem .5rem;
  display: flex;
}

.crucial__container img {
  height: 1.75rem;
  width: 1.75rem;
  box-sizing: content-box;
  padding: 0 .75rem;
}

.crucial__container span {
  font-weight: 500;
  line-height: 1.75rem;
}

.header {
  z-index: 11;
  background: #3c4b52;
  transition: transform .5s ease-out;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  transform: translateY(0);
  box-shadow: 0 8px 24px rgba(0, 0, 0, .12);
}

@media screen and (max-width: 767px) {
  .header--hide {
    transform: translateY(-100%);
  }
}

.header__cells {
  grid-template-columns: 1fr 2fr 1fr;
  padding: 0 1.5rem;
  display: grid;
}

.header__cell {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: .75rem 0;
  display: flex;
}

.header__cell span {
  padding: 0 .625rem;
  font-weight: 500;
  display: none;
}

.header__cell:first-child {
  justify-content: flex-start;
}

.header__cell:last-child {
  justify-content: flex-end;
}

.header__cell:focus-visible, .header__cell:focus {
  outline: none;
}

@media screen and (min-width: 768px) {
  .header__cell {
    padding: 1rem 0;
  }

  .header__cell span {
    display: inline-block;
  }
}

.header__claims {
  justify-content: space-between;
  padding: .5rem 0 1rem;
  display: flex;
  position: relative;
  overflow: scroll;
}

.header__claim {
  color: #95a8b1;
  min-width: 2rem;
  min-height: 2rem;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 .75rem;
  font-size: .875rem;
  font-weight: 700;
  display: flex;
}

.header__claim:focus-visible {
  opacity: .7;
}

.header__claim:focus {
  outline: none;
}

.header__claim:focus-visible {
  outline: 2px solid;
}

@media screen and (min-width: 768px) {
  .header__claim {
    margin: 0 0 0 1rem;
  }
}

.header__claim:last-child {
  margin: 0 .75rem;
}

@media screen and (min-width: 768px) {
  .header__claim:last-child {
    margin: 0 1rem;
  }
}

.header__claim--0 {
  color: #dc5259;
  cursor: pointer;
  background: rgba(220, 82, 89, .1);
}

.header__claim--1 {
  color: #e88c49;
  cursor: pointer;
  background: rgba(232, 140, 73, .1);
}

.header__claim--2 {
  cursor: pointer;
  background: #4c5e67;
}

.header__claim--3 {
  color: #b7c853;
  cursor: pointer;
  background: rgba(183, 200, 83, .1);
}

.header__claim--4 {
  color: #58c263;
  cursor: pointer;
  background: rgba(88, 194, 99, .1);
}

.header__claim--active {
  color: #2c383f;
  background: #fff;
}

.header__indicator {
  width: 0;
  height: 0;
  border-bottom: .375rem solid #fff;
  border-left: .375rem solid rgba(0, 0, 0, 0);
  border-right: .375rem solid rgba(0, 0, 0, 0);
  position: absolute;
  bottom: 0;
}

.loaders {
  z-index: 100;
  background: #2c383f;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.loaders img {
  height: 8rem;
  animation: spin 1s linear infinite;
}

.opinion {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  margin: 1rem .375rem;
}

.opinion:focus-visible {
  opacity: .7;
}

.opinion:focus {
  outline: none;
}

@media screen and (min-width: 768px) {
  .opinion {
    flex: 1;
  }
}

.opinion__button {
  border-radius: 2rem;
  justify-content: center;
  align-items: center;
  padding: .375rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .opinion__button {
    padding: .375rem 2rem;
  }
}

@media screen and (min-width: 480px) {
  .opinion__button:hover {
    outline: 2px solid #fff;
  }
}

.opinion__button img {
  width: 1.825rem;
  height: 1.825rem;
  border: 2px solid rgba(0, 0, 0, .04);
  border-radius: 3rem;
  display: block;
}

@media screen and (min-width: 768px) {
  .opinion__button img {
    width: 2.5rem;
    height: 2.5rem;
    border: 4px solid rgba(0, 0, 0, .04);
  }
}

.opinion__button .show-checkmark {
  border-color: rgba(0, 0, 0, 0);
}

.opinion__button.bounce {
  animation: bounce .5s;
}

.opinion__button.bounce img {
  border: 2px solid rgba(0, 0, 0, 0);
}

.opinion__button--0 {
  background: #dc5259;
}

.opinion__button--1 {
  background: #e88c49;
}

.opinion__button--2 {
  background: rgba(156, 165, 168, .36);
}

@media screen and (min-width: 768px) {
  .opinion__button--2 {
    background: none;
    border: 2px solid #3c4b52;
  }

  .opinion__button--2 img {
    border: 4px solid #3c4b52;
  }
}

.opinion__button--3 {
  background: #b7c853;
}

.opinion__button--4 {
  background: #58c263;
}

.overview {
  padding: 0 1.5rem 2.5rem;
}

@media screen and (min-width: 768px) {
  .overview {
    padding: 2.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .overview {
    padding: 5rem;
  }
}

.overview__links {
  padding: 0 0 .75rem;
}

.overview__link {
  cursor: pointer;
  margin: 0 1.5rem 0 0;
  padding: .25rem 0;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.375rem;
}

.overview__link--active {
  border-bottom: 2px solid #44baab;
}

.overview__question {
  max-width: 40rem;
  margin: 0 auto;
  overflow: visible;
}

.overview__slider {
  flex-flow: row;
  display: flex;
}

.overview__slide {
  width: 100%;
  flex-shrink: 0;
}

.overview__slide.animate {
  animation: fade 1.5s;
}

.overview__table {
  min-height: 280px;
  background-image: linear-gradient(rgba(0, 0, 0, 0) 0%, #2c383f 100%), linear-gradient(rgba(255, 255, 255, .08) 0%, rgba(255, 255, 255, 0) 100%), repeating-linear-gradient(90deg, #2c383f 0%, #2c383f 10%, rgba(255, 255, 255, .32) calc(10% + 1px), #2c383f 10%, #2c383f 20%);
  background-repeat: repeat;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: min-content;
  grid-auto-flow: row dense;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  padding-bottom: 20px;
  display: grid;
  position: relative;
}

.overview__table-token {
  text-align: center;
  width: 32px;
  height: 32px;
  margin: 12px auto 0;
}

.overview__table-token--0 {
  grid-column: 1;
}

.overview__table-token--1 {
  grid-column: 2;
}

.overview__table-token--2 {
  grid-column: 3;
}

.overview__table-token--3 {
  grid-column: 4;
}

.overview__table-token--4 {
  grid-column: 5;
}

.overview__table-heading {
  text-align: center;
  background-color: #2c383f;
  margin: 0;
  padding-bottom: 12px;
  font-size: 12px;
}

.overview__table-heading:nth-child(1) {
  color: #ef92aa;
}

.overview__table-heading:nth-child(2) {
  color: #f39f95;
}

.overview__table-heading:nth-child(3) {
  color: #b4bcbe;
}

.overview__table-heading:nth-child(4) {
  color: #d8e0a2;
}

.overview__table-heading:nth-child(5) {
  color: #9ede97;
}

@media screen and (max-width: 767px) {
  .overview__table-heading:nth-child(1) {
    text-align: left;
    grid-column: span 2;
    padding-left: 12px;
  }

  .overview__table-heading:nth-child(5) {
    text-align: right;
    grid-column: span 2;
    padding-right: 12px;
  }

  .overview__table-heading:nth-child(2), .overview__table-heading:nth-child(4) {
    display: none;
  }
}

.overview__line {
  height: 4px;
  width: 100%;
  background: linear-gradient(90deg, #da3a55 10.23%, #e8834a 29.87%, #fff 50.31%, #c2cf57 69.79%, #4ec869 90.26%);
  border: none;
  grid-column: 1 / -1;
  margin: 0;
}

.overview__heading {
  padding: .75rem 0;
}

.overview__heading span {
  color: #44baab;
}

.overview__buttons {
  display: flex;
}

.overview__button {
  width: 100%;
  cursor: pointer;
  background: rgba(255, 255, 255, .08);
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  margin: 0 1rem 0 0;
  padding: .75rem;
  font-weight: 500;
  display: flex;
}

.overview__button img {
  height: 1.75rem;
  padding: 0 0 0 .75rem;
}

.overview__button:first-child img {
  transform: rotate(180deg);
}

.overview__button:nth-child(2) {
  max-width: 12ch;
  color: #44baab;
  cursor: default;
  background: none;
  border: 2px solid rgba(255, 255, 255, .08);
  font-size: .75rem;
  font-weight: 700;
}

.overview__button:last-child {
  margin: 0;
}

.overview__button--disabled {
  color: rgba(255, 255, 255, .16);
  cursor: default;
}

.overview__button--disabled img {
  opacity: .16;
}

.overview__preamble {
  color: #95a8b1;
}

.overview__preamble span {
  font-weight: 700;
}

.overview__argument {
  border-radius: .5rem;
  margin: 1.25rem 0;
  padding: 1rem;
  position: relative;
}

.overview__argument--pro {
  background: rgba(68, 186, 171, .1);
}

.overview__argument--pro div {
  background: #44baab;
}

.overview__argument--con {
  background: rgba(220, 82, 89, .1);
}

.overview__argument--con div {
  background: #dc5259;
}

.overview__argument p {
  color: #fff;
  margin: 0;
}

.overview__argument div {
  width: .125rem;
  height: calc(100% - 2rem);
  border-radius: 1px;
  position: absolute;
  top: 1rem;
  left: 0;
}

.overview__legend {
  color: #95a8b1;
  grid-template-columns: 1fr 1fr 1fr;
  padding: .75rem 0;
  font-size: .75rem;
  display: grid;
}

@media screen and (min-width: 768px) {
  .overview__legend {
    font-size: 1rem;
    display: flex;
  }
}

@media screen and (min-width: 768px) {
  .overview__legend div {
    margin: 0 .5rem 0 1rem;
  }
}

.overview__legend span {
  align-items: center;
  padding: 0 .25rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .overview__legend span:first-child div {
    margin-left: 0;
  }
}

.overview__opinions {
  background: rgba(255, 255, 255, .08);
  border-radius: .5rem;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.overview__opinion {
  border-bottom: 1px solid rgba(255, 255, 255, .08);
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: .75rem 1rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .overview__opinion {
    flex-direction: row;
    align-items: center;
  }
}

.overview__opinion h2 {
  grid-template-columns: min-content 1fr;
  margin: 0;
  padding: .5rem 0;
  font-size: 1rem;
  display: grid;
}

.overview__opinion h2 span {
  color: #95a8b1;
  padding: 0 .25rem 0 0;
}

@media screen and (max-width: 767px) {
  .overview__opinion h2 {
    padding-left: 2rem;
    font-size: .875rem;
    display: block;
  }

  .overview__opinion h2 span {
    position: absolute;
    left: 1rem;
  }
}

.overview__opinion:first-child {
  z-index: 1;
  background: #3c4b52;
  border-bottom: none;
  transition: transform .5s ease-out;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.overview__opinion:first-child h2 {
  display: none;
}

@media screen and (min-width: 768px) {
  .overview__opinion:first-child {
    border-radius: .5rem .5rem 0 0;
    top: calc(4.5rem + 2px);
    box-shadow: 0 -.5rem #2c383f;
  }

  .overview__opinion:first-child h2 {
    display: block;
  }
}

.overview__opinion:last-child {
  border-bottom: none;
}

.overview__circles {
  justify-content: center;
  margin-left: 2rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .overview__circles {
    justify-content: flex-end;
  }
}

.overview__circle {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1rem;
  flex-shrink: 0;
  margin: .25rem .25rem 0;
}

@media screen and (min-width: 768px) {
  .overview__circle {
    width: 2rem;
    height: 2rem;
    margin: .5rem .5rem 0;
  }
}

.overview__circle img {
  height: 1.5rem;
  width: 1.5rem;
}

@media screen and (min-width: 768px) {
  .overview__circle img {
    height: 2rem;
    width: 2rem;
  }
}

.overview__circle--0 {
  background: #dc5259;
}

.overview__circle--1 {
  background: #e88c49;
}

.overview__circle--2 {
  background: #95a8b1;
}

.overview__circle--3 {
  background: #b7c853;
}

.overview__circle--4 {
  background: #58c263;
}

.overview__circle--0 img, .overview__circle--1 img, .overview__circle--2 img, .overview__circle--3 img, .overview__circle--4 img {
  filter: invert();
  padding: .175rem;
}

@media screen and (min-width: 768px) {
  .overview__circle--0 img, .overview__circle--1 img, .overview__circle--2 img, .overview__circle--3 img, .overview__circle--4 img {
    padding: .375rem;
  }
}

.overview__circle--small {
  width: 1rem;
  height: 1rem;
}

@media screen and (min-width: 768px) {
  .overview__circle--small {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.overview__circle--small img {
  height: 1rem;
  width: 1rem;
}

@media screen and (min-width: 768px) {
  .overview__circle--small img {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.progress {
  width: 100%;
  background: #4c5e67;
}

.progress__horizontal {
  width: 0;
  height: .25rem;
  background: #44baab;
  border-radius: .25rem;
  transition: width .25s;
}

.video {
  width: 100%;
  aspect-ratio: 16 / 9;
}

.video__overlay {
  position: relative;
}

.video__buttons {
  z-index: 10;
  transition: opacity .3s ease-in-out;
  position: absolute;
  top: .25rem;
  right: .25rem;
}

@media screen and (min-width: 480px) {
  .video__buttons {
    top: 1.75rem;
    right: 1.75rem;
  }
}

.video__button {
  min-width: 42px;
  min-height: 42px;
  cursor: pointer;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: opacity .3s ease-in-out;
  display: inline-flex;
}

.video__button:focus-visible {
  opacity: .7;
}

.video__button:focus {
  outline: none;
}

.video__button img {
  box-sizing: content-box;
  width: 14px;
  height: 14px;
  background: rgba(0, 0, 0, .5);
  border-radius: .5rem;
  padding: .5rem;
}

@media screen and (min-width: 768px) {
  .video__button:first-child {
    margin-right: 1rem;
  }

  .video__button img {
    width: unset;
    height: unset;
    padding: .5rem 1rem;
  }
}

.video--controls-hidden .video__button {
  opacity: 0;
}

@media (hover: hover) {
  .video--controls-hidden:hover .video__button {
    opacity: 1;
  }
}

.video__primary-button {
  cursor: auto;
  width: 100%;
  height: 100%;
  z-index: 1;
  color: #fff;
  background-color: rgba(0, 0, 0, .2);
  border: none;
  border-radius: .25rem;
  align-content: center;
  justify-content: center;
  padding: 0;
  display: grid;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.video__primary-button--play {
  opacity: 0;
}

.video__primary-button--pause {
  opacity: 1 !important;
}

.video__primary-button img {
  width: 80px;
  height: 80px;
  background-color: rgba(0, 0, 0, .5);
  border-radius: 100%;
  padding: .5rem;
}

.video__player {
  aspect-ratio: 16 / 9;
  width: 100%;
  background: #8a9ea7;
  border-radius: .25rem;
  display: block;
}

.video__progress {
  color: #fff;
  font-variant-numeric: tabular-nums;
  z-index: 1;
  padding: 4px;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  transition: opacity .3s ease-in-out;
  position: absolute;
  bottom: 12px;
  left: 12px;
}

.video--controls-hidden .video__progress {
  opacity: 0;
}

@media (hover: hover) {
  .video--controls-hidden:hover .video__progress {
    opacity: 1;
  }
}

.error {
  min-height: calc(100vh - 4rem);
  background: #2c383f 0 0 / cover;
  flex-wrap: wrap;
  align-items: center;
  display: flex;
  position: relative;
}

.error__wrapper {
  width: 100%;
  max-width: 40rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 2rem;
  display: flex;
}

.error__wrapper img {
  width: 50%;
  height: 50%;
  padding: 2rem;
  display: block;
}

.error__heading {
  margin: 0;
  font-size: 2rem;
  font-weight: 700;
}

@media screen and (min-width: 768px) {
  .error__heading {
    font-size: 3.375rem;
  }
}

.error__preamble {
  color: #95a8b1;
  padding: 0 1rem;
}

.error__heading, .error__preamble {
  text-align: center;
}

.home {
  min-height: calc(100vh - 4.5rem);
  background: #2c383f 50% -60px no-repeat;
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
  position: relative;
}

.home__unvalid-slide {
  height: auto;
  min-height: initial;
  background: linear-gradient(#3c4b52 0%, #2c383f 100%);
  background-image: unset !important;
}

.home__question {
  overflow: hidden;
}

.home__slider {
  max-width: 40rem;
  flex-flow: row;
  display: flex;
}

.home__slide {
  width: 100%;
  flex-shrink: 0;
  padding: 1.5rem;
}

.home__slide.animate {
  animation: fade .75s;
}

@media screen and (min-width: 768px) {
  .home__slide {
    padding: 3rem 0;
  }
}

.home__heading {
  font-size: 1.55rem;
}

@media screen and (min-width: 768px) {
  .home__heading {
    font-size: 2rem;
  }
}

.home__heading span {
  color: #44baab;
}

.home__preamble {
  color: #95a8b1;
}

.home__preamble span {
  font-weight: 700;
}

.home__argument {
  border-radius: .5rem;
  margin: 1.25rem 0;
  padding: 1rem;
  position: relative;
}

.home__argument--pro {
  background: rgba(68, 186, 171, .1);
}

.home__argument--pro div {
  background: #44baab;
}

.home__argument--con {
  background: rgba(220, 82, 89, .1);
}

.home__argument--con div {
  background: #dc5259;
}

.home__argument p {
  color: #fff;
  margin: 0;
}

.home__argument div {
  width: .125rem;
  height: calc(100% - 2rem);
  border-radius: 1px;
  position: absolute;
  top: 1rem;
  left: 0;
}

.home__opinions {
  width: 100%;
  z-index: 1;
  background: #2c383f;
  margin-top: auto;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}

.home__opinion {
  width: 100%;
  max-width: 40rem;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

@media screen and (min-width: 768px) {
  .home__opinion {
    justify-content: center;
  }
}

.home__labels {
  color: #95a8b1;
  text-align: center;
  width: 100%;
  max-width: 40rem;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 auto;
  padding: 0 0 1rem;
  font-size: .75rem;
  display: grid;
}

@media screen and (min-width: 768px) {
  .home__labels {
    grid-template-columns: repeat(5, 1fr);
  }
}

.home__label:nth-child(2n) {
  display: none;
}

.home__label:first-child {
  text-align: left;
  padding: 0 0 0 1rem;
}

.home__label:last-child {
  text-align: right;
  padding: 0 1rem 0 0;
}

@media screen and (min-width: 768px) {
  .home__label {
    font-size: .875rem;
  }

  .home__label:nth-child(2n) {
    display: block;
  }

  .home__label:first-child, .home__label:last-child {
    text-align: center;
    color: #95a8b1;
    padding: 0;
  }
}

.result {
  background: #2c383f;
}

.result__wrapper {
  justify-content: center;
  padding: 1.5rem 1.5rem 2.5rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .result__wrapper {
    padding: 2.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .result__wrapper {
    padding: 5rem;
  }
}

.result__wrapper--C {
  background: linear-gradient(95deg, rgba(68, 186, 171, .32) 0%, #2c383f 100%);
}

.result__wrapper--V {
  background: linear-gradient(95deg, rgba(237, 28, 36, .32) 0%, #2c383f 100%);
}

.result__wrapper--M {
  background: linear-gradient(95deg, rgba(13, 157, 219, .32) 0%, #2c383f 100%);
}

.result__wrapper--S {
  background: linear-gradient(95deg, rgba(237, 27, 52, .32) 0%, #2c383f 100%);
}

.result__wrapper--L {
  background: linear-gradient(95deg, rgba(0, 106, 179, .32) 0%, #2c383f 100%);
}

.result__wrapper--KD {
  background: linear-gradient(95deg, rgba(0, 94, 161, .32) 0%, #2c383f 100%);
}

.result__wrapper--MP {
  background: linear-gradient(95deg, rgba(83, 160, 69, .32) 0%, #2c383f 100%);
}

.result__wrapper--SD {
  background: linear-gradient(95deg, rgba(247, 218, 6, .32) 0%, #2c383f 100%);
}

.result__vector {
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result__container {
  width: 100%;
  height: 100%;
  z-index: 1;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

@media screen and (min-width: 992px) {
  .result__container {
    max-width: 1440px;
    flex-direction: row;
  }
}

.result__background {
  width: calc(100% + 10rem);
  z-index: -1;
  background: #2c383f;
  position: absolute;
  top: 2.5rem;
  bottom: -5rem;
  left: -5rem;
  right: -5rem;
}

@media screen and (min-width: 768px) {
  .result__background {
    display: none;
  }
}

.result__heading {
  font-size: 2.25rem;
}

@media screen and (min-width: 480px) {
  .result__heading {
    font-size: 2.75rem;
  }
}

@media screen and (min-width: 768px) {
  .result__heading {
    font-size: 3rem;
  }
}

.result__alignment {
  width: 100%;
  flex-direction: column;
  padding: 1rem 0 0;
  display: flex;
}

@media screen and (min-width: 992px) {
  .result__alignment {
    max-width: fit-content;
  }
}

.result__faction {
  align-items: center;
  display: flex;
}

.result__faction img {
  height: 5.5rem;
  margin: 0 1.5rem 0 0;
}

@media screen and (max-width: 480px) {
  .result__faction img {
    width: 2.5rem;
    height: 2.5rem;
    margin: 0 1rem 0 0;
  }
}

@media screen and (max-width: 350px) {
  .result__faction img {
    display: none;
  }
}

.result__faction h1 {
  margin: 0;
  padding: .25rem .5rem;
  font-size: 1.75rem;
}

@media screen and (min-width: 768px) {
  .result__faction h1 {
    font-size: 3.375rem;
  }
}

@media screen and (min-width: 480px) {
  .result__faction h1 {
    font-size: 2.75rem;
  }
}

.result__faction--C h1 {
  background: #114838;
}

.result__faction--V h1 {
  background: #ed1c24;
}

.result__faction--M h1 {
  background: #0d9ddb;
}

.result__faction--S h1 {
  background: #ed1b34;
}

.result__faction--L h1 {
  background: #006ab3;
}

.result__faction--KD h1 {
  background: #005ea1;
}

.result__faction--MP h1 {
  background: #53a045;
}

.result__faction--SD h1 {
  color: #000;
  background: #f7da06;
}

.result__share__buttons {
  margin-top: auto;
}

.result__share {
  display: none;
}

@media screen and (min-width: 480px) {
  .result__share {
    display: block;
  }
}

@media screen and (max-width: 992px) {
  .result__share {
    margin-top: 2.75rem;
  }
}

.result__share h2 {
  text-transform: uppercase;
  font-size: .75rem;
  font-weight: 700px;
  line-height: 1rem;
}

.result__share-links {
  column-gap: 32px;
  display: flex;
}

.result__buttons {
  padding: 1.75rem 0 1.5rem;
}

.result__buttons a {
  width: 100%;
  margin: 0 .75rem 0 0;
}

@media screen and (min-width: 768px) {
  .result__buttons {
    display: flex;
  }
}

@media screen and (min-width: 992px) {
  .result__buttons {
    padding: 1rem 0 0;
  }
}

.result__opinions {
  width: 100%;
  background: #3c4b52;
  border-radius: .5rem;
  padding: 1rem;
  position: relative;
}

@media screen and (min-width: 768px) {
  .result__opinions {
    background: rgba(255, 255, 255, .08);
    padding: 2rem;
  }
}

@media screen and (min-width: 992px) {
  .result__opinions {
    max-width: 40rem;
    margin: 0 0 0 2.5rem;
  }
}

.result__opinion {
  align-items: center;
  padding: 0 0 .75rem;
  display: flex;
}

.result__opinion img {
  height: 1.5rem;
}

@media screen and (min-width: 768px) {
  .result__opinion img {
    height: 2rem;
  }
}

.result__opinion span {
  min-width: 6ch;
  padding: 0 .75rem;
  font-size: .875rem;
  font-weight: 700;
}

.result__bars {
  width: 100%;
  align-items: center;
  display: flex;
  position: relative;
}

.result__bar {
  height: 1.5rem;
  border-radius: .25rem;
  position: absolute;
}

@media screen and (min-width: 768px) {
  .result__bar {
    height: 2rem;
  }
}

.result__bar--full {
  background: pink;
}

.result__bar--none {
  width: 100%;
  background: #2c383f;
}

.result__bar--C {
  background: #114838;
}

.result__bar--V {
  background: #ed1c24;
}

.result__bar--M {
  background: #0d9ddb;
}

.result__bar--S {
  background: #ed1b34;
}

.result__bar--L {
  background: #006ab3;
}

.result__bar--KD {
  background: #005ea1;
}

.result__bar--MP {
  background: #53a045;
}

.result__bar--SD {
  background: #f7da06;
}

.welcome {
  text-align: center;
  background: #2c383f 0 0 / cover;
  flex-wrap: wrap;
  padding: 4rem 0 2.5rem;
  display: flex;
  position: relative;
}

.welcome__wrapper {
  width: 100%;
  max-width: 40rem;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
}

.welcome__wrapper h1 {
  font-size: 2.25rem;
}

@media screen and (min-width: 768px) {
  .welcome__wrapper h1 {
    font-size: 2.8125rem;
  }
}

.welcome__wrapper a {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .welcome__wrapper a {
    width: auto;
  }
}

.error {
  min-height: calc(100vh - 4rem);
  background: #2c383f 0 0 / cover;
  flex-wrap: wrap;
  align-items: center;
  display: flex;
  position: relative;
}

.error__wrapper {
  width: 100%;
  max-width: 40rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 2rem;
  display: flex;
}

.error__wrapper img {
  width: 50%;
  height: 50%;
  padding: 2rem;
  display: block;
}

.error__heading {
  margin: 0;
  font-size: 2rem;
  font-weight: 700;
}

@media screen and (min-width: 768px) {
  .error__heading {
    font-size: 3.375rem;
  }
}

.error__preamble {
  color: #95a8b1;
  padding: 0 1rem;
}

.error__heading, .error__preamble {
  text-align: center;
}

.home {
  min-height: calc(100vh - 4.5rem);
  background: #2c383f 50% -60px no-repeat;
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
  position: relative;
}

.home__unvalid-slide {
  height: auto;
  min-height: initial;
  background: linear-gradient(#3c4b52 0%, #2c383f 100%);
  background-image: unset !important;
}

.home__question {
  overflow: hidden;
}

.home__slider {
  max-width: 40rem;
  flex-flow: row;
  display: flex;
}

.home__slide {
  width: 100%;
  flex-shrink: 0;
  padding: 1.5rem;
}

.home__slide.animate {
  animation: fade .75s;
}

@media screen and (min-width: 768px) {
  .home__slide {
    padding: 3rem 0;
  }
}

.home__heading {
  font-size: 1.55rem;
}

@media screen and (min-width: 768px) {
  .home__heading {
    font-size: 2rem;
  }
}

.home__heading span {
  color: #44baab;
}

.home__preamble {
  color: #95a8b1;
}

.home__preamble span {
  font-weight: 700;
}

.home__argument {
  border-radius: .5rem;
  margin: 1.25rem 0;
  padding: 1rem;
  position: relative;
}

.home__argument--pro {
  background: rgba(68, 186, 171, .1);
}

.home__argument--pro div {
  background: #44baab;
}

.home__argument--con {
  background: rgba(220, 82, 89, .1);
}

.home__argument--con div {
  background: #dc5259;
}

.home__argument p {
  color: #fff;
  margin: 0;
}

.home__argument div {
  width: .125rem;
  height: calc(100% - 2rem);
  border-radius: 1px;
  position: absolute;
  top: 1rem;
  left: 0;
}

.home__opinions {
  width: 100%;
  z-index: 1;
  background: #2c383f;
  margin-top: auto;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}

.home__opinion {
  width: 100%;
  max-width: 40rem;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

@media screen and (min-width: 768px) {
  .home__opinion {
    justify-content: center;
  }
}

.home__labels {
  color: #95a8b1;
  text-align: center;
  width: 100%;
  max-width: 40rem;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 auto;
  padding: 0 0 1rem;
  font-size: .75rem;
  display: grid;
}

@media screen and (min-width: 768px) {
  .home__labels {
    grid-template-columns: repeat(5, 1fr);
  }
}

.home__label:nth-child(2n) {
  display: none;
}

.home__label:first-child {
  text-align: left;
  padding: 0 0 0 1rem;
}

.home__label:last-child {
  text-align: right;
  padding: 0 1rem 0 0;
}

@media screen and (min-width: 768px) {
  .home__label {
    font-size: .875rem;
  }

  .home__label:nth-child(2n) {
    display: block;
  }

  .home__label:first-child, .home__label:last-child {
    text-align: center;
    color: #95a8b1;
    padding: 0;
  }
}

.result {
  background: #2c383f;
}

.result__wrapper {
  justify-content: center;
  padding: 1.5rem 1.5rem 2.5rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .result__wrapper {
    padding: 2.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .result__wrapper {
    padding: 5rem;
  }
}

.result__wrapper--C {
  background: linear-gradient(95deg, rgba(68, 186, 171, .32) 0%, #2c383f 100%);
}

.result__wrapper--V {
  background: linear-gradient(95deg, rgba(237, 28, 36, .32) 0%, #2c383f 100%);
}

.result__wrapper--M {
  background: linear-gradient(95deg, rgba(13, 157, 219, .32) 0%, #2c383f 100%);
}

.result__wrapper--S {
  background: linear-gradient(95deg, rgba(237, 27, 52, .32) 0%, #2c383f 100%);
}

.result__wrapper--L {
  background: linear-gradient(95deg, rgba(0, 106, 179, .32) 0%, #2c383f 100%);
}

.result__wrapper--KD {
  background: linear-gradient(95deg, rgba(0, 94, 161, .32) 0%, #2c383f 100%);
}

.result__wrapper--MP {
  background: linear-gradient(95deg, rgba(83, 160, 69, .32) 0%, #2c383f 100%);
}

.result__wrapper--SD {
  background: linear-gradient(95deg, rgba(247, 218, 6, .32) 0%, #2c383f 100%);
}

.result__vector {
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result__container {
  width: 100%;
  height: 100%;
  z-index: 1;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

@media screen and (min-width: 992px) {
  .result__container {
    max-width: 1440px;
    flex-direction: row;
  }
}

.result__background {
  width: calc(100% + 10rem);
  z-index: -1;
  background: #2c383f;
  position: absolute;
  top: 2.5rem;
  bottom: -5rem;
  left: -5rem;
  right: -5rem;
}

@media screen and (min-width: 768px) {
  .result__background {
    display: none;
  }
}

.result__heading {
  font-size: 2.25rem;
}

@media screen and (min-width: 480px) {
  .result__heading {
    font-size: 2.75rem;
  }
}

@media screen and (min-width: 768px) {
  .result__heading {
    font-size: 3rem;
  }
}

.result__alignment {
  width: 100%;
  flex-direction: column;
  padding: 1rem 0 0;
  display: flex;
}

@media screen and (min-width: 992px) {
  .result__alignment {
    max-width: fit-content;
  }
}

.result__faction {
  align-items: center;
  display: flex;
}

.result__faction img {
  height: 5.5rem;
  margin: 0 1.5rem 0 0;
}

@media screen and (max-width: 480px) {
  .result__faction img {
    width: 2.5rem;
    height: 2.5rem;
    margin: 0 1rem 0 0;
  }
}

@media screen and (max-width: 350px) {
  .result__faction img {
    display: none;
  }
}

.result__faction h1 {
  margin: 0;
  padding: .25rem .5rem;
  font-size: 1.75rem;
}

@media screen and (min-width: 768px) {
  .result__faction h1 {
    font-size: 3.375rem;
  }
}

@media screen and (min-width: 480px) {
  .result__faction h1 {
    font-size: 2.75rem;
  }
}

.result__faction--C h1 {
  background: #114838;
}

.result__faction--V h1 {
  background: #ed1c24;
}

.result__faction--M h1 {
  background: #0d9ddb;
}

.result__faction--S h1 {
  background: #ed1b34;
}

.result__faction--L h1 {
  background: #006ab3;
}

.result__faction--KD h1 {
  background: #005ea1;
}

.result__faction--MP h1 {
  background: #53a045;
}

.result__faction--SD h1 {
  color: #000;
  background: #f7da06;
}

.result__share__buttons {
  margin-top: auto;
}

.result__share {
  display: none;
}

@media screen and (min-width: 480px) {
  .result__share {
    display: block;
  }
}

@media screen and (max-width: 992px) {
  .result__share {
    margin-top: 2.75rem;
  }
}

.result__share h2 {
  text-transform: uppercase;
  font-size: .75rem;
  font-weight: 700px;
  line-height: 1rem;
}

.result__share-links {
  column-gap: 32px;
  display: flex;
}

.result__buttons {
  padding: 1.75rem 0 1.5rem;
}

.result__buttons a {
  width: 100%;
  margin: 0 .75rem 0 0;
}

@media screen and (min-width: 768px) {
  .result__buttons {
    display: flex;
  }
}

@media screen and (min-width: 992px) {
  .result__buttons {
    padding: 1rem 0 0;
  }
}

.result__opinions {
  width: 100%;
  background: #3c4b52;
  border-radius: .5rem;
  padding: 1rem;
  position: relative;
}

@media screen and (min-width: 768px) {
  .result__opinions {
    background: rgba(255, 255, 255, .08);
    padding: 2rem;
  }
}

@media screen and (min-width: 992px) {
  .result__opinions {
    max-width: 40rem;
    margin: 0 0 0 2.5rem;
  }
}

.result__opinion {
  align-items: center;
  padding: 0 0 .75rem;
  display: flex;
}

.result__opinion img {
  height: 1.5rem;
}

@media screen and (min-width: 768px) {
  .result__opinion img {
    height: 2rem;
  }
}

.result__opinion span {
  min-width: 6ch;
  padding: 0 .75rem;
  font-size: .875rem;
  font-weight: 700;
}

.result__bars {
  width: 100%;
  align-items: center;
  display: flex;
  position: relative;
}

.result__bar {
  height: 1.5rem;
  border-radius: .25rem;
  position: absolute;
}

@media screen and (min-width: 768px) {
  .result__bar {
    height: 2rem;
  }
}

.result__bar--full {
  background: pink;
}

.result__bar--none {
  width: 100%;
  background: #2c383f;
}

.result__bar--C {
  background: #114838;
}

.result__bar--V {
  background: #ed1c24;
}

.result__bar--M {
  background: #0d9ddb;
}

.result__bar--S {
  background: #ed1b34;
}

.result__bar--L {
  background: #006ab3;
}

.result__bar--KD {
  background: #005ea1;
}

.result__bar--MP {
  background: #53a045;
}

.result__bar--SD {
  background: #f7da06;
}

.welcome {
  text-align: center;
  background: #2c383f 0 0 / cover;
  flex-wrap: wrap;
  padding: 4rem 0 2.5rem;
  display: flex;
  position: relative;
}

.welcome__wrapper {
  width: 100%;
  max-width: 40rem;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
}

.welcome__wrapper h1 {
  font-size: 2.25rem;
}

@media screen and (min-width: 768px) {
  .welcome__wrapper h1 {
    font-size: 2.8125rem;
  }
}

.welcome__wrapper a {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .welcome__wrapper a {
    width: auto;
  }
}

.error {
  min-height: calc(100vh - 4rem);
  background: #2c383f 0 0 / cover;
  flex-wrap: wrap;
  align-items: center;
  display: flex;
  position: relative;
}

.error__wrapper {
  width: 100%;
  max-width: 40rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 2rem;
  display: flex;
}

.error__wrapper img {
  width: 50%;
  height: 50%;
  padding: 2rem;
  display: block;
}

.error__heading {
  margin: 0;
  font-size: 2rem;
  font-weight: 700;
}

@media screen and (min-width: 768px) {
  .error__heading {
    font-size: 3.375rem;
  }
}

.error__preamble {
  color: #95a8b1;
  padding: 0 1rem;
}

.error__heading, .error__preamble {
  text-align: center;
}

.home {
  min-height: calc(100vh - 4.5rem);
  background: #2c383f 50% -60px no-repeat;
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
  position: relative;
}

.home__unvalid-slide {
  height: auto;
  min-height: initial;
  background: linear-gradient(#3c4b52 0%, #2c383f 100%);
  background-image: unset !important;
}

.home__question {
  overflow: hidden;
}

.home__slider {
  max-width: 40rem;
  flex-flow: row;
  display: flex;
}

.home__slide {
  width: 100%;
  flex-shrink: 0;
  padding: 1.5rem;
}

.home__slide.animate {
  animation: fade .75s;
}

@media screen and (min-width: 768px) {
  .home__slide {
    padding: 3rem 0;
  }
}

.home__heading {
  font-size: 1.55rem;
}

@media screen and (min-width: 768px) {
  .home__heading {
    font-size: 2rem;
  }
}

.home__heading span {
  color: #44baab;
}

.home__preamble {
  color: #95a8b1;
}

.home__preamble span {
  font-weight: 700;
}

.home__argument {
  border-radius: .5rem;
  margin: 1.25rem 0;
  padding: 1rem;
  position: relative;
}

.home__argument--pro {
  background: rgba(68, 186, 171, .1);
}

.home__argument--pro div {
  background: #44baab;
}

.home__argument--con {
  background: rgba(220, 82, 89, .1);
}

.home__argument--con div {
  background: #dc5259;
}

.home__argument p {
  color: #fff;
  margin: 0;
}

.home__argument div {
  width: .125rem;
  height: calc(100% - 2rem);
  border-radius: 1px;
  position: absolute;
  top: 1rem;
  left: 0;
}

.home__opinions {
  width: 100%;
  z-index: 1;
  background: #2c383f;
  margin-top: auto;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}

.home__opinion {
  width: 100%;
  max-width: 40rem;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

@media screen and (min-width: 768px) {
  .home__opinion {
    justify-content: center;
  }
}

.home__labels {
  color: #95a8b1;
  text-align: center;
  width: 100%;
  max-width: 40rem;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 auto;
  padding: 0 0 1rem;
  font-size: .75rem;
  display: grid;
}

@media screen and (min-width: 768px) {
  .home__labels {
    grid-template-columns: repeat(5, 1fr);
  }
}

.home__label:nth-child(2n) {
  display: none;
}

.home__label:first-child {
  text-align: left;
  padding: 0 0 0 1rem;
}

.home__label:last-child {
  text-align: right;
  padding: 0 1rem 0 0;
}

@media screen and (min-width: 768px) {
  .home__label {
    font-size: .875rem;
  }

  .home__label:nth-child(2n) {
    display: block;
  }

  .home__label:first-child, .home__label:last-child {
    text-align: center;
    color: #95a8b1;
    padding: 0;
  }
}

.result {
  background: #2c383f;
}

.result__wrapper {
  justify-content: center;
  padding: 1.5rem 1.5rem 2.5rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .result__wrapper {
    padding: 2.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .result__wrapper {
    padding: 5rem;
  }
}

.result__wrapper--C {
  background: linear-gradient(95deg, rgba(68, 186, 171, .32) 0%, #2c383f 100%);
}

.result__wrapper--V {
  background: linear-gradient(95deg, rgba(237, 28, 36, .32) 0%, #2c383f 100%);
}

.result__wrapper--M {
  background: linear-gradient(95deg, rgba(13, 157, 219, .32) 0%, #2c383f 100%);
}

.result__wrapper--S {
  background: linear-gradient(95deg, rgba(237, 27, 52, .32) 0%, #2c383f 100%);
}

.result__wrapper--L {
  background: linear-gradient(95deg, rgba(0, 106, 179, .32) 0%, #2c383f 100%);
}

.result__wrapper--KD {
  background: linear-gradient(95deg, rgba(0, 94, 161, .32) 0%, #2c383f 100%);
}

.result__wrapper--MP {
  background: linear-gradient(95deg, rgba(83, 160, 69, .32) 0%, #2c383f 100%);
}

.result__wrapper--SD {
  background: linear-gradient(95deg, rgba(247, 218, 6, .32) 0%, #2c383f 100%);
}

.result__vector {
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result__container {
  width: 100%;
  height: 100%;
  z-index: 1;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

@media screen and (min-width: 992px) {
  .result__container {
    max-width: 1440px;
    flex-direction: row;
  }
}

.result__background {
  width: calc(100% + 10rem);
  z-index: -1;
  background: #2c383f;
  position: absolute;
  top: 2.5rem;
  bottom: -5rem;
  left: -5rem;
  right: -5rem;
}

@media screen and (min-width: 768px) {
  .result__background {
    display: none;
  }
}

.result__heading {
  font-size: 2.25rem;
}

@media screen and (min-width: 480px) {
  .result__heading {
    font-size: 2.75rem;
  }
}

@media screen and (min-width: 768px) {
  .result__heading {
    font-size: 3rem;
  }
}

.result__alignment {
  width: 100%;
  flex-direction: column;
  padding: 1rem 0 0;
  display: flex;
}

@media screen and (min-width: 992px) {
  .result__alignment {
    max-width: fit-content;
  }
}

.result__faction {
  align-items: center;
  display: flex;
}

.result__faction img {
  height: 5.5rem;
  margin: 0 1.5rem 0 0;
}

@media screen and (max-width: 480px) {
  .result__faction img {
    width: 2.5rem;
    height: 2.5rem;
    margin: 0 1rem 0 0;
  }
}

@media screen and (max-width: 350px) {
  .result__faction img {
    display: none;
  }
}

.result__faction h1 {
  margin: 0;
  padding: .25rem .5rem;
  font-size: 1.75rem;
}

@media screen and (min-width: 768px) {
  .result__faction h1 {
    font-size: 3.375rem;
  }
}

@media screen and (min-width: 480px) {
  .result__faction h1 {
    font-size: 2.75rem;
  }
}

.result__faction--C h1 {
  background: #114838;
}

.result__faction--V h1 {
  background: #ed1c24;
}

.result__faction--M h1 {
  background: #0d9ddb;
}

.result__faction--S h1 {
  background: #ed1b34;
}

.result__faction--L h1 {
  background: #006ab3;
}

.result__faction--KD h1 {
  background: #005ea1;
}

.result__faction--MP h1 {
  background: #53a045;
}

.result__faction--SD h1 {
  color: #000;
  background: #f7da06;
}

.result__share__buttons {
  margin-top: auto;
}

.result__share {
  display: none;
}

@media screen and (min-width: 480px) {
  .result__share {
    display: block;
  }
}

@media screen and (max-width: 992px) {
  .result__share {
    margin-top: 2.75rem;
  }
}

.result__share h2 {
  text-transform: uppercase;
  font-size: .75rem;
  font-weight: 700px;
  line-height: 1rem;
}

.result__share-links {
  column-gap: 32px;
  display: flex;
}

.result__buttons {
  padding: 1.75rem 0 1.5rem;
}

.result__buttons a {
  width: 100%;
  margin: 0 .75rem 0 0;
}

@media screen and (min-width: 768px) {
  .result__buttons {
    display: flex;
  }
}

@media screen and (min-width: 992px) {
  .result__buttons {
    padding: 1rem 0 0;
  }
}

.result__opinions {
  width: 100%;
  background: #3c4b52;
  border-radius: .5rem;
  padding: 1rem;
  position: relative;
}

@media screen and (min-width: 768px) {
  .result__opinions {
    background: rgba(255, 255, 255, .08);
    padding: 2rem;
  }
}

@media screen and (min-width: 992px) {
  .result__opinions {
    max-width: 40rem;
    margin: 0 0 0 2.5rem;
  }
}

.result__opinion {
  align-items: center;
  padding: 0 0 .75rem;
  display: flex;
}

.result__opinion img {
  height: 1.5rem;
}

@media screen and (min-width: 768px) {
  .result__opinion img {
    height: 2rem;
  }
}

.result__opinion span {
  min-width: 6ch;
  padding: 0 .75rem;
  font-size: .875rem;
  font-weight: 700;
}

.result__bars {
  width: 100%;
  align-items: center;
  display: flex;
  position: relative;
}

.result__bar {
  height: 1.5rem;
  border-radius: .25rem;
  position: absolute;
}

@media screen and (min-width: 768px) {
  .result__bar {
    height: 2rem;
  }
}

.result__bar--full {
  background: pink;
}

.result__bar--none {
  width: 100%;
  background: #2c383f;
}

.result__bar--C {
  background: #114838;
}

.result__bar--V {
  background: #ed1c24;
}

.result__bar--M {
  background: #0d9ddb;
}

.result__bar--S {
  background: #ed1b34;
}

.result__bar--L {
  background: #006ab3;
}

.result__bar--KD {
  background: #005ea1;
}

.result__bar--MP {
  background: #53a045;
}

.result__bar--SD {
  background: #f7da06;
}

.welcome {
  text-align: center;
  background: #2c383f 0 0 / cover;
  flex-wrap: wrap;
  padding: 4rem 0 2.5rem;
  display: flex;
  position: relative;
}

.welcome__wrapper {
  width: 100%;
  max-width: 40rem;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
}

.welcome__wrapper h1 {
  font-size: 2.25rem;
}

@media screen and (min-width: 768px) {
  .welcome__wrapper h1 {
    font-size: 2.8125rem;
  }
}

.welcome__wrapper a {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .welcome__wrapper a {
    width: auto;
  }
}

.error {
  min-height: calc(100vh - 4rem);
  background: #2c383f 0 0 / cover;
  flex-wrap: wrap;
  align-items: center;
  display: flex;
  position: relative;
}

.error__wrapper {
  width: 100%;
  max-width: 40rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 2rem;
  display: flex;
}

.error__wrapper img {
  width: 50%;
  height: 50%;
  padding: 2rem;
  display: block;
}

.error__heading {
  margin: 0;
  font-size: 2rem;
  font-weight: 700;
}

@media screen and (min-width: 768px) {
  .error__heading {
    font-size: 3.375rem;
  }
}

.error__preamble {
  color: #95a8b1;
  padding: 0 1rem;
}

.error__heading, .error__preamble {
  text-align: center;
}

.home {
  min-height: calc(100vh - 4.5rem);
  background: #2c383f 50% -60px no-repeat;
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
  position: relative;
}

.home__unvalid-slide {
  height: auto;
  min-height: initial;
  background: linear-gradient(#3c4b52 0%, #2c383f 100%);
  background-image: unset !important;
}

.home__question {
  overflow: hidden;
}

.home__slider {
  max-width: 40rem;
  flex-flow: row;
  display: flex;
}

.home__slide {
  width: 100%;
  flex-shrink: 0;
  padding: 1.5rem;
}

.home__slide.animate {
  animation: fade .75s;
}

@media screen and (min-width: 768px) {
  .home__slide {
    padding: 3rem 0;
  }
}

.home__heading {
  font-size: 1.55rem;
}

@media screen and (min-width: 768px) {
  .home__heading {
    font-size: 2rem;
  }
}

.home__heading span {
  color: #44baab;
}

.home__preamble {
  color: #95a8b1;
}

.home__preamble span {
  font-weight: 700;
}

.home__argument {
  border-radius: .5rem;
  margin: 1.25rem 0;
  padding: 1rem;
  position: relative;
}

.home__argument--pro {
  background: rgba(68, 186, 171, .1);
}

.home__argument--pro div {
  background: #44baab;
}

.home__argument--con {
  background: rgba(220, 82, 89, .1);
}

.home__argument--con div {
  background: #dc5259;
}

.home__argument p {
  color: #fff;
  margin: 0;
}

.home__argument div {
  width: .125rem;
  height: calc(100% - 2rem);
  border-radius: 1px;
  position: absolute;
  top: 1rem;
  left: 0;
}

.home__opinions {
  width: 100%;
  z-index: 1;
  background: #2c383f;
  margin-top: auto;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}

.home__opinion {
  width: 100%;
  max-width: 40rem;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

@media screen and (min-width: 768px) {
  .home__opinion {
    justify-content: center;
  }
}

.home__labels {
  color: #95a8b1;
  text-align: center;
  width: 100%;
  max-width: 40rem;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 auto;
  padding: 0 0 1rem;
  font-size: .75rem;
  display: grid;
}

@media screen and (min-width: 768px) {
  .home__labels {
    grid-template-columns: repeat(5, 1fr);
  }
}

.home__label:nth-child(2n) {
  display: none;
}

.home__label:first-child {
  text-align: left;
  padding: 0 0 0 1rem;
}

.home__label:last-child {
  text-align: right;
  padding: 0 1rem 0 0;
}

@media screen and (min-width: 768px) {
  .home__label {
    font-size: .875rem;
  }

  .home__label:nth-child(2n) {
    display: block;
  }

  .home__label:first-child, .home__label:last-child {
    text-align: center;
    color: #95a8b1;
    padding: 0;
  }
}

.result {
  background: #2c383f;
}

.result__wrapper {
  justify-content: center;
  padding: 1.5rem 1.5rem 2.5rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .result__wrapper {
    padding: 2.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .result__wrapper {
    padding: 5rem;
  }
}

.result__wrapper--C {
  background: linear-gradient(95deg, rgba(68, 186, 171, .32) 0%, #2c383f 100%);
}

.result__wrapper--V {
  background: linear-gradient(95deg, rgba(237, 28, 36, .32) 0%, #2c383f 100%);
}

.result__wrapper--M {
  background: linear-gradient(95deg, rgba(13, 157, 219, .32) 0%, #2c383f 100%);
}

.result__wrapper--S {
  background: linear-gradient(95deg, rgba(237, 27, 52, .32) 0%, #2c383f 100%);
}

.result__wrapper--L {
  background: linear-gradient(95deg, rgba(0, 106, 179, .32) 0%, #2c383f 100%);
}

.result__wrapper--KD {
  background: linear-gradient(95deg, rgba(0, 94, 161, .32) 0%, #2c383f 100%);
}

.result__wrapper--MP {
  background: linear-gradient(95deg, rgba(83, 160, 69, .32) 0%, #2c383f 100%);
}

.result__wrapper--SD {
  background: linear-gradient(95deg, rgba(247, 218, 6, .32) 0%, #2c383f 100%);
}

.result__vector {
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result__container {
  width: 100%;
  height: 100%;
  z-index: 1;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

@media screen and (min-width: 992px) {
  .result__container {
    max-width: 1440px;
    flex-direction: row;
  }
}

.result__background {
  width: calc(100% + 10rem);
  z-index: -1;
  background: #2c383f;
  position: absolute;
  top: 2.5rem;
  bottom: -5rem;
  left: -5rem;
  right: -5rem;
}

@media screen and (min-width: 768px) {
  .result__background {
    display: none;
  }
}

.result__heading {
  font-size: 2.25rem;
}

@media screen and (min-width: 480px) {
  .result__heading {
    font-size: 2.75rem;
  }
}

@media screen and (min-width: 768px) {
  .result__heading {
    font-size: 3rem;
  }
}

.result__alignment {
  width: 100%;
  flex-direction: column;
  padding: 1rem 0 0;
  display: flex;
}

@media screen and (min-width: 992px) {
  .result__alignment {
    max-width: fit-content;
  }
}

.result__faction {
  align-items: center;
  display: flex;
}

.result__faction img {
  height: 5.5rem;
  margin: 0 1.5rem 0 0;
}

@media screen and (max-width: 480px) {
  .result__faction img {
    width: 2.5rem;
    height: 2.5rem;
    margin: 0 1rem 0 0;
  }
}

@media screen and (max-width: 350px) {
  .result__faction img {
    display: none;
  }
}

.result__faction h1 {
  margin: 0;
  padding: .25rem .5rem;
  font-size: 1.75rem;
}

@media screen and (min-width: 768px) {
  .result__faction h1 {
    font-size: 3.375rem;
  }
}

@media screen and (min-width: 480px) {
  .result__faction h1 {
    font-size: 2.75rem;
  }
}

.result__faction--C h1 {
  background: #114838;
}

.result__faction--V h1 {
  background: #ed1c24;
}

.result__faction--M h1 {
  background: #0d9ddb;
}

.result__faction--S h1 {
  background: #ed1b34;
}

.result__faction--L h1 {
  background: #006ab3;
}

.result__faction--KD h1 {
  background: #005ea1;
}

.result__faction--MP h1 {
  background: #53a045;
}

.result__faction--SD h1 {
  color: #000;
  background: #f7da06;
}

.result__share__buttons {
  margin-top: auto;
}

.result__share {
  display: none;
}

@media screen and (min-width: 480px) {
  .result__share {
    display: block;
  }
}

@media screen and (max-width: 992px) {
  .result__share {
    margin-top: 2.75rem;
  }
}

.result__share h2 {
  text-transform: uppercase;
  font-size: .75rem;
  font-weight: 700px;
  line-height: 1rem;
}

.result__share-links {
  column-gap: 32px;
  display: flex;
}

.result__buttons {
  padding: 1.75rem 0 1.5rem;
}

.result__buttons a {
  width: 100%;
  margin: 0 .75rem 0 0;
}

@media screen and (min-width: 768px) {
  .result__buttons {
    display: flex;
  }
}

@media screen and (min-width: 992px) {
  .result__buttons {
    padding: 1rem 0 0;
  }
}

.result__opinions {
  width: 100%;
  background: #3c4b52;
  border-radius: .5rem;
  padding: 1rem;
  position: relative;
}

@media screen and (min-width: 768px) {
  .result__opinions {
    background: rgba(255, 255, 255, .08);
    padding: 2rem;
  }
}

@media screen and (min-width: 992px) {
  .result__opinions {
    max-width: 40rem;
    margin: 0 0 0 2.5rem;
  }
}

.result__opinion {
  align-items: center;
  padding: 0 0 .75rem;
  display: flex;
}

.result__opinion img {
  height: 1.5rem;
}

@media screen and (min-width: 768px) {
  .result__opinion img {
    height: 2rem;
  }
}

.result__opinion span {
  min-width: 6ch;
  padding: 0 .75rem;
  font-size: .875rem;
  font-weight: 700;
}

.result__bars {
  width: 100%;
  align-items: center;
  display: flex;
  position: relative;
}

.result__bar {
  height: 1.5rem;
  border-radius: .25rem;
  position: absolute;
}

@media screen and (min-width: 768px) {
  .result__bar {
    height: 2rem;
  }
}

.result__bar--full {
  background: pink;
}

.result__bar--none {
  width: 100%;
  background: #2c383f;
}

.result__bar--C {
  background: #114838;
}

.result__bar--V {
  background: #ed1c24;
}

.result__bar--M {
  background: #0d9ddb;
}

.result__bar--S {
  background: #ed1b34;
}

.result__bar--L {
  background: #006ab3;
}

.result__bar--KD {
  background: #005ea1;
}

.result__bar--MP {
  background: #53a045;
}

.result__bar--SD {
  background: #f7da06;
}

.welcome {
  text-align: center;
  background: #2c383f 0 0 / cover;
  flex-wrap: wrap;
  padding: 4rem 0 2.5rem;
  display: flex;
  position: relative;
}

.welcome__wrapper {
  width: 100%;
  max-width: 40rem;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
}

.welcome__wrapper h1 {
  font-size: 2.25rem;
}

@media screen and (min-width: 768px) {
  .welcome__wrapper h1 {
    font-size: 2.8125rem;
  }
}

.welcome__wrapper a {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .welcome__wrapper a {
    width: auto;
  }
}

html {
  box-sizing: border-box;
  touch-action: manipulation;
  scrollbar-width: none;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  font-family: QuattroNews, sans-serif;
}

html *, html :before, html :after {
  box-sizing: inherit;
}

html ::-webkit-scrollbar {
  display: none;
}

html * {
  -webkit-tap-highlight-color: transparent;
}

html, body {
  margin: 0;
  padding: 0;
}

body {
  color: #fff;
  background: #3c4b52;
}

body #ot-sdk-btn-floating {
  display: none !important;
}

img {
  display: block;
}

h1 {
  margin: 1.25rem 0;
  font-size: 2rem;
}

h2 {
  font-size: 1.25rem;
}

h1, h2 {
  margin-top: 0;
}

a {
  color: #fff;
  text-decoration: none;
}

a:focus {
  outline: none;
}

a:focus-visible {
  outline: 2px solid;
}

p {
  margin: .875rem 0;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

@font-face {
  font-family: QuattroNews;
  src: url(QuattroNews-Regular.c62de662.woff2) format("woff2"), url(QuattroNews-Regular.5267a4c2.woff) format("woff");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: QuattroNews;
  src: url(QuattroNews-Medium.6114e56c.woff2) format("woff2"), url(QuattroNews-Medium.ba70d29d.woff) format("woff");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: QuattroNews;
  src: url(QuattroNews-Bold.5201837d.woff2) format("woff2"), url(QuattroNews-Bold.a6749b06.woff) format("woff");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

.index {
  min-height: 100vh;
  flex-direction: column;
  display: flex;
}

.disable-link {
  pointer-events: none;
}

/*# sourceMappingURL=index.2f167996.css.map */
